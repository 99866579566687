import React from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { DialogContentProps } from "@models/layoutTypes";
import { apiFunctionState, dialogIDRecoilState, isSubmitButtonDisabledState } from "@models/recoilStates";

interface DialogButtonsProps {
	dialogId: string;
	submitButtonText: string;
	isDupCheckActive?: boolean;
	prevDialog?: DialogContentProps;
	prevDialogID?: string;
	nextDialog?: DialogContentProps;
	nextDialogID?: string;
}

const Buttons: React.FC<DialogButtonsProps> = ({ dialogId, submitButtonText, isDupCheckActive,
												 prevDialog, prevDialogID,
												 nextDialog, nextDialogID }) => {

	const { closeDialog, openDialog } = useDialog();
	const { t } = useTranslation();

	const apiFunction = useRecoilValue(apiFunctionState);

	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useRecoilState(isSubmitButtonDisabledState);

	const setDialogID = useSetRecoilState(dialogIDRecoilState);

	const closeThisDialog = () => {
		closeDialog(dialogId);
		setIsSubmitButtonDisabled(true);

		if (prevDialog && prevDialogID) {
			setDialogID(prevDialogID);
			openDialog(prevDialog);
		}
	}

	const handleSubmit = () => {
		if (typeof apiFunction === 'function') {
			apiFunction();
		}

		if (!isDupCheckActive) {
			closeDialog(dialogId);
			setIsSubmitButtonDisabled(true);
		}

		if (nextDialog && nextDialogID) {
			setDialogID(nextDialogID);
			openDialog(nextDialog);
		}
	}

	return (
		<>
			<Button className='white' onClick={closeThisDialog}>{t('hospital.cancel')}</Button>
			{isSubmitButtonDisabled
				? <Button disabled>{submitButtonText}</Button>
				: <Button className='blue' onClick={handleSubmit}>{submitButtonText}</Button>
			}
		</>
	);
}

export default Buttons;
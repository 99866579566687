import React from 'react';
import { Input } from "@atoms/Input";
import { OptionProps } from "@models/optionProps";

const RadioOption: React.FC<OptionProps> = ({ id, text, ...props }) => {
	return (
		<div>
			<Input type='radio' id={id} {...props} />
			<label htmlFor={id}>{text}</label>
		</div>
	);
};

export default RadioOption;
import React from 'react';
import { useTranslation } from "react-i18next";
import { BPIRI } from "@models/layoutTypes";

const Bpiri: React.FC<BPIRI> = ({ ppMax, ppMean, ppMin, ppSd,
									sbpMax, sbpMean, sbpMin, sbpSd }) => {
	const { t } = useTranslation();

	return (
		<tbody>
			<tr>
				<th>{t('patient.sbpMax')}</th>
				<td>{sbpMax} mmHg</td>
				<th>{t('patient.sbpMin')}</th>
				<td>{sbpMin} mmHg</td>
				<th>{t('patient.sbpMean')}</th>
				<td>{sbpMean} mmHg</td>
			</tr>
			<tr>
				<th>{t('patient.sbpSd')}</th>
				<td>{sbpSd} mmHg</td>
				<th>{t('patient.ppMax')}</th>
				<td>{ppMax} mmHg</td>
				<th>{t('patient.ppMin')}</th>
				<td>{ppMin} mmHg</td>
			</tr>
			<tr>
				<th>{t('patient.ppMean')}</th>
				<td>{ppMean} mmHg</td>
				<th>{t('patient.ppSd')}</th>
				<td colSpan={3}>{ppSd} mmHg</td>
			</tr>
		</tbody>
	);
};

export default Bpiri;
import React, { useEffect, useMemo, useRef } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from 'highcharts/modules/solid-gauge';
import highchartsAccessibility from "highcharts/modules/accessibility";
import { useTranslation } from "react-i18next";
import { PredictionProps } from "@models/layoutTypes";
HighchartsMore(Highcharts); // init module
SolidGauge(Highcharts);
highchartsAccessibility(Highcharts); // init module

const BpPrediction: React.FC<PredictionProps & HighchartsReact.Props> = ({ measuredDateCount, participantRate, predictable }: PredictionProps, props: HighchartsReact.Props) => {
	const { t } = useTranslation();
	const gaugeOptions = useMemo(() => ({
		chart: {
			backgroundColor: 'transparent',
			height: 300,
			margin: [0, 0, 0, 0],
			type: 'solidgauge'
		},
		credits: {
			enabled: false
		},
		pane: [{
			background: [{ // 첫 번째 차트의 배경
				borderColor: 'rgba(0, 0, 0, 0.1)',
				borderWidth: 20,
				innerRadius: '100%',
				outerRadius: '100%',
				shape: 'arc'
			}, { // 두 번째 차트의 배경
				borderColor: 'rgba(0, 0, 0, 0.1)',
				borderWidth: 20,
				innerRadius: '70%',
				outerRadius: '70%',
				shape: 'arc'
			}],
			center: ['50%', '50%'],
			endAngle: 90,
			startAngle: -90
		}],
		plotOptions: {
			solidgauge: {
				borderWidth: 20,
				dataLabels: {
					borderWidth: 0,
					format: predictable ? `<p class="possible">${t('patient.possible')}</p>` : `<p class="impossible">${t('patient.impossible')}</p>`,
					useHTML: true
				}
			}
		},
		series: [{
			borderColor: '#1b59f8',
			data: [{ innerRadius: '100%', radius: 100, y: participantRate }],
			enableMouseTracking: false,
			name: 'participation',
			type: 'solidgauge',
			yAxis: 0
		}, {
			borderColor: '#9ddcff',
			data: [{ innerRadius: '70%', radius: 70, y: measuredDateCount }],
			enableMouseTracking: false,
			name: 'measurement',
			type: 'solidgauge',
			yAxis: 1
		}],
		title: undefined,
		yAxis: [{
			lineWidth: 0,
			max: 100,
			min: 0,
			tickPositions: []
		}, {
			lineWidth: 0,
			max: 7,
			min: 0,
			tickPositions: []
		}]
	}), [measuredDateCount, participantRate, predictable, t]);

	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	useEffect(() => {
		const svg = document.getElementsByTagName('svg');
		if (svg.length > 0) {
			const path = svg[1].getElementsByTagName('path');
			if (path.length > 1) {
				// 첫 번째 path는 백그라운드
				path[0].setAttributeNS(null, 'stroke-linejoin', 'round');
				// 두 번째 path는 값
				path[1].setAttributeNS(null, 'stroke-linejoin', 'round');
			}
		}
	}, []);

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={gaugeOptions}
			ref={chartComponentRef}
			{...props}
		/>
	);
};

export default BpPrediction;
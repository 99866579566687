import React, { useCallback, useState } from 'react';
import { useRecoilState } from "recoil";
import { Button } from "@atoms/Button";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { formatDateRange } from "@logics/common";
import { DateRangeProps } from "@models/layoutTypes";
import arrowNext from '@styles/images/arrow-next.png';
import arrowPrev from '@styles/images/arrow-prev.png';

const DateRange: React.FC<DateRangeProps> = ({ dateState, days }) => {
	const [hasNext, setHasNext] = useState(false);
	const [date, setDate] = useRecoilState(dateState);

	const findPrevRange = useCallback(() => {
		setDate(prev => {
			let newDate = new Date(prev);
			newDate.setDate(prev.getDate() - days);
			return newDate;
		});
	}, [days, setDate]);

	const findNextRange = useCallback(() => {
		setDate(prev => {
			let newDate = new Date(prev);
			newDate.setDate(prev.getDate() + days);
			return newDate;
		});
	}, [days, setDate]);

	useEffectAfterMount(() => {
		const dateTime: number = new Date(date).setHours(0, 0, 0, 0);
		const todayDateTime: number = new Date().setHours(0, 0, 0, 0);
		setHasNext(dateTime < todayDateTime);
		formatDateRange(date, days);
	}, [date, days]);

	return (
		<div className='date-range'>
			<Button onClick={findPrevRange}>
				<img src={arrowPrev} alt='prev'/>
			</Button>
			<p>{formatDateRange(date, days)}</p>
			<Button onClick={findNextRange} disabled={!hasNext}>
				<img src={arrowNext} alt='next'/>
			</Button>
		</div>
	);
};

export default DateRange;
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { getCookie } from "typescript-cookie";
import { findHospitalList } from "@apis/hospitalList";
import { Button } from "@atoms/Button";
import { INITIAL_PAGINATION } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { HeaderProps, ListBodyProps, MiddleBoxProps, SearchInputProps } from "@models/layoutTypes";
import {
	dialogIDRecoilState,
	dialogRecoilState,
	hospitalSearchState,
	pageNumberState,
	totalElementsState, usedCategoriesState
} from "@models/recoilStates";
import { Dialog } from "@molecules/Dialog";
import { Footer } from "@molecules/Footer";
import { HospitalForm, T3QHospitalForm } from "@molecules/HospitalForm";
import { DialogButtons } from "@molecules/ModifyPassword";
import { SearchInput } from "@molecules/SearchInput";
import { TrTh } from "@molecules/TableRow";
import { WhiteBottomBox } from "@organisms/BottomBox";
import { WhiteMiddleBox } from "@organisms/MiddleBox";
import add from "@styles/images/add.png";
import { ContentBoxWhite } from "@templates/ContentBox";
import { DefaultLayout } from "@templates/DefaultLayout";

const ListBody: React.FC<ListBodyProps> = ({ content, page, total }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	if (content.length === 0) {
		return <tr><td className='no-result' colSpan={7}>{t('hospital.nr')}</td></tr>;
	}

	return (
		<>
			{content.map(({ address, categories, createdDate, managerContact, managerName, name, seq }, index: number) => (
				<tr key={index} onClick={() => navigate(`/hospital/${seq}`)}>
					<td>{total - (index + 10 * page)}</td>
					<td>{name}</td>
					<td>{categories.sort().join(', ').toUpperCase()}</td>
					<td>{address}</td>
					<td>{managerName || '-'}</td>
					<td>{managerContact || '-'}</td>
					<td>{createdDate}</td>
				</tr>
			))}
		</>
	);
}

const List: React.FC<HeaderProps> = (headerProps) => {
	const isT3Q: boolean = !!getCookie('userUuid');

	const { openDialog } = useDialog();
	const { t } = useTranslation();

	const [hospitalList, setHospitalList] = useState<any[]>([]);
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);

	const dialogState = useRecoilValue(dialogRecoilState);
	const keyword = useRecoilValue(hospitalSearchState);
	const pageNumber = useRecoilValue(pageNumberState);
	const totalE = useRecoilValue(totalElementsState);

	const prevPN = useRef<number>(0);
	const prevTotalElementsRef = useRef<number>(0);

	const searchboxes: SearchInputProps[] = useMemo(() => ([{
		keywordState: hospitalSearchState,
		placeholder: t('hospital.sfhnan')
	}]), [t]);

	// 병원 목록 불러오기 성공 시
	const fetchHospitalListSucceed = useRecoilCallback(({ set }) => (res: AxiosResponse<any, any>, pageNum: number) => {
		const { content, first, last, totalElements, totalPages } = res.data;
		setHospitalList(content);
		setPagination({ first, last, number: pageNum, totalPages });

		set(pageNumberState, pageNum);
		set(totalElementsState, totalElements);

		prevTotalElementsRef.current = totalElements;
		prevPN.current = pageNum;
	}, []);

	// 병원 목록 불러오기 실패 시
	const fetchHospitalListFailed = useRecoilCallback(({ set }) => () => {
		setHospitalList([]);
		setPagination(INITIAL_PAGINATION);

		set(pageNumberState, 0);
		set(totalElementsState, 0);

		prevTotalElementsRef.current = 0;
		prevPN.current = 0;
	}, []);

	const fetchHospitalList = useCallback((pageNum: number) => {
		findHospitalList(keyword, pageNum,
			(res: AxiosResponse<any, any>) => fetchHospitalListSucceed(res, pageNum),
			fetchHospitalListFailed);
	}, [fetchHospitalListFailed, fetchHospitalListSucceed, keyword]);

	const listContents: MiddleBoxProps = useMemo(() => ({
		cols: <>
			<col width='8%' />
			<col width='13%' />
			<col width='15%' />
			<col width='25%' />
			<col width='13%' />
			<col width='13%' />
			<col width='13%' />
		</>,
		listHead: <TrTh thArr={['No.', t('hospital.noh'), t('hospital.category'), t('hospital.addr'), t('hospital.adminn'), t('hospital.admphone'), t('hospital.rd')]} />,
		listBody: <ListBody content={hospitalList} total={totalE} page={pageNumber} />,
		type: 'hospital'
	}), [hospitalList, t]);

	const openAddHospitalDialog = useRecoilCallback(({ reset, set }) => () => {
		const id: string = 'register-hospital-dialog';
		set(dialogIDRecoilState, id);
		reset(usedCategoriesState);
		openDialog({
			dialogTitle: t('hospital.rh'),
			dialogContents: isT3Q ? <T3QHospitalForm type='registration' /> : <HospitalForm type='registration' />,
			dialogButtons: <DialogButtons dialogId={id} submitButtonText={t('hospital.dhksfy')} />
		});
	}, [isT3Q, openDialog, t]);

	// 키워드 변경 시 동작
	useEffect(() => {
		fetchHospitalList(0);
	}, [fetchHospitalList, keyword]);

	// 페이지 이동 시 동작
	useEffectAfterMount(() => {
		if (pageNumber !== prevPN.current) {
			fetchHospitalList(pageNumber);
		}
	}, [fetchHospitalList, pageNumber]);

	// 전체 병원 수 변동 시 동작
	useEffectAfterMount(() => {
		if ((prevTotalElementsRef.current === 0 && totalE !== 0) || prevTotalElementsRef.current < totalE) {
			fetchHospitalList(pageNumber);
		}
	}, [fetchHospitalList, totalE]);

	return (
		<DefaultLayout {...headerProps}>
			<ContentBoxWhite>
				<div className='white-top-box'>
					<div className='search-box'>
						{searchboxes.map((sb, i) => <SearchInput key={i} {...sb} />)}
					</div>
					<Button className='blue add-button' onClick={openAddHospitalDialog}>
						<img src={add} alt='add' />
						<span>{t('hospital.rh')}</span>
					</Button>
				</div>
				<WhiteMiddleBox {...listContents} />
				<WhiteBottomBox {...pagination} />
			</ContentBoxWhite>
			<Footer />
			<Dialog {...dialogState} />
		</DefaultLayout>
	);
};

export default List;
import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "@atoms/Button";
import { useDownloadPDF } from "@hooks/useDownloadPDF";
import { riskAssmtDateState } from "@models/recoilStates";
import { PastASCVDDetail } from "@organisms/Ascvd";

const AscvdResult = () => {
	const { t } = useTranslation();
	const { ascvdSeq } = useParams();

	const riskAssmtDate = useRecoilValue(riskAssmtDateState);

	const { pageRef, excludeRef, downloadPDF } = useDownloadPDF();

	return (
		<div className='ascvd-download-wrap' ref={pageRef}>
			<header>
				<h1>{t('patient.ascvdrar')}</h1>
				<Button
					className='blue'
					onClick={() => downloadPDF(`ASCVD-${riskAssmtDate}`)}
					ref={excludeRef}
					style={{ padding: '10px 16px' }}>{t('patient.download')}</Button>
			</header>
			<main>
				<PastASCVDDetail ascvdSeq={Number(ascvdSeq || 0)} />
			</main>
		</div>
	);
};

export default AscvdResult;
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { findDangerousPatients, sendEncouragementPush } from "@apis/dashboard";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { DashboardBottomProps, DashboardDangerPatientProps } from "@models/layoutTypes";
import {
	apiFunctionState,
	dialogIDRecoilState,
	isSubmitButtonDisabledState,
	loggedInUserState
} from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { DialogButtons } from "@molecules/ModifyPassword";

const List: React.FC<DashboardBottomProps> = ({ refreshCounter, title }) => {
	const { closeDialog, openDialog } = useDialog();
	const { t } = useTranslation();

	const [patients, setPatients] = useState<DashboardDangerPatientProps[]>([]);

	const loggedInUser = useRecoilValue(loggedInUserState);

	const openSendPushDialog = useRecoilCallback(({ set }) => () => {
		const dialogId: string = 'send-push-dialog';
		set(dialogIDRecoilState, dialogId);
		set(apiFunctionState, () => sendEncouragementPush);
		set(isSubmitButtonDisabledState, false);

		openDialog({
			dialogTitle: t('dashboard.spn'),
			dialogContents: t('dashboard.sartypettmtb'),
			dialogButtons: <DialogButtons dialogId={dialogId} submitButtonText={t('dashboard.ysn')} />
		});
	}, [openDialog, t]);

	const openNoPatientsDialog = useRecoilCallback(({ set }) => () => {
		const dialogId: string = 'no-patients-to-send-push-dialog';
		set(dialogIDRecoilState, dialogId);
		set(isSubmitButtonDisabledState, false);

		openDialog({
			dialogClass: 'untitled',
			dialogContents: t('dashboard.nutem'),
			dialogButtons: <Button className='blue' onClick={() => closeDialog(dialogId)}>{t('dashboard.ok')}</Button>
		});
	}, [closeDialog, openDialog, t]);

	useEffect(() => {
		if (loggedInUser.hospitalSeq) {
			findDangerousPatients(loggedInUser.hospitalSeq, (res) => setPatients(res.data), () => setPatients([]));
		}
	}, [loggedInUser.hospitalSeq, refreshCounter]);

	return (
		<div className='dashboard-bottom'>
			<div className='dashboard-bottom-header' style={{ borderBottom: 'none' }}>
				<h2>{title}</h2>
				<Button className='white' style={{ margin: 18, padding: '10px 16px' }}
						onClick={patients.length === 0 ? openNoPatientsDialog : openSendPushDialog}>{t('dashboard.spn')}</Button>
			</div>
			<div className='dashboard-bottom-content' style={{ height: 296, margin: '0 18px', overflowY: 'auto', padding: 0 }}>
				<table style={{ borderCollapse: 'separate' }}>
					<ColGroup widths={['20%', '20%', '23%', '15%', '22%']} />
					<thead>
						<tr>
							<th>{t('patient.name')}</th>
							<th>{t('patient.dob')}</th>
							<th>{t('patient.phone')}</th>
							<th>{t('dashboard.gender')}</th>
							<th>{t('dashboard.lmd')}</th>
						</tr>
					</thead>
					<tbody>
					{patients.map(({ birthDate, contact, gender, lastParticipationDate, name }, index: number) => (
						<tr key={index}>
							<td>{name}</td>
							<td>{birthDate}</td>
							<td>{contact}</td>
							<td>{t(`patient.${gender.toLowerCase()}`)}</td>
							<td>{lastParticipationDate}</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default List;
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { findHospitalDetail } from "@apis/hospitalDetail";
import { modifyHospital, saveHospital } from "@apis/hospitalList";
import { Input } from "@atoms/Input";
import { AI_CATEGORIES, INITIAL_HOSPITAL_FORM_DATA } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { formatPhoneNumber } from "@logics/common";
import { HospitalFormDataProps, HospitalFormProps } from "@models/layoutTypes";
import {
	apiFunctionState,
	hospitalModifyCountState,
	isSubmitButtonDisabledState,
	totalElementsState,
	usedCategoriesState
} from "@models/recoilStates";
import { CheckBoxOption } from "@molecules/ToggleInputs";

const HospitalForm: React.FC<HospitalFormProps> = ({ type }) => {
	const { hospitalSeq } = useParams();
	const { t } = useTranslation();

	const [formData, setFormData] = useState<HospitalFormDataProps>(INITIAL_HOSPITAL_FORM_DATA);
	const [phoneFormat, setPhoneFormat] = useState(type === 'modify');

	const usedCategories = useRecoilValue(usedCategoriesState);

	const phoneRef = useRef<HTMLInputElement>(null);

	const registerHospital = useRecoilCallback(({ set }) => () => {
		saveHospital(formData, () => {
			set(totalElementsState, prev => prev + 1);
			setFormData(INITIAL_HOSPITAL_FORM_DATA);
		}, () => setFormData(INITIAL_HOSPITAL_FORM_DATA));
	}, [formData]);

	const updateHospital = useRecoilCallback(({ set }) => () => {
		modifyHospital(formData, (res) => {
			if (res.status === 200) {
				set(hospitalModifyCountState, prev => prev + 1);
			}
		}, () => {});
	}, [formData]);

	const setSubmittableAndApiFunction = useRecoilCallback(({ reset, set }) => () => {
		const { address, categories, managerContact, managerName, name } = formData;
		const abled: boolean = !!(address && categories.length && managerContact && managerName && name && phoneFormat);

		set(isSubmitButtonDisabledState, !abled);

		if (abled) {
			if (type === 'registration') {
				set(apiFunctionState, () => registerHospital);
			} else {
				set(apiFunctionState, () => updateHospital);
			}
		} else {
			reset(apiFunctionState);
		}
	}, [formData, phoneFormat, registerHospital, type, updateHospital]);

	const handleFormDataChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { checked, name, value } = e.target;
		if (name === 'categories') {
			if (checked) {
				setFormData(prev => ({ ...prev, categories: [...prev.categories, value] }));
			} else {
				setFormData(prev => ({ ...prev, categories: prev.categories.filter(item => item !== value) }));
			}
		} else if (name === 'managerContact') { // 담당자 전화번호 유효성 검사
			setFormData(prev => ({ ...prev, managerContact: formatPhoneNumber(value) }));
		} else {
			setFormData(prev => ({ ...prev, [name]: value }));
		}
	}, []);

	useEffect(() => {
		setPhoneFormat(type === 'modify');
		if (hospitalSeq && type === 'modify') {
			findHospitalDetail(hospitalSeq, (res) => {
				const { address, categories, managerContact, managerName, name, seq } = res.data;
				setFormData({
					address,
					isActive: true,
					managerContact,
					managerName,
					name,
					hospitalSeq: seq,
					categories: categories.map((c: string) => c.toUpperCase()).sort()
				});
			}, () => setFormData(INITIAL_HOSPITAL_FORM_DATA));
		}
	}, [hospitalSeq, type]);

	useEffectAfterMount(() => {
		setSubmittableAndApiFunction();
	}, [setSubmittableAndApiFunction]);

	useEffectAfterMount(() => {
		if (phoneRef.current) {
			setPhoneFormat(phoneRef.current.checkValidity());
		}
	}, [formData.managerContact]);

	return (
		<form className='hospital-form'>
			<div className='input-grp'>
				<label htmlFor="categories">{t('hospital.category')}</label>
				<div style={{ display: 'flex' }}>
					{AI_CATEGORIES.map((category: string) =>
						<CheckBoxOption name='categories' text={category} value={category} key={category}
										disabled={usedCategories.includes(category)}
										onChange={handleFormDataChange}
										checked={formData.categories.includes(category)} />
					)}
				</div>
			</div>
			<div className='input-grp'>
				<label htmlFor="name">{t('hospital.noh')}</label>
				<Input id='name' name='name' type='text' placeholder={t('hospital.pethn')} onChange={handleFormDataChange}
					   value={formData.name} />
			</div>
			<div className='input-grp'>
				<label htmlFor="address">{t('hospital.addr')}</label>
				<Input id='address' name='address' type='text' placeholder={t('hospital.peta')} onChange={handleFormDataChange}
					   value={formData.address} />
			</div>
			<div className='input-grp'>
				<label htmlFor="managerName">{t('hospital.adminn')}</label>
				{type === 'registration'
					? <Input id='managerName' name='managerName' type='text' placeholder={t('hospital.petnota')}
							 value={formData.managerName} onChange={handleFormDataChange} />
					: <Input id='managerName' name='managerName' type='text' placeholder={t('hospital.petnota')}
							 defaultValue={formData.managerName} disabled />
				}
			</div>
			<div className='input-grp'>
				<label htmlFor="managerContact">{t('hospital.admphone')}</label>
				{type === 'registration'
					? <Input id='managerContact' name='managerContact' type='text' ref={phoneRef} maxLength={13}
							 pattern="010-\d{4}-\d{4}" placeholder={t('patient.pithwe')}
							 value={formData.managerContact} onChange={handleFormDataChange} />
					: <Input id='managerContact' name='managerContact' type='text' ref={phoneRef} maxLength={13}
							 pattern="010-\d{4}-\d{4}" placeholder={t('patient.pithwe')}
							 defaultValue={formData.managerContact} disabled />
				}
			</div>
		</form>
	);
};

export default HospitalForm;
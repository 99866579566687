import React, { useRef } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from "highcharts/modules/accessibility";
highchartsAccessibility(Highcharts); // init module

const options: Highcharts.Options = {
	chart: {
		height: 260,
		reflow: true
	},
	credits: {
		enabled: false
	},
	title: {
		verticalAlign: 'middle',
		text: '<strong>100</strong><br /><span>전체 참여자 수</span>',
		x: -50,
		useHTML: true
	},
	legend: {
		align: 'right',
		itemStyle: {
			fontSize: '16px',
			fontWeight: '500'
		},
		layout: 'vertical',
		symbolRadius: 1,
		symbolWidth: 20,
		y: -65
	},
	plotOptions: {
		pie: {
			innerSize: '75%',
			borderWidth: 4,
			borderRadius: 8,
			dataLabels: {
				enabled: false
			},
			showInLegend: true
		}
	},
	series: [{
		type: 'pie',
		enableMouseTracking: false,
		data: [{
			color: '#1e4bfa',
			name: '알람&nbsp;<b>40</b>',
			y: 40
		}, {
			color: '#789dfb',
			name: '위험&nbsp;<b>30</b>',
			y: 30
		}, {
			color: '#9ddcff',
			name: '주의&nbsp;<b>20</b>',
			y: 20
		}, {
			color: '#e5e5e5',
			name: '정상&nbsp;<b>10</b>',
			y: 10
		}]
	}]
}

const Pie = (props: HighchartsReact.Props) => {
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
			ref={chartComponentRef}
			{...props}
		/>
	);
};

export default Pie;
import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findBPHistoryByPeriod } from "@apis/patientDetail";
import { BP_GRAPH_DATA } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import {
	bpTooltipOptions, bpXAxis, bpYAxis,
	handleMouseOut, handleMouseOver,
	onlyOneNonNull, setGraphDataByRes
} from "@logics/graph";
import { BPGraphProps } from "@models/layoutTypes";
HighchartsMore(Highcharts); // init module
highchartsAccessibility(Highcharts); // init module

function setChartOptions(t: TFunction<"translation", undefined>, { bpArr, pulseArr, xCategories }: BPGraphProps) {
	const result: Highcharts.Options = {
		chart: {
			height: 290,
			reflow: true
		},
		credits: {
			enabled: false
		},
		title: {
			text: undefined
		},
		legend: {
			enabled: false
		},
		xAxis: bpXAxis(xCategories),
		yAxis: bpYAxis(6),
		tooltip: bpTooltipOptions,
		plotOptions: {
			series: {
				connectNulls: true,
				point: {
					events: {
						mouseOver: handleMouseOver,
						mouseOut: handleMouseOut
					}
				}
			}
		},
		series: [{
			color: '#f24949',
			data: bpArr,
			lineWidth: 2,
			name: t('patient.bpavg'),
			pointWidth: 20,
			type: 'errorbar',
			zIndex: 2
		}, {
			color: '#1b59f8',
			data: pulseArr,
			lineWidth: 3,
			marker: {
				enabled: onlyOneNonNull(pulseArr),
				states: {
					hover: {
						enabled: true,
						fillColor: '#fff',
						lineColor: '#1b59f8',
						lineWidth: 4,
						radius: 8
					}
				},
				symbol: 'circle'
			},
			name: t('patient.pulseavg'),
			type: 'spline',
			zIndex: 1
		}]
	}
	return result;
}

const BpTrending = (props: HighchartsReact.Props) => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [arterialPressure, setArterialPressure] = useState(0);
	const [graphData, setGraphData] = useState<BPGraphProps>(BP_GRAPH_DATA);
	const [options, setOptions] = useState<Highcharts.Options>({});
	const [pulsePressure, setPulsePressure] = useState(0);

	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	useEffect(() => {
		findBPHistoryByPeriod(patientSeq, false, props.from, props.to, (res) => {
			setGraphDataByRes(props.from, props.to, res, setGraphData);
			setPulsePressure(res.data.pulsePressure.toFixed(1));
			setArterialPressure(res.data.arterialPressure.toFixed(1));
		});
	}, [patientSeq, props.from, props.to]);

	useEffectAfterMount(() => {
		setOptions(setChartOptions(t, graphData));
	}, [t, graphData]);

	return (
		<>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
				ref={chartComponentRef}
				{...props}
			/>
			<div className='bp-monthly-average'>
				<div>
					<span>{t('patient.mpp')}</span>
					<strong>{pulsePressure}</strong>
				</div>
				<div>
					<span>{t('patient.map')}</span>
					<strong>{arterialPressure}</strong>
				</div>
			</div>
		</>
	);
};

export default BpTrending;
import React from 'react';
import dataMatrix from '@styles/images/data-matrix-scai.png';

const Scai = () => {
	return (
		<>
			<ul>
				<li>
					<strong>품목명</strong>
					<span>피부암 영상 검출∙진단보조 소프트웨어</span>
				</li>
				<li>
					<strong>모델명</strong>
					<span>SCP-AI-01</span>
				</li>
				<li>
					<strong>제품명</strong>
					<span>canofy MD SCAI</span>
				</li>
				<li>
					<strong>허가번호</strong>
					<span>제허24-421호</span>
				</li>
				<li>
					<strong>제조자</strong>
					<span>㈜라이프시맨틱스<br/>서울특별시 강남구 삼성로95길 27, 3층</span>
				</li>
				<li>
					<strong>제조연월</strong>
					<span>2024-07-19</span>
				</li>
				<li>
					<strong>포장단위</strong>
					<span>1개</span>
				</li>
				<li>
					<strong>소프트웨어 버전</strong>
					<span>1.1.1</span>
				</li>
			</ul>
			<p>의료영상 내에서 피부암 의심 부위를 검출한 후 윤곽선, 색상 또는 지시선 등으로 표시하거나 피부암의 유무, 피부암의 중증도 또는 피부암의 상태 등에 대한 가능성 정도를 자동으로 표시하여 의료인의 진단 결정을 보조하는 데 사용하는 소프트웨어</p>
			<h3>본 제품은 의료기기입니다.</h3>
			<div className='data-matrix-code'>
				<img src={dataMatrix} alt='Data Matrix' />
				<ul>
					<li>(01)0889528440129</li>
					<li>(11)240719</li>
				</ul>
			</div>
		</>
	);
};

export default Scai;
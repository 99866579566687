import React from 'react';
import { useTranslation } from "react-i18next";
import { ColGroupProps, PHI } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const PatientHealthInformation: React.FC<PHI> = ({ bmi, diabetes, hbpMedicine, height, smoke, weight }) => {
	const { t } = useTranslation();

	return (
		<div className='hc-result-col'>
			<h3>{t('patient.phi')}</h3>
			<table className='hc-result-table'>
				<ColGroup {...widths} />
				<tbody>
					<tr>
						<th>{t('patient.height')}</th>
						<td>{height} cm</td>
						<th>{t('patient.weight')}</th>
						<td>{weight} kg</td>
						<th>BMI</th>
						<td>{bmi} kg/m²</td>
					</tr>
					<tr>
						<th>{t('patient.smkstatus')}</th>
						<td>{smoke ? 'Y' : 'N'}</td>
						<th>{t('patient.diabetesstatus')}</th>
						<td>{diabetes ? 'Y' : 'N'}</td>
						<th>{t('patient.thm')}</th>
						<td>{hbpMedicine ? 'Y' : 'N'}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default PatientHealthInformation;
import React from 'react';
import { OptionProps } from "@models/optionProps";
import { RadioOption } from "@molecules/ToggleInputs";

interface DateUnitSelectorProps {
	options: OptionProps[];
}

const Selector: React.FC<DateUnitSelectorProps> = ({ options }) => {
	return (
		<div className='date-unit-selector'>
			{options.map((option, i) => <RadioOption key={i} {...option} />)}
		</div>
	);
};

export default Selector;
import React from 'react';
import { Trans, useTranslation } from "react-i18next";

interface Props {
	left: number;
	top: number;
}

const RiskAssessment: React.FC<Props> = ({ left, top }) => {
	const { t } = useTranslation();

	return (
		<div className='tooltip-container' style={{ left, top }}>
			<h3>{t('patient.rac')}</h3>
			<div className='tooltip-contents'>
				<h4><Trans i18nKey='patient.racandidates' components={{ span: <span /> }} /></h4>
				<ul>
					<li>{t('patient.racandidates1')}</li>
					<li>{t('patient.racandidates2')}</li>
					<li>{t('patient.racandidates3')}</li>
				</ul>
			</div>
			<div className='tooltip-contents'>
				<h4>{t('patient.whenimpossible')}</h4>
				<ul>
					<li>{t('patient.whenimpossible1')}</li>
					<li>{t('patient.whenimpossible2')}</li>
				</ul>
			</div>
			<div className='tooltip-contents'>
				<h4>{t('patient.cferad')}</h4>
				<ul>
					<li>{t('patient.cferad1')}</li>
					<li>{t('patient.cferad2')}</li>
				</ul>
			</div>
		</div>
	);
};

export default RiskAssessment;
import React from 'react';
import dataMatrix from "@styles/images/data-matrix-hdai.png";

const Hdai = () => {
	return (
		<>
			<ul>
				<li>
					<strong>품목명</strong>
					<span>병원진료용 소프트웨어</span>
				</li>
				<li>
					<strong>모델명</strong>
					<span>HDA-AI-01</span>
				</li>
				<li>
					<strong>제품명</strong>
					<span>canofy MD HDAI</span>
				</li>
				<li>
					<strong>허가번호</strong>
					<span>제허24-307호</span>
				</li>
				<li>
					<strong>제조자</strong>
					<span>㈜라이프시맨틱스<br/>서울특별시 강남구 삼성로95길 27, 3층</span>
				</li>
				<li>
					<strong>제조연월</strong>
					<span>2024-07-19</span>
				</li>
				<li>
					<strong>포장단위</strong>
					<span>1개</span>
				</li>
				<li>
					<strong>소프트웨어 버전</strong>
					<span>1.1.1</span>
				</li>
			</ul>
			<p>확대경(더모스코피: Dermoscopy) 장비가 부착된 스마트폰 카메라를 통해 수집된 면도된 두피 이미지를 기반으로 시술 혹은 수술 부위의 모발 개수 분석을 수행하고 이식 및 채취가 가능한 모발 수를 의사에게 제공하는 데 사용되는 소프트웨어</p>
			<h3>본 제품은 의료기기입니다.</h3>
			<div className='data-matrix-code'>
				<img src={dataMatrix} alt='Data Matrix' />
				<ul>
					<li>(01)08809528440136</li>
					<li>(11)240719</li>
				</ul>
			</div>
		</>
	);
};

export default Hdai;
import React, { useEffect } from 'react';
import { getCookie } from "typescript-cookie";
import { redirection } from "@apis/t3q";
import { useT3QHooks } from "@hooks/useT3QHooks";

const T3Q = () => {
	const { tokenVerification } = useT3QHooks();

	useEffect(() => {
		const accessToken = getCookie('access_token');
		const refreshToken = getCookie('refresh_token');
		if (accessToken && refreshToken) {
			// 토큰 검증
			tokenVerification(accessToken, refreshToken);
		} else {
			// 리다이렉션
			redirection();
		}
	}, []);

	return <></>;
};

export default T3Q;
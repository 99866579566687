import React from 'react';
import { useRecoilCallback } from "recoil";
import { PaginationProps } from "@models/layoutTypes";
import { pageNumberState } from "@models/recoilStates";

const White: React.FC<PaginationProps> = ({ first, last, number, totalPages }) => {
	// 1부터 totalPages까지의 숫자 배열 생성
	const pages: number[] = Array.from({ length: totalPages }, (_, i: number) => i + 1);

	const findThisPage = useRecoilCallback(({ set }) => (e: React.MouseEvent<HTMLLIElement>) => {
		const pn: string | undefined = e.currentTarget.dataset.page;
		set(pageNumberState, Number(pn!));
	}, []);

	return (
		<div className='white-bottom-box'>
			<ul className='pagination'>
				<li className={`page-item first ${first ? 'disabled' : ''}`}
					data-page={0}
					onClick={!first ? findThisPage : undefined}></li>
				<li className={`page-item prev ${first ? 'disabled' : ''}`}
					data-page={number - 1}
					onClick={!first ? findThisPage : undefined}></li>
				{pages.length === 0
					? <li className='page-item active'>1</li>
					: pages.map(page => (
						<li key={page}
							className={`page-item ${page === number + 1 ? 'active' : ''}`}
							data-page={page - 1}
							onClick={findThisPage}>{page}</li>
					))
				}
				<li className={`page-item next ${last ? 'disabled' : ''}`}
					data-page={number + 1}
					onClick={!last ? findThisPage : undefined}></li>
				<li className={`page-item last ${last ? 'disabled' : ''}`}
					data-page={totalPages - 1}
					onClick={!last ? findThisPage : undefined}></li>
			</ul>
		</div>
	);
};

export default White;
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findPredictionResult } from "@apis/patientDetail";
import { INITIAL_PREDICTED_VALUES } from "@constants/common";
import { ColGroupProps, IsBitProps, SysDias } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";

const widths: ColGroupProps = { widths: ['16%', ...Array(4).fill('21%')] };

const Value: React.FC<IsBitProps> = ({ isBit }) => {
	const { t } = useTranslation();
	const { patientSeq } = useParams();
	const [predictions, setPredictions] = useState<SysDias[]>(INITIAL_PREDICTED_VALUES);

	const after: string = useMemo(() => t('patient.after'), [t]);
	const nweek: string = useMemo(() => t('patient.nweek'), [t]);

	useEffect(() => {
		if (patientSeq) {
			findPredictionResult(patientSeq, isBit,
				(res) => setPredictions(Object.values(res.data)),
				() => setPredictions(INITIAL_PREDICTED_VALUES));
		}
	}, [isBit, patientSeq]);

	return (
		<table>
			<ColGroup {...widths} />
			<thead>
				<tr>
					<th />
					<th>{after} 1{nweek}</th>
					<th>{after} 2{nweek}</th>
					<th>{after} 3{nweek}</th>
					<th>{after} 4{nweek}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th>{t('patient.systole')}</th>
					{predictions.map(p => <td>{p.systolic}</td>)}
				</tr>
				<tr>
					<th>{t('patient.diastole')}</th>
					{predictions.map(p => <td>{p.diastolic}</td>)}
				</tr>
			</tbody>
		</table>
	);
};

export default Value;
import React, { useEffect, useState } from 'react';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { findDashboardStatistics } from "@apis/dashboard";
import { Button } from "@atoms/Button";
import { INITIAL_DASHBOARD_STATISTICS } from "@constants/common";
import { formatEnglishDate, formatKoreanDate } from "@logics/common";
import { DashboardMiddleBoxProps, DialogProps, HeaderProps, LoggedInUserProps } from "@models/layoutTypes";
import { columnGraphDateState, dialogRecoilState, loggedInUserState } from "@models/recoilStates";
import { Dialog } from "@molecules/Dialog";
import { Footer } from "@molecules/Footer";
import { DashboardGraph, DashboardList, GrayBottomBox } from "@organisms/BottomBox";
import { DashboardMiddleBox, GrayMiddleBox } from "@organisms/MiddleBox";
import { GrayTopBox } from "@organisms/TopBox";
import icRefresh from '@styles/images/ic-refresh.png';
import { ContentBoxGray } from "@templates/ContentBox";
import { DefaultLayout } from "@templates/DefaultLayout";

const Dashboard: React.FC<HeaderProps> = (headerProps) => {
	const lng: string = i18next.language;

	const { t } = useTranslation();

	const [middleBoxProps, setMiddleBoxProps] = useState<DashboardMiddleBoxProps>(INITIAL_DASHBOARD_STATISTICS);
	const [refreshCounter, setRefreshCounter] = useState<number>(0);

	const dialogState: DialogProps = useRecoilValue(dialogRecoilState);
	const loggedInUser: LoggedInUserProps = useRecoilValue(loggedInUserState);

	const setDateForColumn = useSetRecoilState(columnGraphDateState);

	useEffect(() => {
		setDateForColumn(new Date());
		findDashboardStatistics((res) => setMiddleBoxProps(res.data), () => setMiddleBoxProps(INITIAL_DASHBOARD_STATISTICS));
	}, [refreshCounter, setDateForColumn]);

	return (
		<DefaultLayout {...headerProps}>
			<ContentBoxGray>
				<GrayTopBox className='colorless flex'>
					<h1>Welcome, {loggedInUser.name}</h1>
					<div className='update-at'>
						<p>{t('dashboard.today')}, {lng === 'en' ? formatEnglishDate(new Date()) : formatKoreanDate(new Date())}</p>
						<Button onClick={() => setRefreshCounter(prev => prev + 1)}>
							<img src={icRefresh} alt='새로고침' />
						</Button>
					</div>
				</GrayTopBox>
				<GrayMiddleBox className='colorless flex'>
					<DashboardMiddleBox
						refreshCounter={refreshCounter}
						title={t('dashboard.ru')}
						count={middleBoxProps.totalPatient}
						trend={middleBoxProps.totalPatientDirection} />
					<DashboardMiddleBox
						refreshCounter={refreshCounter}
						title={t('dashboard.pdm')}
						count={middleBoxProps.yesterdayMeasurePatient}
						trend={middleBoxProps.yesterdayMeasureDirection} />
					<DashboardMiddleBox
						refreshCounter={refreshCounter}
						title={t('dashboard.tm')}
						count={middleBoxProps.todayMeasurePatient}
						trend={middleBoxProps.todayMeasureDirection} />
					<DashboardMiddleBox
						refreshCounter={refreshCounter}
						title={t('dashboard.cbpm')}
						count={middleBoxProps.accumulatePredicationPatient}
						trend={middleBoxProps.accumulatePredicationDirection} />
				</GrayMiddleBox>
				<GrayBottomBox className='colorless flex'>
					<DashboardList refreshCounter={refreshCounter} title={t('dashboard.ue')} />
					<DashboardGraph refreshCounter={refreshCounter} title={t('dashboard.dm')} />
				</GrayBottomBox>
			</ContentBoxGray>
			<Footer />
			<Dialog {...dialogState} />
		</DefaultLayout>
	);
};

export default Dashboard;
import React from 'react';
import { MiddleBoxProps } from "@models/layoutTypes";

const White: React.FC<MiddleBoxProps> = ({ cols, listHead, listBody, type }) => {
	return (
		<div className='white-middle-box'>
			<table className={type}>
				<colgroup>{cols}</colgroup>
				<thead className='list-head'>{listHead}</thead>
				<tbody className='list-body'>{listBody}</tbody>
			</table>
		</div>
	);
};

export default White;
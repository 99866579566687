import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { findPatientPersonalInfo } from '@apis/patientDetail';
import { Button } from '@atoms/Button';
import { INITIAL_PATIENT_REPORT_DATA } from '@constants/common';
import { useDownloadPDF } from '@hooks/useDownloadPDF';
import { BloodPressureDetail } from '@organisms/BloodPressure';
import canofyLogo from '@styles/images/img_small_logo.png';

const BpDetails = () => {
	const { pageRef, excludeRef, downloadPDF } = useDownloadPDF();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [patientData, setPatientData] = useState(INITIAL_PATIENT_REPORT_DATA);

	useEffect(() => {
		if (patientSeq) {
			findPatientPersonalInfo(patientSeq, 'bpai', false,
				res => setPatientData(res.data),
				() => setPatientData(INITIAL_PATIENT_REPORT_DATA));
		}
	}, [patientSeq]);

	return (
		<div className='bp-details-wrap' ref={pageRef}>
			<header>
				<h1>{t('patient.dhbpl')}</h1>
				<div style={{ alignItems: 'center', display: 'flex', gap: 10 }}>
					<Button
						className="blue"
						onClick={() => downloadPDF(`${patientData.name}-detailed-blood-pressure-list`)}
						ref={excludeRef}
						style={{ padding: '10px 16px' }}>{t('patient.download')}</Button>
					<img src={canofyLogo} alt="canofy logo" />
				</div>
			</header>
			<main style={{ gap: 25 }}>
				<div className='patient-info'>
					<div>
						<p>{t('patient.name')}</p>
						<p>{patientData.name}</p>
					</div>
					<div>
						<p>{t('dashboard.gender')}</p>
						<p>{t(`patient.${patientData.gender.toLowerCase()}`)}</p>
					</div>
					<div>
						<p>{t('patient.dob')}</p>
						<p>{patientData.birthDate}</p>
					</div>
					<div>
						<p>{t('patient.height')}/{t('patient.weight')}</p>
						<p>{patientData.height}cm/{patientData.weight}kg</p>
					</div>
				</div>
				<BloodPressureDetail />
			</main>
		</div>
	);
};

export default BpDetails;
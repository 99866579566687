import React, { useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { HeaderProps } from "@models/layoutTypes";
import { aiModelState, dialogIDRecoilState, dialogRecoilState, modifyPasswordSucceedState } from "@models/recoilStates";
import { Dialog } from "@molecules/Dialog";
import { Footer } from "@molecules/Footer";
import { ASCVDTestResult } from "@organisms/Ascvd";
import { BloodPressureChange, BloodPressureTrending, MultifacetedAnalysis } from "@organisms/BloodPressure";
import { GrayBottomBox } from "@organisms/BottomBox";
import { HCAIHistory, HCAITestResult } from "@organisms/HcaiDetail";
import { GrayMiddleBox } from "@organisms/MiddleBox";
import { BPAIPatientBasicInfo, HCAIPatientBasicInfo } from "@organisms/PatientInfo";
import { GrayTopBox } from "@organisms/TopBox";
import { TreatmentHistory } from "@organisms/TreatmentHistory";
import { ContentBoxGray } from "@templates/ContentBox";
import { DefaultLayout } from "@templates/DefaultLayout";

const BPAIContents = () => {
	return (
		<>
			<GrayTopBox>
				<BPAIPatientBasicInfo />
			</GrayTopBox>
			<GrayMiddleBox>
				<BloodPressureTrending />
				<BloodPressureChange isReport={false} moreBtn reverse rows={5} title toggle unit='WEEK' isBit={false} />
			</GrayMiddleBox>
			{/* TODO 삭제하지 말고 일단 주석 */}
			{/*<GrayBottomBox className='padding-18'>
				<TreatmentHistory />
			</GrayBottomBox>*/}
		</>
	);
}

const HCAIContents = () => {
	return (
		<>
			<GrayTopBox>
				<HCAIPatientBasicInfo />
			</GrayTopBox>
			<GrayMiddleBox>
				<HCAITestResult />
			</GrayMiddleBox>
			<GrayBottomBox className='padding-18'>
				<HCAIHistory />
			</GrayBottomBox>
		</>
	);
}

const Detail: React.FC<HeaderProps> = (headerProps) => {
	const { closePwdModifyResultDialog, openDialog } = useDialog();

	const { t } = useTranslation();

	const aiModel = useRecoilValue(aiModelState);
	const dialogState = useRecoilValue(dialogRecoilState);
	const modifyPasswordSucceed = useRecoilValue(modifyPasswordSucceedState);

	const setDialogID = useSetRecoilState(dialogIDRecoilState);

	const mainContents = useMemo(() => {
		switch (aiModel) {
			case "BPAI":
				return <BPAIContents />;
			case "HCAI":
				return <HCAIContents />;
			default:
				return null;
		}
	}, [aiModel]);

	const subContents = useMemo(() => {
		switch (aiModel) {
			case "BPAI":
				return <MultifacetedAnalysis />;
			case "HCAI":
				return <ASCVDTestResult />;
			default:
				return null;
		}
	}, [aiModel]);

	useEffect(() => {
		if (modifyPasswordSucceed !== undefined) {
			setDialogID(modifyPasswordSucceed ? 'password-modified-dialog' : 'password-modify-failed-dialog');
			openDialog({
				dialogClass: 'untitled',
				dialogContents: modifyPasswordSucceed ? t('profile.pcs') : t('profile.pcf'),
				dialogButtons: <Button className='blue' onClick={closePwdModifyResultDialog}>{t('dashboard.ok')}</Button>
			});
		}
	}, [closePwdModifyResultDialog, modifyPasswordSucceed, openDialog, setDialogID, t]);

	return (
		<DefaultLayout {...headerProps}>
			<div style={{ display: 'flex', height: '100%' }}>
				<ContentBoxGray style={{ width: '70%' }}>{mainContents}</ContentBoxGray>
				{subContents}
			</div>
			<Footer />
			<Dialog {...dialogState} />
		</DefaultLayout>
	);
};

export default Detail;
import { CSSProperties } from "react";
import { formatDate } from "@logics/common";
import {
	AnalyticsResultProps, AscvdResult,
	BPGraphProps, ClassificationProps, DashboardMiddleBoxProps,
	HospitalFormDataProps,
	PaginationProps, PasswordForm, PastAscvdDetailProps, PatientFormDataProps, PatientReportDataProps,
	PredictionProps, SysDias, T3QHospitalFormDataProps, UserDetailProps
} from "@models/layoutTypes";
import { SelectBoxOptionProps } from "@models/optionProps";

// ○○AI 카테고리
export const AI_CATEGORIES: string[] = ['BPAI', 'HCAI', 'HDAI', 'SCAI'];

// 혈압 그래프 데이터
export const BP_GRAPH_DATA: BPGraphProps = {
	bpArr: [[null, null]],
	pulseArr: [null],
	xCategories: ['']
};

// 음주 여부 옵션
export const DRINK_OPTIONS: SelectBoxOptionProps[] = [
	{
		label: 'Y',
		value: 'Y'
	},
	{
		label: 'N',
		value: 'N'
	}
];

// 환자 검색 필터 - BPAI
export const BPAI_FILTER_VALUES: string[] = ['NORMAL', 'WARNING', 'DANGEROUS', 'WAITING'];

export const HCAI_FILTER_VALUES: string[] = ['POSITIVE', 'NEGATIVE', 'AWAITING_EVALUATION'];

// 진료 기록 분석 결과 기본값
export const INITIAL_ANALYTICS_RESULT: AnalyticsResultProps = {
	bpControl: '-',
	diseaseRisk: '-',
	goalDiastolic: -1,
	goalLDL: -1,
	goalSystolic: -1,
	medicationControl: '-'
};

// ascvd 그래프 레전드 스타일 기본값
export const INITIAL_ASCVD_GRAPH_LEGEND_STYLE: CSSProperties = { marginTop: 0 };

// yyyy-mm-dd 형식의 날짜 기본값
export const INITIAL_DATE_STRING_HYPHEN: string = formatDate(new Date(), '-');

// ascvd 결과 기본값
export const INITIAL_RISK_ASSESSMENT_RESULT: AscvdResult = {
	positive: 0,
	negative: 0
}

export const INITIAL_PAST_ASCVD_DETAIL: PastAscvdDetailProps = {
	ascvdSeq: 0,
	bloodInspectResultInfo: {},
	bloodPressureInspectResultInfo: {},
	patientHealthInfo: {},
	patientInfo: {
		age: 0,
		ascvdCreateAt: '',
		gender: 'male',
		name: ''
	},
	riskAssessmentDate: '',
	riskAssessmentResultInfo: INITIAL_RISK_ASSESSMENT_RESULT
}

// 진료 기록 분석 분류 기본값
export const INITIAL_CLASSIFICATION: ClassificationProps = {
	bpControl: '',
	diastolic: 0,
	ldl: 0,
	medicationControl: '',
	risk: '',
	systolic: 0
}

// 대시보드 측정자 화살표 부분 기본값
export const INITIAL_DASHBOARD_STATISTICS: DashboardMiddleBoxProps = {
	totalPatient: 0,
	totalPatientDirection: 'STAY',
	yesterdayMeasurePatient: 0,
	yesterdayMeasureDirection: 'STAY',
	todayMeasurePatient: 0,
	todayMeasureDirection: 'STAY',
	accumulatePredicationPatient: 0,
	accumulatePredicationDirection: 'STAY'
}

// 병원 정보 입력 폼 기본값
export const INITIAL_HOSPITAL_FORM_DATA: HospitalFormDataProps = {
	address: '',
	categories: [],
	managerContact: '',
	managerName: '',
	name: ''
};

// 병원 정보 기본값
export const INITIAL_HOSPITAL_INFO: HospitalFormDataProps = {
	address: '',
	categories: [],
	managerContact: '',
	managerName: '',
	name: '',
	registerDate: ''
}

// 페이징 기본값
export const INITIAL_PAGINATION: PaginationProps = {
	first: true,
	last: true,
	number: 0,
	totalPages: 1
};

// 비밀번호 입력 폼 기본값
export const INITIAL_PASSWORD_FORM_DATA: PasswordForm = {
	password: '',
	repeatPassword: ''
};

// 환자 정보 입력 폼 기본값
export const INITIAL_PATIENT_FORM_DATA: PatientFormDataProps = {
	birthDate: formatDate(new Date(1970, 0, 1), '-'),
	contact: '',
	name: '',
	gender: 'MALE'
};

// 레포트용 환자 정보 기본값
export const INITIAL_PATIENT_REPORT_DATA: PatientReportDataProps = {
	birthDate: '',
	gender: 'MALE',
	height: 0,
	name: '',
	weight: 0
};

// 혈압 분석값 기본값
export const INITIAL_PREDICTED_VALUES: SysDias[] = new Array(4).fill({ systolic: 0, diastolic: 0 });

// 혈압 분석 그래프 데이터 기본값
export const INITIAL_PREDICTION: PredictionProps = {
	measuredDateCount: 0,
	participantRate: 0,
	predictable: false
};

export const INITIAL_T3Q_HOSPITAL_FORM_DATA: T3QHospitalFormDataProps = {
	address: '',
	categories: [],
	isActive: true,
	name: ''
};

// 의료진 상세 정보 기본값
export const INITIAL_USER_DETAIL: UserDetailProps = {
	categories: [],
	contact: '',
	createdAt: '',
	id: '',
	name: '',
	status: '',
	userType: ''
}

// 언어 옵션
export const LANGUAGE_OPTIONS: SelectBoxOptionProps[] = [
	{
		label: '한국어',
		value: 'ko'
	},
	{
		label: 'English',
		value: 'en'
	}
];

// 흡연 여부 옵션
export const SMOKE_OPTIONS: SelectBoxOptionProps[] = [
	{
		label: 'Y',
		value: 'Y'
	},
	{
		label: 'N',
		value: 'N'
	}
];

// uprot 옵션
export const UPROT_OPTIONS: SelectBoxOptionProps[] = [
	{
		label: 'Negative',
		value: 'NEGATIVE'
	},
	{
		label: 'Trace',
		value: 'TRACE'
	},
	{
		label: '1+',
		value: 'ONE_PLUS'
	},
	{
		label: '2+',
		value: 'TWO_PLUS'
	},
	{
		label: '3+',
		value: 'THREE_PLUS'
	},
	{
		label: '4+',
		value: 'FOUR_PLUS'
	}
];

// Y/N 옵션
export const YN_OPTIONS: SelectBoxOptionProps[] = [
	{
		label: 'Y',
		value: 'Y'
	},
	{
		label: 'N',
		value: 'N'
	}
];
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modifyTestResult, saveTestResult } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { Input } from "@atoms/Input";
import { INITIAL_DATE_STRING_HYPHEN } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { handleZero, isAllTruthy, isInRange } from "@logics/common";
import { AscvdFormProps, BIRI, BPIRI, PHI, TestResultFormProps } from "@models/layoutTypes";
import { SelectBoxOptionProps } from "@models/optionProps";
import {
	apiFunctionState,
	ascvdResultChangeState,
	isSubmitButtonDisabledState,
	loggedInUserState
} from "@models/recoilStates";
import { DatePicker } from "@molecules/DatePicker";
import { SelectBox } from "@molecules/SelectBox";
import icDown from '@styles/images/ic-down.png';
import icReset from '@styles/images/ic-reset.png';
import icUp from '@styles/images/ic-up.png';

interface FoldableProps {
	phi: boolean;
	bptr: boolean;
	btr: boolean;
}

type ResetProps = 'biri' | 'bpiri' | 'phi';

interface Invalidity {
	[key: string]: boolean
}

const SELECT_OPTIONS: SelectBoxOptionProps[] = [
	{
		label: 'Y',
		value: '1'
	},
	{
		label: 'N',
		value: '0'
	}
];

const INITIAL_BIRI: BIRI = {
	appt: undefined,
	ast: undefined,
	hdlCholesterol: undefined,
	pdw: undefined,
	phosphorus: undefined,
	totalCholesterol: undefined
}

const INITIAL_BPIRI: BPIRI = {
	ppMax: undefined,
	ppMean: undefined,
	ppMin: undefined,
	ppSd: undefined,
	sbpMax: undefined,
	sbpMean: undefined,
	sbpMin: undefined,
	sbpSd: undefined
}

const INITIAL_PHI: PHI = {
	bmi: undefined,
	diabetes: undefined,
	hbpMedicine: undefined,
	height: undefined,
	smoke: undefined,
	weight: undefined
}

const INITIAL_FOLDABLE_OBJ: FoldableProps = {
	phi: false,
	bptr: false,
	btr: false
}

const TestResultForm: React.FC<TestResultFormProps> = (props) => {
	const lng: string = i18next.language;

	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [foldableObj, setFoldableObj] = useState<FoldableProps>(INITIAL_FOLDABLE_OBJ);
	const [biriData, setBiriData] = useState<BIRI>(props.bloodInspectResultInfo || INITIAL_BIRI);
	const [biriInvalid, setBiriInvalid] = useState(false);
	const [bpiriData, setBpiriData] = useState<BPIRI>(props.bloodPressureInspectResultInfo || INITIAL_BPIRI);
	const [bpiriInvalid, setBpiriInvalid] = useState(false);
	const [phiData, setPhiData] = useState<PHI>(props.patientHealthInfo || INITIAL_PHI);
	const [phiInvalid, setPhiInvalid] = useState(false);
	const [formData, setFormData] = useState<AscvdFormProps>({
		ascvdSeq: props.ascvdSeq,
		bloodInspectResultInfo: biriData,
		bloodPressureInspectResultInfo: bpiriData,
		patientHealthInfo: phiData,
		riskAssessmentDate: props.riskAssessmentDate || INITIAL_DATE_STRING_HYPHEN
	});

	const [invalidity, setInvalidity] = useState<Invalidity>(() => {
		const obj = { ...biriData, ...bpiriData };
		let result: Invalidity = {};
		for (const key in obj) {
			result[key] = false;
		}

		return result;
	});

	const { seq } = useRecoilValue(loggedInUserState);

	const setApiFunction = useSetRecoilState(apiFunctionState);
	const setChangeCnt = useSetRecoilState(ascvdResultChangeState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	const addTestResult = useCallback(() => {
		if (patientSeq) {
			saveTestResult(formData, patientSeq, seq, () => {
				setChangeCnt(prev => prev + 1);
			}, () => {
				alert('실패');
			});
		}
	}, [formData, patientSeq, seq, setChangeCnt]);

	const editTestResult = useCallback(() => {
		if (patientSeq) {
			modifyTestResult(formData, patientSeq, () => {
				setChangeCnt(prev => prev + 1);
			}, () => {
				alert('실패');
			});
		}
	}, [formData, patientSeq, setChangeCnt]);

	// 혈액 검사 결과 변경 핸들링
	const handleBIRIChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { max, min, name, value } = e.target;
		const val = value ? handleZero(value) : value;
		setBiriData(prev => ({ ...prev, [name]: val }));

		const v: boolean = Number(max) >= Number(val) && Number(min) <= Number(val);
		setBiriInvalid(!v);
		setInvalidity(prev => ({ ...prev, [name]: !v }));
	}, []);

	// 혈압 검사 결과 변경 핸들링
	const handleBPIRIChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { max, min, name, value } = e.target;
		const val = value ? handleZero(value) : value;
		setBpiriData(prev => ({ ...prev, [name]: val }));

		const v: boolean = Number(max) >= Number(val) && Number(min) <= Number(val);
		setBpiriInvalid(!v);
		setInvalidity(prev => ({ ...prev, [name]: !v }));
	}, []);

	// number 타입의 input에서 'e', 'E', '-' 및 '+' 키를 차단
	const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		if (['e', 'E', '-', '+'].includes(e.key)) {
			e.preventDefault();
		}
	}, []);

	// 환자 건강 정보 변경 핸들링
	const handlePHIChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { min, name, value } = e.target;
		const val = value ? handleZero(value) : value;
		setPhiData(prev => ({ ...prev, [name]: val }));

		const v: boolean = Number(min) > Number(val);
		setPhiInvalid(v);
		setInvalidity(prev => ({ ...prev, [name]: v }));
	}, []);

	// 해당 영역의 입력값 초기화
	const resetInputs = useCallback((group: ResetProps) => {
		switch (group) {
			case "biri":
				const newBiriData = props.bloodInspectResultInfo || INITIAL_BIRI;
				setBiriData(newBiriData);
				setBiriInvalid(false);

				const newBiriInvalidity: Invalidity = {};
				for (const key in newBiriData) {
					newBiriInvalidity[key] = false;
				}
				setInvalidity(prev => ({ ...prev, ...newBiriInvalidity }));
				break;
			case "bpiri":
				const newBpiriData = props.bloodPressureInspectResultInfo || INITIAL_BPIRI;
				setBpiriData(newBpiriData);
				setBpiriInvalid(false);

				const newBpiriInvalidity: Invalidity = {};
				for (const key in newBpiriData) {
					newBpiriInvalidity[key] = false;
				}
				setInvalidity(prev => ({ ...prev, ...newBpiriInvalidity }));
				break;
			case "phi":
				const newPhiData = props.patientHealthInfo || INITIAL_PHI;
				setPhiData(newPhiData);
				setPhiInvalid(false);

				const newPhiInvalidity: Invalidity = {};
				for (const key in newPhiData) {
					newPhiInvalidity[key] = false;
				}
				setInvalidity(prev => ({ ...prev, ...newPhiInvalidity }));
				break;
		}
	}, [props.bloodInspectResultInfo, props.bloodPressureInspectResultInfo, props.patientHealthInfo]);

	useEffect(() => {
		const h = Number(phiData.height);
		const w = Number(phiData.weight);
		if (h && w) {
			setPhiData(prev => ({ ...prev, bmi: Number((w / ((h * 0.01) ** 2)).toFixed(2)) }));
		}
	}, [phiData.height, phiData.weight]);

	useEffectAfterMount(() => {
		setFormData(prev => ({ ...prev, patientHealthInfo: phiData }));
	}, [phiData]);

	useEffectAfterMount(() => {
		setFormData(prev => ({ ...prev, bloodPressureInspectResultInfo: bpiriData }));
	}, [bpiriData]);

	useEffectAfterMount(() => {
		setFormData(prev => ({ ...prev, bloodInspectResultInfo: biriData }));
	}, [biriData]);

	useEffectAfterMount(() => {
		const { bloodInspectResultInfo, bloodPressureInspectResultInfo, patientHealthInfo } = formData;

		const biriTruthy: boolean = isAllTruthy(bloodInspectResultInfo);
		const bpiriTruthy: boolean = isAllTruthy(bloodPressureInspectResultInfo);
		const phiTruthy: boolean = isAllTruthy(patientHealthInfo);

		// isInRange는 모든 값이 입력되었고, 그것이 유효한지 여부를 판단한다.
		const biriValid: boolean = biriTruthy ? isInRange(bloodInspectResultInfo) : false; // 혈액 검사 결과
		const bpiriValid: boolean = bpiriTruthy ? isInRange(bloodPressureInspectResultInfo) : false; // 혈압 검사 결과
		const phiValid: boolean = phiTruthy ? isInRange(patientHealthInfo) : false;

		const submittable: boolean = biriTruthy && bpiriTruthy && phiTruthy && biriValid && bpiriValid && phiValid;
		setIsSubmitButtonDisabled(!submittable);
		if (submittable) {
			if (props.isModifyMode) { // 수정
				setApiFunction(() => editTestResult);
			} else { // 등록
				setApiFunction(() => addTestResult);
			}
		}
	}, [formData]);

	const handleFoldState = (name: keyof FoldableProps) => {
		setFoldableObj({ ...foldableObj, [name]: !foldableObj[name] });
	}

	const handleSelectChange = (name: string) => (value: string) => {
		setPhiData({ ...phiData!, [name]: value === '1' });
	}

	return (
		<form className='ascvd-form' style={{ width: 690 }}>
			<div>
				<div className='rad-with-warn'>
					<h2>{t('patient.rad')}</h2>
					<span>* {t('patient.ascvdwarn')}</span>
				</div>
				<div style={{ width: 'unset' }}>
					<DatePicker id='riskAssessmentDate' name='riskAssessmentDate' onChange={setFormData}
								value={formData.riskAssessmentDate} />
				</div>
			</div>
			<div>
				<div className='foldable'>
					<div className='grid-title'>
						<h2>{t('patient.phi')}</h2>
						{phiInvalid && <span> * {t('patient.prtdye')}</span>}
					</div>
					<div className='buttons'>
						<Button type='button' onClick={() => resetInputs('phi')}>
							<img src={icReset} alt='reset' />
						</Button>
						<Button type='button' onClick={() => handleFoldState('phi')}>
							<img src={foldableObj.phi ? icDown : icUp} alt='up down icon' />
						</Button>
					</div>
				</div>
				<div className='grid-container three' style={{ display: foldableObj.phi ? 'none' : '' }}>
					<div className='grid-item'>
						<label htmlFor='height'>{t('patient.height')} (cm)</label>
						<Input type='number' id='height' name='height'
							   className={invalidity.height ? 'invalid' : ''}
							   value={phiData?.height ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handlePHIChange} min={0.1}
							   placeholder='00.0 cm' step={0.1}
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='weight'>{t('patient.weight')} (kg)</label>
						<Input type='number' id='weight' name='weight'
							   className={invalidity.weight ? 'invalid' : ''}
							   value={phiData?.weight ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handlePHIChange} min={0.1}
							   placeholder='00.0 kg' step={0.1}
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='bmi'>BMI (kg/m²)</label>
						<Input type='number' id='bmi' name='bmi' disabled
							   value={phiData?.bmi ?? ''}
							   placeholder='Auto Calculate'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='smoke'>{t('patient.smkstatus')}</label>
						<SelectBox
							name='smoke'
							onChange={handleSelectChange('smoke')}
							options={SELECT_OPTIONS}
							placeholder={t('patient.smkstatus')}
							value={phiData?.smoke === undefined ? null : SELECT_OPTIONS.find(option => option.value === (phiData.smoke ? '1' : '0'))}
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='diabetes'>{t('patient.diabetesstatus')}</label>
						<SelectBox
							name='diabetes'
							onChange={handleSelectChange('diabetes')}
							options={SELECT_OPTIONS}
							placeholder={t('patient.diabetesstatus')}
							value={phiData?.diabetes === undefined ? null : SELECT_OPTIONS.find(option => option.value === (phiData.diabetes ? '1' : '0'))}
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='hbpMedicine'>{t('patient.thtnm')}</label>
						<SelectBox
							name='hbpMedicine'
							onChange={handleSelectChange('hbpMedicine')}
							options={SELECT_OPTIONS}
							placeholder={t('patient.tm')}
							value={phiData?.hbpMedicine === undefined ? null : SELECT_OPTIONS.find(option => option.value === (phiData.hbpMedicine ? '1' : '0'))}
						/>
					</div>
				</div>
			</div>
			<div>
				<div className='foldable'>
					<div className='grid-title'>
						<h2>{t('patient.bptr')}</h2>
						{bpiriInvalid && <span> * {t('patient.prtdye')}</span>}
					</div>
					<div className='buttons'>
						<Button type='button' onClick={() => resetInputs('bpiri')}>
							<img src={icReset} alt='reset'/>
						</Button>
						<Button type='button' onClick={() => handleFoldState('bptr')}>
							<img src={foldableObj.bptr ? icDown : icUp} alt='up down icon'/>
						</Button>
					</div>
				</div>
				<div className='grid-container three' style={{ display: foldableObj.bptr ? 'none' : '' }}>
					<div className='grid-item'>
						<label htmlFor='sbpMax'>{t('patient.sbpMax')} (mmHg)</label>
						<Input type='number' id='sbpMax' name='sbpMax'
							   className={invalidity.sbpMax ? 'invalid' : ''}
							   value={bpiriData?.sbpMax ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={80} max={200}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='sbpMin'>{t('patient.sbpMin')} (mmHg)</label>
						<Input type='number' id='sbpMin' name='sbpMin'
							   className={invalidity.sbpMin ? 'invalid' : ''}
							   value={bpiriData?.sbpMin ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={80} max={200}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='sbpMean'>{t('patient.sbpMean')} (mmHg)</label>
						<Input type='number' id='sbpMin' name='sbpMean'
							   className={invalidity.sbpMean ? 'invalid' : ''}
							   value={bpiriData?.sbpMean ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={80} max={200}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='sbpSd'>{t('patient.sbpSd')} (mmHg)</label>
						<Input type='number' id='sbpSd' name='sbpSd'
							   className={invalidity.sbpSd ? 'invalid' : ''}
							   value={bpiriData?.sbpSd ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={0} max={100}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='ppMax'>{t('patient.ppMax')} (mmHg)</label>
						<Input type='number' id='ppMax' name='ppMax'
							   className={invalidity.ppMax ? 'invalid' : ''}
							   value={bpiriData?.ppMax ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={0} max={150}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='ppMin'>{t('patient.ppMin')} (mmHg)</label>
						<Input type='number' id='ppMin' name='ppMin'
							   className={invalidity.ppMin ? 'invalid' : ''}
							   value={bpiriData?.ppMin ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={0} max={150}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='ppMean'>{t('patient.ppMean')}{lng === 'en' ? <br /> : ' '}(mmHg)</label>
						<Input type='number' id='ppMean' name='ppMean'
							   className={invalidity.ppMean ? 'invalid' : ''}
							   value={bpiriData?.ppMean ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={0} max={150}
							   placeholder='00 mmHg'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='ppSd'>{t('patient.ppSd')} (mmHg)</label>
						<Input type='number' id='ppSd' name='ppSd'
							   className={invalidity.ppSd ? 'invalid' : ''}
							   value={bpiriData?.ppSd ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBPIRIChange} min={0} max={100}
							   placeholder='00 mmHg'
						/>
					</div>
				</div>
			</div>
			<div>
				<div className='foldable'>
					<div className='grid-title'>
						<h2>{t('patient.btr')}</h2>
						{biriInvalid && <span> * {t('patient.prtdye')}</span>}
					</div>
					<div className='buttons'>
						<Button type='button' onClick={() => resetInputs('biri')}>
							<img src={icReset} alt='reset' />
						</Button>
						<Button type='button' onClick={() => handleFoldState('btr')}>
							<img src={foldableObj.btr ? icDown : icUp} alt='up down icon' />
						</Button>
					</div>
				</div>
				<div className='grid-container three' style={{ display: foldableObj.btr ? 'none' : '' }}>
					<div className='grid-item'>
						<label htmlFor='totalCholesterol'>Total cholesterol (mg/dL)</label>
						<Input type='number' id='totalCholesterol' name='totalCholesterol'
							   className={invalidity.totalCholesterol ? 'invalid' : ''}
							   value={biriData?.totalCholesterol ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBIRIChange} min={0} max={500}
							   placeholder='00 mg/dL'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='pdw'>PDW (%)</label>
						<Input type='number' id='pdw' name='pdw'
							   className={invalidity.pdw ? 'invalid' : ''}
							   value={biriData?.pdw ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBIRIChange} min={0} max={100}
							   placeholder='00 %'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='phosphorus'>Phosphorus (mg/dL)</label>
						<Input type='number' id='phosphorus' name='phosphorus'
							   className={invalidity.phosphorus ? 'invalid' : ''}
							   value={biriData?.phosphorus ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBIRIChange} min={0} max={20}
							   placeholder='00 mg/dL'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='ast'>AST (U/L)</label>
						<Input type='number' id='ast' name='ast'
							   className={invalidity.ast ? 'invalid' : ''}
							   value={biriData?.ast ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBIRIChange} min={0} max={2000}
							   placeholder='00 U/L'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='appt'>aPTT (sec)</label>
						<Input type='number' id='appt' name='appt'
							   className={invalidity.appt ? 'invalid' : ''}
							   value={biriData?.appt ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBIRIChange} min={0} max={200}
							   placeholder='00 sec'
						/>
					</div>
					<div className='grid-item'>
						<label htmlFor='hdlCholesterol'>HDL-C (mg/dL)</label>
						<Input type='number' id='hdlCholesterol' name='hdlCholesterol'
							   className={invalidity.hdlCholesterol ? 'invalid' : ''}
							   value={biriData?.hdlCholesterol ?? ''}
							   onKeyDown={handleKeyDown}
							   onChange={handleBIRIChange} min={0} max={500}
							   placeholder='00 mg/dL'
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default TestResultForm;
import React from 'react';
import dataMatrix from '@styles/images/data-matrix-bpai.png';

const Bpai = () => {
	return (
		<>
			<ul>
				<li>
					<strong>품목명</strong>
					<span>혈압 분석 소프트웨어</span>
				</li>
				<li>
					<strong>모델명</strong>
					<span>BPP-AI-01</span>
				</li>
				<li>
					<strong>제품명</strong>
					<span>canofy MD BPAI</span>
				</li>
				<li>
					<strong>허가번호</strong>
					<span>제허24-534호</span>
				</li>
				<li>
					<strong>제조자</strong>
					<span>㈜라이프시맨틱스<br/>서울특별시 강남구 삼성로95길 27, 3층</span>
				</li>
				<li>
					<strong>제조연월</strong>
					<span>2024-08-08</span>
				</li>
				<li>
					<strong>포장단위</strong>
					<span>1개</span>
				</li>
				<li>
					<strong>소프트웨어 버전</strong>
					<span>1.1.1</span>
				</li>
			</ul>
			<p>본태성 고혈압 환자의 과거 8주간 가정에서 측정한 수축기 혈압, 이완기 혈압 및 맥박 데이터를 분석하여서 향후 4주간의 평균 가정 혈압 수치를 의료인에게 제공하는 인공지능 기반 소프트웨어</p>
			<h3>본 제품은 의료기기입니다.</h3>
			<div className='data-matrix-code'>
				<img src={dataMatrix} alt='Data Matrix' />
				<ul>
					<li>(01)8809528440143</li>
					<li>(11)240808</li>
				</ul>
			</div>
		</>
	);
};

export default Bpai;
import React from 'react';
import { useTranslation } from "react-i18next";
import { IsBitProps } from "@models/layoutTypes";
import { PredictedValueTable } from "@molecules/PredictReport";

const Prediction: React.FC<IsBitProps> = (props) => {
	const { t } = useTranslation();
	return (
		<div>
			<h2>{t('patient.bpp')}</h2>
			<PredictedValueTable {...props} />
		</div>
	);
};

export default Prediction;
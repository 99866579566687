import React from 'react';
import { BIRI, ColGroupProps } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";
import { BiriTbody } from "@molecules/HcaiTbody";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const BloodInspectResultInfo: React.FC<BIRI> = (props) => {
	return (
		<table>
			<ColGroup {...widths} />
			<BiriTbody {...props} />
		</table>
	);
};

export default BloodInspectResultInfo;
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { findPatientList } from "@apis/patientList";
import { INITIAL_PAGINATION } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { HeaderProps, MiddleBoxProps, PatientSearchProps, SearchInputProps } from '@models/layoutTypes';
import {
	aiModelState,
	dialogIDRecoilState,
	dialogRecoilState,
	doctorNameKeywordState,
	isSubmitButtonDisabledState,
	pageNumberState,
	patientSearchKeywordState,
	patientSearchState,
	totalElementsState
} from "@models/recoilStates";
import { Dialog } from "@molecules/Dialog";
import { Footer } from "@molecules/Footer";
import { DialogButtons } from "@molecules/ModifyPassword";
import { PatientForm } from "@molecules/PatientForm";
import { TrTh } from "@molecules/TableRow";
import { WhiteBottomBox } from "@organisms/BottomBox";
import { WhiteMiddleBox } from "@organisms/MiddleBox";
import { BPAIPatientListBody, HCAIPatientListBody } from "@organisms/PatientList";
import { WhiteTopBox } from "@organisms/TopBox";
import { ContentBoxWhite } from "@templates/ContentBox";
import { DefaultLayout } from "@templates/DefaultLayout";

const List: React.FC<HeaderProps> = (headerProps) => {
	const { openDialog } = useDialog();
	const { t } = useTranslation();

	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [patientList, setPatientList] = useState<any[]>([]);

	const aiModel = useRecoilValue(aiModelState);
	const dialogState = useRecoilValue(dialogRecoilState);
	const pageNumber = useRecoilValue(pageNumberState);
	const patientSearchObj: PatientSearchProps = useRecoilValue(patientSearchState);
	const totalE = useRecoilValue(totalElementsState);

	const prevPN = useRef<number>(0);
	const prevTotalElementsRef = useRef<number>(0);

	const searchboxes: SearchInputProps[] = useMemo(() => {
		switch (aiModel) {
			case "BPAI":
				return [{
					keywordState: patientSearchKeywordState,
					placeholder: t('patient.sfpnpn')
				}, {
					keywordState: doctorNameKeywordState,
					placeholder: t('patient.sfap')
				}];
			case "HCAI":
				return [{
					keywordState: patientSearchKeywordState,
					placeholder: t('patient.sfpnpn')
				}];
			default:
				return []
		}
	}, [aiModel, t]);

	const colAndHead: any = useMemo(() => {
		switch (aiModel) {
			case "BPAI":
				return {
					cols: <>
						<col width='7%'/>
						<col width='12%'/>
						<col width='11%'/>
						<col width='12%'/>
						<col width='12%'/>
						<col width='10%'/>
						<col width='12%'/>
						<col width='12%'/>
						<col width='12%'/>
					</>,
					listHead: <TrTh thArr={['No.', t('patient.pid'), t('patient.name'), t('patient.dob'), t('patient.contact'), t('dashboard.gender'), t('patient.rd'), t('patient.ap'), t('hospital.status')]} />,
					listBody: <BPAIPatientListBody content={patientList} total={totalE} page={pageNumber} />
				}
			case "HCAI":
				return {
					cols: <>
						<col width='8%'/>
						<col width='13%'/>
						<col width='13%'/>
						<col width='13%'/>
						<col width='13%'/>
						<col width='13%'/>
						<col width='15%'/>
						<col width='12%'/>
					</>,
					listHead: <TrTh thArr={['No.', t('patient.name'), t('patient.dob'), t('patient.contact'), t('dashboard.gender'), t('patient.ap'), t('patient.rd'), t('hospital.status')]} />,
					listBody: <HCAIPatientListBody content={patientList} total={totalE} page={pageNumber} />
				}
		}
	}, [aiModel, patientList, t]);

	const listContents: MiddleBoxProps = useMemo(() => ({
		...colAndHead,
		type: 'patient'
	}), [colAndHead]);

	const openAddPatientDialog = useRecoilCallback(({ set }) => () => {
		const id: string = 'register-patient-information-dialog';
		set(dialogIDRecoilState, id);
		set(isSubmitButtonDisabledState, true);

		openDialog({
			dialogTitle: t('patient.rpi'),
			dialogContents: <PatientForm type='registration' />,
			dialogButtons: <DialogButtons dialogId={id} submitButtonText={t('hospital.dhksfy')} isDupCheckActive />
		});
	}, [openDialog, t]);

	const fetchPatientList = useRecoilCallback(({ set }) => (pageNum: number) => {
		findPatientList(patientSearchObj, pageNum, aiModel.toLowerCase(), (res) => {
			const { content, first, last, totalElements, totalPages } = res.data;
			setPatientList(content);
			setPagination({ first, last, number: pageNum, totalPages });

			set(pageNumberState, pageNum);
			set(totalElementsState, totalElements);

			prevTotalElementsRef.current = totalElements;
			prevPN.current = pageNum;
		}, () => {
			setPatientList([]);
			setPagination(INITIAL_PAGINATION);

			set(totalElementsState, 0);

			prevTotalElementsRef.current = 0;
			prevPN.current = 0;
		});
	}, [aiModel, patientSearchObj]);

	// 필터, 키워드 변경 시 동작
	useEffectAfterMount(() => {
		if (patientSearchObj.hospitalSeq) {
			fetchPatientList(0);
		}
	}, [patientSearchObj]);

	// 페이지 이동 시 동작
	useEffectAfterMount(() => {
		if (patientSearchObj.hospitalSeq && pageNumber !== prevPN.current) {
			fetchPatientList(pageNumber);
		}
	}, [pageNumber]);

	// 전체 환자 수 변동 시 동작
	useEffectAfterMount(() => {
		if (patientSearchObj.hospitalSeq && prevTotalElementsRef.current < totalE) {
			fetchPatientList(pageNumber);
		}
	}, [totalE]);

	return (
		<DefaultLayout {...headerProps}>
			<ContentBoxWhite>
				<WhiteTopBox addBtnText={t('patient.rp')} openAddDialog={openAddPatientDialog} searchboxes={searchboxes} />
				<WhiteMiddleBox {...listContents} />
				<WhiteBottomBox {...pagination} />
			</ContentBoxWhite>
			<Footer/>
			<Dialog {...dialogState} />
		</DefaultLayout>
	);
};

export default List;
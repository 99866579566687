import React from 'react';
import { useTranslation } from "react-i18next";

const RiskAssessmentDate: React.FC<{ date: string }> = ({ date }) => {
	const { t } = useTranslation();

	return (
		<div className='hc-result-col'>
			<h3>{t('patient.rad')}</h3>
			<table className='hc-result-table'>
				<colgroup>
					<col width='16.66%' />
					<col width='*' />
				</colgroup>
				<tbody>
					<tr>
						<th>{t('patient.date')}</th>
						<td>{date.split('T')[0]}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default RiskAssessmentDate;
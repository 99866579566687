import React, { CSSProperties } from 'react';
import { useTranslation } from "react-i18next";

interface Props {
	containerStyle?: CSSProperties;
	wrapperStyle?: CSSProperties;
}

const AboutAscvd: React.FC<Props> = ({ containerStyle = {}, wrapperStyle = {} }) => {
	const { t } = useTranslation();

	return (
		<div style={wrapperStyle}>
			<div className='about-ascvd' style={containerStyle}>
				<p>{t('patient.acd')}</p>
				<p>{t('patient.idm')}</p>
			</div>
		</div>
	);
};

export default AboutAscvd;
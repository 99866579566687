import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { findHospitalUsers } from "@apis/hospitalDetail";
import { INITIAL_PATIENT_FORM_DATA } from "@constants/common";
import { formatPhoneNumber } from "@logics/common";
import {
	ColGroupProps,
	PatientFormDataProps,
	PatientFormProps,
	PatientFormPropsExt,
	UserProps
} from "@models/layoutTypes";
import { SelectBoxOptionProps } from "@models/optionProps";
import { aiModelState, doctorOptionsState, loggedInUserState } from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { BPAIForm, HCAIForm } from "@molecules/PatientForm";

const widths: ColGroupProps = { widths: new Array(4).fill('25%') };

const PatientForm: React.FC<PatientFormProps> = ({ patientData, type }) => {
	const { t } = useTranslation();

	const aiModel = useRecoilValue(aiModelState);
	const loggedInUser = useRecoilValue(loggedInUserState);

	const [formData, setFormData] = useState<PatientFormDataProps>(patientData ?? INITIAL_PATIENT_FORM_DATA);

	const setDoctorOptions = useSetRecoilState(doctorOptionsState);

	const handleFormDataChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		const { name, type, value } = e.target;
		let val: string | boolean;
		switch (type) {
			case 'checkbox':
				val = (e.target as HTMLInputElement).checked;
				break;
			case 'number':
				val = Number(value).toString();
				break;
			default:
				val = value;
				break;
		}

		if (name === 'contact') {
			setFormData(prev => ({ ...prev, contact: formatPhoneNumber(value) }));
		} else {
			setFormData(prev => ({ ...prev, [name]: val }));
		}
	}, []);

	const handleSelectChange = useCallback((name: string) => (value: string) => {
		setFormData(prev => ({ ...prev, [name]: value }));
	}, []);

	const formProps: PatientFormPropsExt = useMemo(() => ({
			genderOptions: [{
				label: t('patient.male'),
				value: 'MALE'
			},
			{
				label: t('patient.female'),
				value: 'FEMALE'
			}],
			formData,
			setFormData,
			handleFormDataChange,
			handleSelectChange,
			type
		}), [formData, handleFormDataChange, handleSelectChange, type, t]);

	useEffect(() => {
		const { category, hospitalSeq, name, seq, userType } = loggedInUser;
		if (userType === 'DOCTOR') {
			if (type === 'registration') {
				setFormData(prev => ({ ...prev, staffName: name, staffSeq: seq }));
			}
		} else if (userType === 'COORDINATOR') {
			findHospitalUsers(hospitalSeq?.toString(), (res) => {
				const responseData: UserProps[] = res.data.content;
				const options: SelectBoxOptionProps[] = responseData.filter(data =>
																			data.active &&
																			data.categories[0] === category.toLowerCase() &&
																			data.userType === 'DOCTOR')
																	.map(d => ({ label: d.name, value: d.seq.toString() }));
				setDoctorOptions(options);
			}, () => {});
		}
	}, [loggedInUser, setDoctorOptions, type]);

	return (
		<form className='patient-form'>
			<table>
				<ColGroup {...widths} />
				{aiModel === 'BPAI' && <BPAIForm {...formProps} />}
				{aiModel === 'HCAI' && <HCAIForm {...formProps} />}
			</table>
		</form>
	);
};

export default PatientForm;
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { findHospitalUsers } from "@apis/hospitalDetail";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { UserProps } from "@models/layoutTypes";
import {
	dialogIDRecoilState, isSubmitButtonDisabledState, modifyPasswordSucceedState,
	usedCategoriesState, userListRefreshState
} from "@models/recoilStates";
import { DialogButtons } from "@molecules/ModifyPassword";
import { CheckBoxOption } from "@molecules/ToggleInputs";
import { UserDetailDialogButtons, UserDetailDialogContents } from "@molecules/UserDetailDialog";
import { UserForm } from "@molecules/UserForm";
import add from '@styles/images/add.png';

const UserList = () => {
	const { closeDialog, openDialog } = useDialog();
	const { hospitalSeq } = useParams();
	const { t } = useTranslation();

	const [checkedUserCnt, setCheckedUserCnt] = useState<number>(0);
	const [selectedUser, setSelectedUser] = useState<UserProps>();
	const [useListHeight, setUseListHeight] = useState<number>();
	const [users, setUsers] = useState<UserProps[]>([]);

	const modifyPasswordSucceed = useRecoilValue(modifyPasswordSucceedState);
	const refreshUserList: boolean = useRecoilValue(userListRefreshState);

	const setDialogID = useSetRecoilState(dialogIDRecoilState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);
	const setUsedCategories = useSetRecoilState(usedCategoriesState);

	const userListRef = useRef<HTMLDivElement>(null);

	const active: string = useMemo(() => t('hospital.active'), [t]);
	const coordinator: string = useMemo(() => t('hospital.coordinator'), [t]);
	const doctor: string = useMemo(() => t('hospital.doctor'), [t]);
	const inactive: string = useMemo(() => t('hospital.inactive'), [t]);

	const closePwdModifyResultDialog = useRecoilCallback(({ set }) => (id: string) => {
		closeDialog(id);
		set(modifyPasswordSucceedState, undefined);
	}, [closeDialog]);

	const openUserDetailDialog = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>, seq: number) => {
		if (!((e.target as HTMLSpanElement).classList.contains('checkmark') ||
			(e.target as HTMLLabelElement).classList.contains('check-option') ||
			(e.target as HTMLInputElement).type === 'checkbox')) {

			setDialogID('user-detail-dialog');
			openDialog({
				dialogTitle: t('hospital.ud'),
				dialogContents: <UserDetailDialogContents userSeq={seq} />,
				dialogButtons: <UserDetailDialogButtons userSeq={seq} />
			});
		}
	}, [openDialog, setDialogID, t]);

	const openPwdModResult = useRecoilCallback(({ set }) => () => {
		if (modifyPasswordSucceed !== undefined) {
			const id: string = modifyPasswordSucceed ? 'password-modified-dialog' : 'password-modify-failed-dialog';
			set(dialogIDRecoilState, id);
			openDialog({
				dialogClass: 'untitled',
				dialogContents: modifyPasswordSucceed ? t('profile.pcs') : t('profile.pcf'),
				dialogButtons: <Button className='blue' onClick={() => closePwdModifyResultDialog(id)}>{t('dashboard.ok')}</Button>
			});
		}
	}, [closePwdModifyResultDialog, modifyPasswordSucceed, openDialog, t]);

	useEffect(() => {
		openPwdModResult();
	}, [openPwdModResult]);

	useEffect(() => {
		if (userListRef.current) {
			setUseListHeight(userListRef.current.offsetHeight);
		}

		findHospitalUsers(hospitalSeq, (res) => {
			const categorySet = new Set<string>();
			const content = res.data.content;
			content.forEach((item: UserProps) => {
				item.categories.forEach((category: string) => {
					categorySet.add(category.toUpperCase());
				});
			});

			setUsers(content);
			setUsedCategories(Array.from(categorySet));
		}, () => {
			setUsers([]);
			setUsedCategories([]);
		});

		setCheckedUserCnt(0);
		const checkAll = document.getElementById('check-all') as HTMLInputElement;
		if (checkAll) checkAll.checked = false;

		document.getElementsByName('check-user').forEach(e => {
			const input: HTMLInputElement = e as HTMLInputElement;
			input.checked = false;
		});
	}, [hospitalSeq, refreshUserList, setUsedCategories]);

	useEffectAfterMount(() => {
		const checkAll = document.getElementById('check-all') as HTMLInputElement;
		checkAll.checked = checkedUserCnt === users.length;

		setSelectedUser(users.find((_, index) => {
			if (checkedUserCnt === 1) {
				const checkBox = document.getElementsByName('check-user')[index] as HTMLInputElement;
				return checkBox.checked;
			} else {
				return undefined;
			}
		}));
	}, [checkedUserCnt, users.length]); // `checkedUserCnt`와 `users.length`가 변경될 때마다 실행

	const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
		const checked: boolean = e.target.checked;
		setCheckedUserCnt(checked ? users.length : 0); // 전체 사용자 체크 상태 업데이트
		const usersCheckbox = document.getElementsByName('check-user');
		usersCheckbox.forEach(checkBox => {
			(checkBox as HTMLInputElement).checked = checked; // 각 사용자 체크박스 상태 업데이트
		});
	}

	const openAddUserDialog = () => {
		const dialogID: string = 'register-user-dialog';
		setDialogID(dialogID);
		openDialog({
			dialogTitle: t('hospital.ru'),
			dialogContents: <UserForm type='registration'
								userData={{
									categories: [''],
									hospitalSeq: Number(hospitalSeq || 0)
								}} />,
			dialogButtons: <DialogButtons dialogId={dialogID} submitButtonText={t('hospital.dhksfy')} />
		});
	}

	const openModifyUserDialog = () => {
		let id: string = '';
		let dialogClass: string | undefined;
		let dialogTitle: string | undefined;
		let dialogContents: JSX.Element | string = '';
		let dialogButtons: JSX.Element = <></>;

		switch (checkedUserCnt) {
			case 0:
				id = 'select-user-first-dialog';
				dialogClass = 'untitled';
				dialogTitle = undefined;
				dialogContents = t('hospital.psu');
				dialogButtons = <Button className='blue' onClick={() => closeDialog(id)}>{t('hospital.ghkrdls')}</Button>;
				break;
			case 1:
				if (selectedUser) {
					id = 'modify-user-dialog';
					dialogClass = undefined;
					dialogTitle = t('hospital.eui');
					dialogContents = <UserForm type='modify'
										userData={{
											categories: selectedUser.categories,
											contact: selectedUser.contact,
											hospitalSeq: Number(hospitalSeq || 0),
											id: selectedUser.id,
											isActive: selectedUser.active,
											name: selectedUser.name,
											seq: selectedUser.seq,
											userType: selectedUser.userType
										}} />;
					dialogButtons = <DialogButtons dialogId={id} submitButtonText={t('hospital.dhksfy')} />;
					setIsSubmitButtonDisabled(false);
				}
				break;
			default:
				id = 'select-one-user-dialog';
				dialogClass = 'untitled';
				dialogTitle = undefined;
				dialogContents = t('hospital.psou');
				dialogButtons = <Button className='blue' onClick={() => closeDialog(id)}>{t('hospital.ghkrdls')}</Button>;
				break;
		}

		setDialogID(id);
		openDialog({
			dialogClass,
			dialogTitle,
			dialogContents,
			dialogButtons
		});
	}

	return (
		<div className='hospital-user-list h100'>
			<div className="hospital-user-list-head">
				<h2>{t('hospital.ul')}</h2>
				<div className='buttons'>
					<Button className='white' onClick={openModifyUserDialog}>{t('hospital.edit')}</Button>
					<Button className='blue add-button' onClick={openAddUserDialog}>
						<img src={add} alt='add' />
						<span>{t('hospital.ru')}</span>
					</Button>
				</div>
			</div>
			<div className="hospital-user-list-body">
				<div className='hospital-user-list-table h100'>
					<div className='hospital-user-list-table-head'>
						<span><CheckBoxOption id='check-all' onChange={handleCheckAll} name='check-all' text='' /></span>
						<span>No.</span>
						<span>{t('login.id')}</span>
						<span>{t('hospital.uc')}</span>
						<span>{t('hospital.name')}</span>
						<span>{t('hospital.phone')}</span>
						<span>{t('hospital.category')}</span>
						<span>{t('hospital.rd')}</span>
						<span>{t('hospital.status')}</span>
					</div>
					<div className='hospital-user-list-table-body' ref={userListRef} style={{ maxHeight: useListHeight }}>
						{users.map((user, i) => (
							<div className='row' key={user.id} onClick={(e) => openUserDetailDialog(e, user.seq)}>
								<span>
									<CheckBoxOption onChange={e => setCheckedUserCnt(prev => e.target.checked ? prev + 1 : prev - 1)}
													name='check-user' value={user.seq.toString()} />
								</span>
								<span>{i + 1}</span>
								<span>{user.id}</span>
								<span>{user.userType === 'DOCTOR' ? doctor : coordinator}</span>
								<span>{user.name}</span>
								<span>{user.contact}</span>
								<span>{user.categories.sort().join(', ').toUpperCase()}</span>
								<span>{user.createdAt.split('T')[0]}</span>
								<span className={user.active ? 'activated' : 'deactivated'}>{user.active ? active : inactive}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserList;
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { findStaffDetailProfile } from "@apis/profile";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { StaffProfileProps } from "@models/layoutTypes";
import {
	dialogIDRecoilState,
	isSubmitButtonDisabledState,
	loggedInUserState,
	modifyPasswordSucceedState
} from "@models/recoilStates";
import { DialogButtons, ModifyPasswordForm } from "@molecules/ModifyPassword";

const Detail = () => {
	const { closePwdModifyResultDialog, openDialog } = useDialog();
	const { t } = useTranslation();
	const loggedInUser = useRecoilValue(loggedInUserState);
	const { id, name, seq, userType } = loggedInUser;

	const initialStaffProfile: StaffProfileProps = useMemo(() => ({
		contact: '',
		hospitalName: '',
		id,
		isActive: false,
		name,
		seq,
		userType
	}), [id, name, seq, userType]);

	const [profile, setProfile] = useState<StaffProfileProps>(initialStaffProfile);

	const setDialogID = useSetRecoilState(dialogIDRecoilState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	const modifyPasswordSucceed = useRecoilValue(modifyPasswordSucceedState);

	const userTypeText: string = useMemo(() => {
		switch (userType) {
			case 'COORDINATOR':
				return t('hospital.coordinator');
			case 'DOCTOR':
				return t('hospital.doctor');
			default:
				return '';
		}
	}, [t, userType]);

	useEffect(() => {
		if (modifyPasswordSucceed !== undefined) {
			setDialogID(modifyPasswordSucceed ? 'password-modified-dialog' : 'password-modify-failed-dialog');
			openDialog({
				dialogClass: 'untitled',
				dialogContents: modifyPasswordSucceed ? t('profile.pcs') : t('profile.pcf'),
				dialogButtons: <Button className='blue' onClick={closePwdModifyResultDialog}>{t('dashboard.ok')}</Button>
			});
		}
	}, [closePwdModifyResultDialog, modifyPasswordSucceed, openDialog, setDialogID, t]);

	useEffectAfterMount(() => {
		if (userType === 'COORDINATOR' || userType === 'DOCTOR') {
			findStaffDetailProfile(loggedInUser.seq, (res) => {
				setProfile(res.data);
			}, () => {
				setProfile(initialStaffProfile);
			});
		}
	}, [loggedInUser]);

	const openModifyPasswordDialog = () => {
		setIsSubmitButtonDisabled(true);
		setDialogID('modify-password-dialog');
		openDialog({
			dialogTitle: t('profile.cp'),
			dialogContents: <ModifyPasswordForm passwordOwner='staff' />,
			dialogButtons: <DialogButtons dialogId='modify-password-dialog' submitButtonText={t('hospital.dhksfy')} />
		});
	}

	return (
		<>
			<div className='profile-header'>
				<h2>{userType === 'ADMIN' ? name : profile.hospitalName}</h2>
				<Button className='white' onClick={openModifyPasswordDialog}>{t('profile.cp')}</Button>
			</div>
			<div className='profile-body'>
				<div className='profile-row'>
					<div>
						<span className='gray'>{t('login.id')}</span>
						<span>{id}</span>
					</div>
					{(userType === 'COORDINATOR' || userType === 'DOCTOR') && (
						<div>
							<span className='gray'>{t('hospital.uc')}</span>
							<span>{userTypeText}</span>
						</div>
					)}
					<div>
						<span className='gray'>{t('hospital.name')}</span>
						<span>{profile.name}</span>
					</div>
				</div>
				{(userType === 'COORDINATOR' || userType === 'DOCTOR') && (
					<div className='profile-row'>
						<div>
							<span className='gray'>{t('hospital.phone')}</span>
							<span>{profile.contact}</span>
						</div>
						<div>
							<span className='gray'>{t('hospital.status')}</span>
							<span>{profile.isActive ? t('hospital.active') : t('hospital.inactive')}</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Detail;
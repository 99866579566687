import React from 'react';
import { BloodPressurePrediction, BloodPressureSummary } from "@molecules/BloodPressureAnalysis";

const Analysis = () => {
	return (
		<div className='multifaceted-analysis'>
			<BloodPressurePrediction />
			{/* TODO 삭제하지 말고 일단 주석 */}
			{/*<BloodPressureSummary />*/}
		</div>
	);
};

export default Analysis;
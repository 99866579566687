import React from "react";
import { AxiosResponse } from "axios";
import i18next from "i18next";
import Highcharts, { PointerEventObject, XAxisOptions, YAxisOptions } from "highcharts";
import { getDatesDuringPeriod, getYMDsDuringPeriod } from "@logics/common";
import { BPGraphDataProps, BPGraphProps, BPProps } from "@models/layoutTypes";

// 화살표 함수로 바꾸지 말 것!
export function onlyOneNonNull(arr: (number | null)[]): boolean {
	let nonNullCount = 0;

	for (const value of arr) {
		if (value !== null) {
			nonNullCount++;
			if (nonNullCount > 1) {
				return false;
			}
		}
	}

	return nonNullCount === 1;
}

// API로 받아온 response로 setGraphData
export const setGraphDataByRes = (from: string, to: string, res: AxiosResponse<any, any>,
								  setGraphData: (value: React.SetStateAction<BPGraphProps>) => void) => {

	const xCategories: string[] = getDatesDuringPeriod(from, to);
	const ymdArr: string[] = getYMDsDuringPeriod(from, to);

	const graphBpList: BPGraphDataProps[] = res.data.graphBpList;
	const graphBPObj = graphBpList.reduce((acc, cur) => {
		acc[cur.date] = cur.bp;
		return acc;
	}, {} as { [key: string]: BPProps });

	setGraphData({
		bpArr: ymdArr.map((d) => [graphBPObj[d]?.diastolic ?? null, graphBPObj[d]?.systolic ?? null]),
		pulseArr: ymdArr.map((d) => graphBPObj[d]?.pulse ?? null),
		xCategories
	});
}

// 혈압 그래프 툴팁 옵션
export const bpTooltipOptions: Highcharts.TooltipOptions = {
	backgroundColor: 'rgba(0, 0, 0, 0.6)',
	formatter: function () {
		return this.points?.reduce(function (s: string, point: Highcharts.TooltipFormatterContextObject): string {
			const { high, low, y } = point.point;
			const text = `${s}<br/>${point.series.name} `;
			return high && low ? `${text}<b>${high}/${low}</b>` : `${text}<b>${y}</b>`;
		}, `<b>${this.x}</b>`);
	},
	shared: true,
	style: {
		color: '#fff',
		fontSize: '14px'
	}
}

// 혈압 그래프 mouseOver 이벤트
export function handleMouseOver(this: Highcharts.Point, e: Event) {
	const { x } = e.target! as unknown as PointerEventObject;
	const from: number = x - 0.25;
	const to: number = x + 0.25;

	this.series.chart.xAxis[0].addPlotBand({
		id: 'plot-band-1',
		from,
		to,
		zIndex: 1,
		color: {
			linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
			stops: [
				[0, 'rgba(255, 255, 255, 0)'], // 시작 색상
				[1, 'rgba(27, 89, 248, 0.2)']  // 끝 색상
			]
		}
	});
}

// 혈압 그래프 mouseOut 이벤트
export function handleMouseOut(this: Highcharts.Point) {
	this.series.chart.xAxis[0].removePlotBand('plot-band-1');
}

// x축, y축 텍스트 스타일
export const axisStyle: Highcharts.CSSObject = {
	color: '#6f6f6f',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '20px'
};

// 혈압 그래프 x축
export const bpXAxis = (categories: string[]): XAxisOptions => {
	return {
		categories,
		labels: {
			style: axisStyle
		},
		lineColor: '#f1f1f1'
	}
};

// 혈압 그래프 y축
export const bpYAxis = (tickAmount?: number): YAxisOptions => {
	return {
		labels: {
			style: axisStyle
		},
		tickAmount,
		title: {
			align: 'low',
			style: {
				color: '#6f6f6f',
				fontSize: '12px',
				fontWeight: '400',
				textAlign: 'right'
			},
			text: `${i18next.t('patient.bp')} (mmHg)`
		}
	}
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { getCookie } from "typescript-cookie";
import { findHospitalDetail } from "@apis/hospitalDetail";
import { Button } from "@atoms/Button";
import { INITIAL_HOSPITAL_INFO } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { HospitalFormDataProps } from "@models/layoutTypes";
import {
	dialogIDRecoilState,
	hospitalCategoriesState,
	hospitalModifyCountState,
	hospitalT3QkeyState,
	isSubmitButtonDisabledState
} from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { HospitalForm, T3QHospitalForm } from "@molecules/HospitalForm";
import { DialogButtons } from "@molecules/ModifyPassword";

const Basic = () => {
	const isT3Q: boolean = !!getCookie('userUuid');

	const { openDialog } = useDialog();
	const { hospitalSeq } = useParams();
	const { t } = useTranslation();

	const [hospitalInfo, setHospitalInfo] = useState<HospitalFormDataProps>({
		...INITIAL_HOSPITAL_INFO,
		hospitalSeq: Number(hospitalSeq || 0)
	});

	const hospitalCategories = useRecoilValue(hospitalCategoriesState);
	const modifyCount: number = useRecoilValue(hospitalModifyCountState);

	const openModifyHospitalDialog = useRecoilCallback(({ set }) => () => {
		const id: string = 'modify-hospital-dialog';
		set(dialogIDRecoilState, id);
		set(isSubmitButtonDisabledState, false);
		openDialog({
			dialogTitle: t('hospital.ehd'),
			dialogContents: isT3Q ? <T3QHospitalForm type='modify' /> : <HospitalForm type='modify' />,
			dialogButtons: <DialogButtons dialogId={id} submitButtonText={t('hospital.dhksfy')} />
		});
	}, [isT3Q, openDialog, t]);

	const getHospitalDetailInfo = useRecoilCallback(({ reset, set }) => () => {
		if (hospitalSeq) {
			findHospitalDetail(hospitalSeq, res => {
				const data = res.data;
				setHospitalInfo(data);
				set(hospitalCategoriesState, data.categories.map((c: string) => c.toUpperCase()).sort());
				set(hospitalT3QkeyState, data.t3qkey);
			}, () => {
				setHospitalInfo({ ...INITIAL_HOSPITAL_INFO, hospitalSeq: Number(hospitalSeq) });
				reset(hospitalCategoriesState);
				reset(hospitalT3QkeyState);
			});
		}
	}, [hospitalSeq]);

	useEffect(() => {
		getHospitalDetailInfo();
	}, [getHospitalDetailInfo, modifyCount]);

	return (
		<table className='basic-info'>
			<ColGroup widths={['10%', '20%', '10%', '20%', '10%', '30%']} />
			<tbody>
				<tr>
					<td colSpan={5}>
						<h2>{hospitalInfo.name}</h2>
					</td>
					<td>
						<div style={{ display: 'flex', gap: 10, justifyContent: 'right' }}>
							<Button className='white' onClick={openModifyHospitalDialog}>{t('hospital.edit')}</Button>
						</div>
					</td>
				</tr>
				<tr>
					<th>{t('hospital.category')}</th>
					<td>{hospitalCategories.join(', ')}</td>
					<th>{t('hospital.addr')}</th>
					<td>{hospitalInfo.address}</td>
					<th>{t('hospital.rd')}</th>
					<td>{hospitalInfo.registerDate}</td>
				</tr>
				<tr>
					<th>{t('hospital.adminn')}</th>
					<td>{hospitalInfo.managerName || '-'}</td>
					<th>{t('hospital.admphone')}</th>
					<td colSpan={3}>{hospitalInfo.managerContact || '-'}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default Basic;
import React, { ChangeEvent, useEffect } from 'react';
import { useRecoilState } from "recoil";
import { Input } from "@atoms/Input";
import { SearchInputProps } from "@models/layoutTypes";
import icSearch from "@styles/images/ic-search.png";

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, keywordState }) => {
	const [keyword, setKeyword] = useRecoilState(keywordState);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value);
	}

	useEffect(() => {
		setKeyword('');
	}, [setKeyword]);

	return (
		<div className='search-input'>
			<img src={icSearch} alt='search icon' />
			<Input type='text' placeholder={placeholder} value={keyword} onChange={handleInputChange} />
		</div>
	);
};

export default SearchInput;
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance, t3qInstance } from '@/axiosInstance';
import { setAxiosRequestConfig } from "@logics/common";

const client_id: string = window._env_.REACT_APP_T3Q_PLATFORM_CLIENT_ID || process.env.REACT_APP_T3Q_PLATFORM_CLIENT_ID!;
const client_secret: string = window._env_.REACT_APP_T3Q_PLATFORM_CLIENT_SECRET || process.env.REACT_APP_T3Q_PLATFORM_CLIENT_SECRET!;

const config: AxiosRequestConfig<any> = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
}

// 리다이렉션
export const redirection = () => {
	const t3q_url: string = window._env_.REACT_APP_T3Q_PLATFORM_URL || process.env.REACT_APP_T3Q_PLATFORM_URL!;
	const redirectUri: string = `${window.location.origin}/auth/signin`;
	window.location.href = `${t3q_url}/realms/service-user-dev/protocol/openid-connect/auth?client_id=${client_id}&redirect_uri=${redirectUri}&response_type=code&scope=openid`;
}

// 토큰 발급
export const tokenGeneration = (code: string, origin: string,
								callbackSuccess: (res: AxiosResponse<any>) => void,
								callbackFail: () => void) => {

	const url: string = '/realms/service-user-dev/protocol/openid-connect/token';
	const body = {
		client_id,
		client_secret,
		code,
		grant_type: 'authorization_code',
		redirect_uri: `${origin}/auth/signin`
	};

	t3qInstance
		.post(url, body, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 토큰 검증
export const tokenIntrospect = (accessToken: string,
								callbackSuccess: (res: AxiosResponse<any>) => void,
								callbackFail: (error: any) => void) => {

	axiosInstance
		.post('/t3q/login', { accessToken })
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 클라이언트 토큰 발급
export const clientTokenGeneration = (callbackSuccess: (res: AxiosResponse<any>) => void) => {
	const url: string = '/realms/service-user-dev/protocol/openid-connect/token';
	const body = {
		client_id,
		client_secret,
		grant_type: 'client_credentials'
	}

	t3qInstance
		.post(url, body, config)
		.then(callbackSuccess);
}

// T3Q 로그아웃
export const t3qLogout = (accessToken: string, uuid: string, callbackSuccess?: () => void) => {
	const url: string = `/admin/realms/service-user-dev/users/${uuid}/logout`;

	const body = {
		user: uuid,
		realm: 'service-user-dev'
	}

	const logoutConfig: AxiosRequestConfig<any> = {
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}

	t3qInstance
		.post(url, body, logoutConfig)
		.then(callbackSuccess);
}

// T3Q 병원 목록
export const findT3QHospitalList = (callbackSuccess: (res: AxiosResponse<any>) => void,
									callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get('/t3q/hospitals', config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// T3Q 사용자 목록
export const findT3QUserList = (t3qkey: string | undefined,
								callbackSuccess: (res: AxiosResponse<any>) => void,
								callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(`/t3q/staffs/${t3qkey}`, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}
import React from 'react';
import { BPIRI, ColGroupProps } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";
import { BpiriTbody } from "@molecules/HcaiTbody";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const BloodPressureInspectResultInfo: React.FC<BPIRI> = (props) => {

	return (
		<table>
			<ColGroup {...widths} />
			<BpiriTbody {...props} />
		</table>
	);
};

export default BloodPressureInspectResultInfo;
import React, { useMemo } from 'react';
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { Button } from "@atoms/Button";
import { SelectBoxOptionProps } from "@models/optionProps";
import { SelectBox } from "@molecules/SelectBox";
import arrowNextD from '@styles/images/pagination-next-disabled.png';
import arrowNextE from '@styles/images/pagination-next.svg';
import arrowPrevD from '@styles/images/pagination-previous-disabled.png';
import arrowPrevE from '@styles/images/pagination-previous.svg';

interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
	years: number[];
	months: string[];
}

const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
		date, changeYear, changeMonth, decreaseMonth, increaseMonth,
		prevMonthButtonDisabled, nextMonthButtonDisabled, years, months
	}) => {

	const yearOptions: SelectBoxOptionProps[] = useMemo(() => years.map(year => ({
		label: year.toString(),
		value: year.toString()
	})), [years]);

	const monthOptions: SelectBoxOptionProps[] = useMemo(() => months.map((month, index) => ({
		label: month,
		value: index.toString()
	})), [months]);

	return (
		<div className='datepicker-header'>
			<Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type='button'>
				<img src={prevMonthButtonDisabled ? arrowPrevD : arrowPrevE} alt="arrowPrev" />
			</Button>
			<SelectBox isFixed={false} name='year' options={yearOptions}
					   onChange={value => changeYear(Number(value))}
					   value={yearOptions.find(option => option.value === date.getFullYear().toString())}
			/>
			<SelectBox isFixed={false} name='month' options={monthOptions}
					   onChange={value => changeMonth(Number(value))}
					   value={monthOptions.find(option => option.value === date.getMonth().toString())}
			/>
			<Button onClick={increaseMonth} disabled={nextMonthButtonDisabled} type='button'>
				<img src={nextMonthButtonDisabled ? arrowNextD : arrowNextE} alt="arrowNext" />
			</Button>
		</div>
	);
};

export default DatePickerHeader;
import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { findBPChanges } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { WeekMonth } from "@models/layoutTypes";
import { OptionProps } from "@models/optionProps";
import { dialogIDRecoilState } from "@models/recoilStates";
import { DateUnitSelector } from "@molecules/DateUnitSelector";
import { BloodPressureDetail } from "@organisms/BloodPressure";

interface SystolicDiastolic {
	systolicSummary: number;
	diastolicSummary: number;
}

interface Statistic {
	alarmCount: number;
	average: SystolicDiastolic;
	measureCount: number;
	reachRatePerState: {
		dangerousRate: number;
		goalRate: SystolicDiastolic;
		lowRate: number;
		warningRate: number;
	};
	relativeStandardDeviation: SystolicDiastolic;
	standard: number;
	standardDeviation: SystolicDiastolic;
}

interface TrsProps {
	data: Statistic[];
	unit: WeekMonth;
}

interface ChangeProps {
	className?: string;
	isBit: boolean;
	isReport: boolean,
	moreBtn: boolean;
	reverse: boolean;
	rows: number;
	title: boolean;
	toggle: boolean;
	unit: WeekMonth;
}

const Trs: React.FC<TrsProps> = ({ data, unit }) => {
	const { t } = useTranslation();

	const unitText: string = useMemo(() => t(`patient.th${unit.toLowerCase()}`), [t, unit]);

	return (
		<>
			{data.map(({
						alarmCount,
						average,
						measureCount,
						reachRatePerState,
						relativeStandardDeviation,
						standard,
						standardDeviation}, i) => (
				<tr key={i}>
					<td>{standard}{unitText}</td>
					<td>{measureCount}</td>
					<td>{average.systolicSummary >= 0 ? Number(average.systolicSummary).toFixed(1) : '-'} / {average.diastolicSummary >= 0 ? Number(average.diastolicSummary).toFixed(1) : '-'}</td>
					<td>{standardDeviation.systolicSummary >= 0 ? Number(standardDeviation.systolicSummary).toFixed(1) : '-'} / {standardDeviation.diastolicSummary >= 0 ? Number(standardDeviation.diastolicSummary).toFixed(1) : '-'}</td>
					<td>{relativeStandardDeviation.systolicSummary >= 0 ? Number(relativeStandardDeviation.systolicSummary).toFixed(1) : '-'} / {relativeStandardDeviation.diastolicSummary >= 0 ? Number(relativeStandardDeviation.diastolicSummary).toFixed(1) : '-'}</td>
					{/* TODO 삭제하지 말고 일단 주석 */}
					{/*<td>{reachRatePerState.goalRate.systolicSummary >= 0 ? Number(reachRatePerState.goalRate.systolicSummary).toFixed(1) : '-'} / {reachRatePerState.goalRate.diastolicSummary >= 0 ? Number(reachRatePerState.goalRate.diastolicSummary).toFixed(1) : '-'}</td>
					<td>{reachRatePerState.warningRate >= 0 ? Number(reachRatePerState.warningRate).toFixed(1) : '-'}</td>
					<td>{reachRatePerState.dangerousRate >= 0 ? Number(reachRatePerState.dangerousRate).toFixed(1) : '-'}</td>
					<td>{reachRatePerState.lowRate >= 0 ? Number(reachRatePerState.lowRate).toFixed(1) : '-'}</td>
					<td>{alarmCount >= 0 ? alarmCount : 0}</td>*/}
				</tr>
			))}
		</>
	);
}

const Change: React.FC<ChangeProps> = ({ className,
										 isBit,
										 isReport,
										 moreBtn,
										 reverse,
										 rows,
										 title,
										 toggle,
										 unit }) => {

	const { closeDialog, openDialog } = useDialog();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [changeRow, setChangeRow] = useState<Statistic[]>([]);
	const [dateUnit, setDateUnit] = useState<WeekMonth>(unit);
	const [fullChangeList, setFullChangeList] = useState<Statistic[]>([]);
	const [limit, setLimit] = useState(5);
	const [moreCount, setMoreCount] = useState(0);

	const dateUnitOptions: OptionProps[] = useMemo(() => [
		{
			checked: dateUnit === 'WEEK',
			id: 'bp-change-week',
			text: t('patient.week'),
			onChange: (e: ChangeEvent<HTMLInputElement>) => setDateUnit(e.target.value as WeekMonth),
			name: 'bp-change',
			value: 'WEEK'
		},
		{
			checked: dateUnit === 'MONTH',
			id: 'bp-change-month',
			text: t('patient.month'),
			onChange: (e: ChangeEvent<HTMLInputElement>) => setDateUnit(e.target.value as WeekMonth),
			name: 'bp-change',
			value: 'MONTH'
		}
	], [dateUnit, t]);

	const style: CSSProperties = useMemo(() => {
		if (className) {
			return { flexDirection: 'column' };
		} else {
			return { flexDirection: 'column', marginTop: '20px', paddingBottom: '20px' };
		}
	}, [className]);

	// 더 보기 버튼 클릭 시 동작
	const increaseMoreCount = useCallback(() => {
		setMoreCount(prev => prev + 1);
		setLimit(prev => prev + 5);
	}, []);

	// 접기 버튼 클릭 시 동작
	const resetMoreCount = useCallback(() => {
		setMoreCount(0);
		setLimit(5);
	}, []);

	const openBPDetailDialog = useRecoilCallback(({ set }) => () => {
		const openBPDetail4Download = () => {
			window.open(`/patient/${patientSeq}/bp`, '_blank', 'width=1200,height=900');
		}

		const dialogId: string = 'bp-detail-dialog';
		const close = () => closeDialog(dialogId);

		set(dialogIDRecoilState, dialogId);
		openDialog({
			dialogTitle: t('patient.dhbpl'),
			dialogContents: <BloodPressureDetail />,
			dialogButtons: <>
				{fullChangeList.length > 0 && <Button className="blue" onClick={openBPDetail4Download}>{t('patient.download')}</Button>}
				<Button className='blue' onClick={close}>{t('dashboard.ok')}</Button>
			</>
		});
	}, [closeDialog, fullChangeList.length, openDialog, patientSeq, t]);

	useEffect(() => {
		if (patientSeq) {
			findBPChanges(patientSeq, isBit, dateUnit, isReport,
				(res) => {
					const statistics = res.data.statistics;
					const list = reverse ? statistics.reverse() : statistics;
					setFullChangeList(list);
					setChangeRow(list.slice(0, rows));
				},
				() => {
					setFullChangeList([]);
					setChangeRow([]);
				},
				() => {
					setMoreCount(0);
					setLimit(5);
				});
		}
	}, [dateUnit, isBit, isReport, patientSeq, reverse, rows]);

	useEffectAfterMount(() => {
		setChangeRow(fullChangeList.slice(0, limit));
	}, [moreCount]);

	return (
		<>
			{title &&
				<div className={`bp-trending-row ${className ?? ''}`}>
					<h2 style={{ marginBottom: 0 }}>{t('patient.bpc')}</h2>
					{toggle &&
						<div style={{ gap: 10 }}>
							{!className && <Button className='btn-bp-detail' onClick={openBPDetailDialog}>{t('patient.bpd')}</Button>}
							<DateUnitSelector options={dateUnitOptions} />
						</div>
					}
				</div>
			}
			<div className={`bp-trending-row ${className ?? ''}`} style={style}>
				<div style={{ overflowX: 'auto', width: '100%' }}>
					<table className='bp-change-table'>
						<thead className="bp-change-thead">
							<tr>
								<th>{dateUnit === 'WEEK' ? t('patient.thweek') : t('patient.thmonth')}</th>
								<th>{t('patient.nom')}</th>
								<th>{t('patient.mean')}</th>
								<th>{t('patient.sd')}</th>
								<th>{t('patient.cov')}</th>
								{/* TODO 삭제하지 말고 일단 주석 */}
								{/*<th>{t('patient.tbpr')}</th>
								<th>{t('patient.cbp')}</th>
								<th>{t('patient.rbp')}</th>
								<th>{t('patient.lbp')}</th>
								<th>{t('patient.non')}</th>*/}
							</tr>
						</thead>
						<tbody className='bp-change-tbody'>
						{fullChangeList.length === 0
							// TODO 삭제하지 말고 일단 주석
							// ? <tr><td colSpan={10}>{t('patient.nodata')}</td></tr>
							? <tr><td colSpan={5}>{t('patient.nodata')}</td></tr>
							: <Trs data={changeRow} unit={dateUnit} />
						}
						</tbody>
					</table>
				</div>
				{moreBtn && (fullChangeList.length > 5 &&
					(limit < fullChangeList.length
						? <Button className='more' onClick={increaseMoreCount}>{t('patient.more')}</Button>
						: <Button className='more' onClick={resetMoreCount}>{t('patient.fold')}</Button>
					)
				)}
			</div>
		</>
	);
};

export default Change;
import React from 'react';
import { useTranslation } from "react-i18next";
import { BPIRI, ColGroupProps } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";
import { BpiriTbody } from "@molecules/HcaiTbody";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const BloodPressureInspectResultInfo: React.FC<BPIRI> = (props) => {

	const { t } = useTranslation();

	return (
		<div className='hc-result-col'>
			<h3>{t('patient.bptr')}</h3>
			<table className='hc-result-table'>
				<ColGroup {...widths} />
				<BpiriTbody {...props} />
			</table>
		</div>
	);
};

export default BloodPressureInspectResultInfo;
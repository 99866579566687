import { AxiosResponse } from "axios";
import { getCookie } from "typescript-cookie";
import { axiosInstance } from "@/axiosInstance";
import { setAxiosRequestConfig, setHcaiResultBody } from "@logics/common";
import { AscvdFormProps, PatientFormDataProps } from "@models/layoutTypes";
import { FormProps } from "@organisms/TreatmentHistory/Form";

// 환자 인적사항 조회
export const findPatientPersonalInfo = (patientSeq: string | undefined, category: string, isBit: boolean,
										callbackSuccess: (res: AxiosResponse<any>) => void,
										callbackFail: () => void) => {

	let url: string = isBit ? '/bit' : '';
	url += `/${category}/patients/${patientSeq}/personal`;

	const config = setAxiosRequestConfig(isBit);

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 환자 상세 조회
export const findPatientDetail = (patientSeq: string | undefined,
								  category: string,
								  callbackSuccess: (res: AxiosResponse<any>) => void,
								  callbackFail: () => void) => {

	axiosInstance
		.get(`/${category}/patients/${patientSeq}`, setAxiosRequestConfig())
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 환자 수정 API
export const modifyPatient = (patientSeq: string | undefined,
							  formData: PatientFormDataProps,
							  category: string,
							  callbackSuccess?: (res: AxiosResponse<any>) => void,
							  callbackFail?: () => void) => {

	const data = {
		...formData,
		drink: formData.drink === 'Y',
		height: Number(formData.height),
		smoke: formData.smoke === 'Y',
		staffSeq: Number(formData.staffSeq),
		weight: Number(formData.weight)
	}

	delete data.staffName;

	const config = setAxiosRequestConfig();

	axiosInstance
		.patch(`/${category}/patients/${patientSeq}`, data, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 환자 비밀번호 변경 API
export const modifyPatientPassword = (patientSeq: string | undefined,
								   password: string,
								   callbackSuccess: (res: AxiosResponse<any>) => void,
								   callbackFail: () => void,
								   callbackFinally: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.patch(`/bpai/patients/${patientSeq}/password`, { password }, config)
		.then(callbackSuccess)
		.catch(callbackFail)
		.finally(callbackFinally);
}

// 혈압 분석 가능 여부 및 참여율 조회 API
export const findPrediction = (patientSeq: string | undefined,
							   callbackSuccess: (res: AxiosResponse<any>) => void,
							   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(`/predictions/patients/${patientSeq}/check`, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 진료 기록 분석 결과 조회 API
export const findPatientAnalytics = (patientSeq: string | undefined, isBit: boolean,
									 callbackSuccess: (res: AxiosResponse<any>) => void,
									 callbackFail: () => void) => {

	let url: string = isBit ? '/bit' : '';
	url += `/treatments/analytics/patients/${patientSeq}`;

	const config = setAxiosRequestConfig(isBit);

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 환자 기본 정보 조회 API
export const findPatientBasicInfo = (patientSeq: string,
									 callbackSuccess: (res: AxiosResponse<any>) => void) => {

	const url: string = `/bpai/patients/${patientSeq}/basic`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch();
}

// 진료 기록 조회 API
export const findTreatmentHistory = (patientSeq: string | undefined,
									 callbackSuccess: (res: AxiosResponse<any>) => void,
									 callbackFail: () => void) => {

	const url: string = `/treatments/patients/${patientSeq}`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 진료 기록 등록 API
export const saveTreatmentHistory = (formState: FormProps,
									 callbackSuccess: (res: AxiosResponse<any>) => void) => {
	const data: FormProps = {
		...formState,
		measuredDate: formState.hasMeasurement ? formState.measuredDate : undefined,
		electrocardiogramTestDate: formState.hasElectrocardiogramTest ? formState.electrocardiogramTestDate : undefined,
		urineTestDate: formState.hasUrineTest ? formState.urineTestDate : undefined,
		bloodCheckDate: formState.hasBloodCheck ? formState.bloodCheckDate : undefined
	};

	delete data.treatmentSeq;
	delete data.patientBasicInfo;

	const config = setAxiosRequestConfig();

	axiosInstance
		.post('/treatments', data, config)
		.then(callbackSuccess)
		.catch();
}

// 진료 기록 상세 API
export const findTreatmentHistoryDetail = (treatmentSeq: number,
										   callbackSuccess: (res: AxiosResponse<any>) => void,
										   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(`/treatments/${treatmentSeq}`, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 진료 기록 삭제 API
export const deleteTreatmentHistory = (treatmentSeq: number,
									   callbackSuccess: (res: AxiosResponse<any>) => void,
									   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.delete(`/treatments/${treatmentSeq}`, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 진료 기록 수정 API
export const modifyTreatmentHistory = (treatmentSeq: number | undefined, formState: FormProps) => {
	const data = {
		...formState,
		measuredDate: formState.hasMeasurement ? formState.measuredDate : undefined,
		electrocardiogramTestDate: formState.hasElectrocardiogramTest ? formState.electrocardiogramTestDate : undefined,
		urineTestDate: formState.hasUrineTest ? formState.urineTestDate : undefined,
		bloodCheckDate: formState.hasBloodCheck ? formState.bloodCheckDate : undefined,
		diastolic: Number(formState.diastolic ?? '0'),
		fbs: Number(formState.fbs ?? '0'),
		hdl: Number(formState.hdl ?? '0'),
		ldl: Number(formState.ldl ?? '0'),
		lvh: Number(formState.lvh ?? '0'),
		systolic: Number(formState.systolic ?? '0'),
		tchol: Number(formState.tchol ?? '0'),
		tg: Number(formState.tg ?? '0'),
		ualbDCr: Number(formState.ualbDCr ?? '0')
	};

	delete data.patientBasicInfo;
	delete data.patientSeq;
	delete data.treatmentSeq;

	const config = setAxiosRequestConfig();

	axiosInstance
		.patch(`/treatments/${treatmentSeq}`, data, config)
		.then()
		.catch();
}

// 혈압 추이 그래프 데이터 조회 API
export const findBPHistoryByPeriod = (patientSeq: string | undefined, isBit: boolean,
									  from: string, to: string,
									  callbackSuccess: (res: AxiosResponse<any>) => void) => {

	let url: string = isBit ? '/bit' : '';
	url += `/bpai/web/bp-history/patients/${patientSeq}/period?from=${from}&to=${to}`;

	const config = setAxiosRequestConfig(isBit);

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch();
}

// 혈압 변화 데이터 조회 API
export const findBPChanges = (patientSeq: string | undefined, isBit: boolean,
							  unit: string,
							  isReport: boolean,
							  callbackSuccess: (res: AxiosResponse<any>) => void,
							  callbackFail: () => void,
							  callbackAlways: () => void) => {

	let url: string = isBit ? '/bit' : '';
	url += `/bpai/web/bp-history/patients/${patientSeq}/statistics?unit=${unit}&isReport=${isReport}`;

	const config = setAxiosRequestConfig(isBit);

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail)
		.finally(callbackAlways);
}

// 혈압 상세 내역 조회 API
export const findBPDetail = (patientSeq: string | undefined,
							 callbackSuccess: (res: AxiosResponse<any>) => void,
							 callbackFail: () => void) => {

	const url: string = `/bpai/web/bp-history/patients/${patientSeq}/date`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 설치 안내 문자 발송 API
export const sendInstallGuideSMS = (patientSeq: string) => {
	const url: string = `/bpai/patients/${patientSeq}/sms`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then()
		.catch();
}

// 혈압 분석 결과 조회 API
export const findPredictionResult = (patientSeq: string | undefined, isBit: boolean,
									 callbackSuccess: (res: AxiosResponse<any>) => void,
									 callbackFail: () => void) => {

	let url: string = isBit ? '/bit' : '';
	url += `/predictions/patient/${patientSeq}`;

	const t3qAccessToken: string | undefined = getCookie('access_token');
	if (t3qAccessToken) {
		url += `?t3qAccessToken=${t3qAccessToken}`;
	}

	const config = setAxiosRequestConfig(isBit);

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// hcai 검사 결과 불러오기
export const findASCVDDetail = (patientSeq: string, ascvdSeq: number,
								callbackSuccess: (res: AxiosResponse<any>) => void,
								callbackFail: () => void) => {

	const url: string = `/hcai/patients/${patientSeq}/ascvds/${ascvdSeq}`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// hcai 검사 결과 저장
export const saveTestResult = (formData: AscvdFormProps, patientSeq: string, staffSeq: number,
							   callbackSuccess: (res: AxiosResponse<any>) => void,
							   callbackFail: () => void) => {

	const body = setHcaiResultBody(formData, patientSeq);

	delete body.ascvdSeq;

	const config = setAxiosRequestConfig();

	axiosInstance
		.post('/hcai/inspectResult', { ...body, staffSeq }, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// hcai 검사 결과 수정
export const modifyTestResult = (formData: AscvdFormProps, patientSeq: string,
							   callbackSuccess: (res: AxiosResponse<any>) => void,
							   callbackFail: () => void) => {

	const body = setHcaiResultBody(formData, patientSeq);

	const config = setAxiosRequestConfig();

	axiosInstance
		.put('/hcai/prediction', body, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// hcai 과거 결과 리스트
export const findPastAscvds = (patientSeq: string,
						   callbackSuccess: (res: AxiosResponse<any>) => void,
						   callbackFail: () => void) => {

	const url: string = `/hcai/patients/${patientSeq}/ascvds`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// hcai 검사 결과 삭제
export const deleteAscvdTestResult = (patientSeq: string, ascvdSeq: number,
							callbackSuccess: (res: AxiosResponse<any>) => void) => {

	const url: string = `/hcai/patients/${patientSeq}/ascvd/${ascvdSeq}`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.delete(url, config)
		.then(callbackSuccess);
}

// hcai 위험 평가
export const evaluate = (ascvdSeq: number = 0,
						 callbackSuccess: (res: AxiosResponse<any>) => void,
						 callbackFail: () => void) => {

	let url: string = '/hcai/prediction';
	const t3qAccessToken: string | undefined = getCookie('access_token');
	if (t3qAccessToken) {
		url += `?t3qAccessToken=${t3qAccessToken}`;
	}

	const config = setAxiosRequestConfig();

	axiosInstance
		.post(url, { ascvdSeq }, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}
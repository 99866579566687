import { useResetRecoilState } from "recoil";
import {
	aiModelState,
	apiFunctionState,
	ascvdGraphLegendStyleState,
	ascvdResultChangeState,
	ascvdResultState,
	ascvdTestResultExistState,
	bpaiPersonalInfoState,
	bpTrendingGraphDateState,
	columnGraphDateState,
	dialogIDRecoilState,
	dialogRecoilState,
	doctorNameKeywordState,
	doctorOptionsState,
	evaluatedState,
	filterBtnOffsetLeftState,
	filterBtnOffsetTopState,
	filterCheckedState,
	filterValuesState,
	hcaiPersonalInfoState,
	hospitalCategoriesState,
	hospitalModifyCountState,
	hospitalSearchState,
	hospitalT3QkeyState,
	isSubmitButtonDisabledState,
	loggedInUserState,
	modifyPasswordSucceedState,
	mostRecentAscvdSeqState,
	navMenuState,
	pageNumberState,
	patientSearchKeywordState,
	pieGraphDateState,
	profileButtonsState,
	riskAssmtDateState,
	totalElementsState,
	treatmentHistoryAddedState,
	usedCategoriesState,
	userListRefreshState
} from "@models/recoilStates";

export const useResetAllRecoilStates = () => {
	// A
	const resetAiModel = useResetRecoilState(aiModelState);
	const resetApiFunction = useResetRecoilState(apiFunctionState);
	const resetAscvdGraphLegendStyle = useResetRecoilState(ascvdGraphLegendStyleState);
	const resetAscvdResult = useResetRecoilState(ascvdResultState);
	const resetAscvdResultChange = useResetRecoilState(ascvdResultChangeState);
	const resetAscvdTestResultExist = useResetRecoilState(ascvdTestResultExistState);

	// B
	const resetBpaiPersonalInfo = useResetRecoilState(bpaiPersonalInfoState);
	const resetBpTrendingGraph = useResetRecoilState(bpTrendingGraphDateState);

	// C
	const resetColumnGraphDate = useResetRecoilState(columnGraphDateState);

	// D
	const resetDialog = useResetRecoilState(dialogRecoilState);
	const resetDialogID = useResetRecoilState(dialogIDRecoilState);
	const resetDoctorNameKeyword = useResetRecoilState(doctorNameKeywordState);
	const resetDoctorOptions = useResetRecoilState(doctorOptionsState);

	// E
	const resetEvaluated = useResetRecoilState(evaluatedState);

	// F
	const resetFilterBtnOffsetLeft = useResetRecoilState(filterBtnOffsetLeftState);
	const resetFilterBtnOffsetTop = useResetRecoilState(filterBtnOffsetTopState);
	const resetFilterChecked = useResetRecoilState(filterCheckedState);
	const resetFilterValues = useResetRecoilState(filterValuesState);

	// H
	const resetHcaiPersonalInfo = useResetRecoilState(hcaiPersonalInfoState);
	const resetHospitalCategories = useResetRecoilState(hospitalCategoriesState);
	const resetHospitalModifyCount = useResetRecoilState(hospitalModifyCountState);
	const resetHospitalSearch = useResetRecoilState(hospitalSearchState);
	const resetHospitalT3Qkey = useResetRecoilState(hospitalT3QkeyState);

	// I
	const resetIsSubmitButtonDisabled = useResetRecoilState(isSubmitButtonDisabledState);

	// L
	const resetLoggedInUser = useResetRecoilState(loggedInUserState);

	// M
	const resetModifyPasswordSucceed = useResetRecoilState(modifyPasswordSucceedState);
	const resetMostRecentAscvdSeq = useResetRecoilState(mostRecentAscvdSeqState);

	// N
	const resetNavMenu = useResetRecoilState(navMenuState);

	// P
	const resetPageNumber = useResetRecoilState(pageNumberState);
	const resetPatientSearchKeyword = useResetRecoilState(patientSearchKeywordState);
	const resetPieGraphDate = useResetRecoilState(pieGraphDateState);
	const resetProfileButton = useResetRecoilState(profileButtonsState);

	// R
	const resetRiskAssmtDate = useResetRecoilState(riskAssmtDateState);

	// T
	const resetTotalElements = useResetRecoilState(totalElementsState);
	const resetTreatmentHistoryAdded = useResetRecoilState(treatmentHistoryAddedState);

	// U
	const resetUsedCategories = useResetRecoilState(usedCategoriesState);
	const resetUserListRefresh = useResetRecoilState(userListRefreshState);

	return () => {
		// A
		resetAiModel();
		resetApiFunction();
		resetAscvdGraphLegendStyle();
		resetAscvdResult();
		resetAscvdResultChange();
		resetAscvdTestResultExist();

		// B
		resetBpaiPersonalInfo();
		resetBpTrendingGraph();

		// C
		resetColumnGraphDate();

		// D
		resetDialog();
		resetDialogID();
		resetDoctorNameKeyword();
		resetDoctorOptions();

		// E
		resetEvaluated();

		// F
		resetFilterBtnOffsetLeft();
		resetFilterBtnOffsetTop();
		resetFilterChecked();
		resetFilterValues();

		// H
		resetHcaiPersonalInfo();
		resetHospitalCategories();
		resetHospitalModifyCount();
		resetHospitalSearch();
		resetHospitalT3Qkey();

		// I
		resetIsSubmitButtonDisabled();

		// L
		resetLoggedInUser();

		// M
		resetModifyPasswordSucceed();
		resetMostRecentAscvdSeq();

		// N
		resetNavMenu();

		// P
		resetPageNumber();
		resetPatientSearchKeyword();
		resetPieGraphDate();
		resetProfileButton();

		// R
		resetRiskAssmtDate();

		// T
		resetTotalElements();
		resetTreatmentHistoryAdded();

		// U
		resetUsedCategories();
		resetUserListRefresh();
	};
}
import React from 'react';
import { BIRI } from "@models/layoutTypes";

const Biri: React.FC<BIRI> = ({ appt, ast,
								hdlCholesterol,
								pdw, phosphorus,
								totalCholesterol }) => {
	return (
		<tbody>
			<tr>
				<th>Total cholesterol</th>
				<td>{totalCholesterol} mg/dL</td>
				<th>PDW</th>
				<td>{pdw} %</td>
				<th>Phosphorus</th>
				<td>{phosphorus} mg/dL</td>
			</tr>
			<tr>
				<th>AST</th>
				<td>{ast} U/L</td>
				<th>aPTT</th>
				<td>{appt} sec</td>
				<th>HDL-C</th>
				<td>{hdlCholesterol} mg/dL</td>
			</tr>
		</tbody>
	);
};

export default Biri;
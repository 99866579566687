import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { findASCVDDetail } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { isAllTruthy } from "@logics/common";
import { AscvdDetailProps, TestResultFormProps } from "@models/layoutTypes";
import {
	ascvdResultChangeState,
	ascvdTestResultExistState,
	dialogIDRecoilState,
	evaluatedState, mostRecentAscvdSeqState, riskAssmtDateState
} from "@models/recoilStates";
import {
	AscvdRegisterForm,
	BloodInspectResultInfo,
	BloodPressureInspectResultInfo,
	PatientHealthInformation,
	RiskAssessmentDate
} from "@molecules/Ascvd";
import { DialogButtons } from "@molecules/ModifyPassword";
import { RiskAssessment } from "@molecules/Tooltip";
import icInfo from '@styles/images/ic-info.png';

const ascvdStyle: CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	gap: 40,
	width: '100%'
};

const TestResult = () => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();
	const { openDialog } = useDialog();

	const [ascvdData, setAscvdData] = useState<AscvdDetailProps>();
	const [left, setLeft] = useState(0);
	const [showTooltip, setShowTooltip] = useState(false);
	const [top, setTop] = useState(0);

	const ascvdTestResultExist = useRecoilValue(ascvdTestResultExistState);
	const changeCnt = useRecoilValue(ascvdResultChangeState);
	const evaluated = useRecoilValue(evaluatedState);

	const tooltipRef = useRef<HTMLImageElement>(null);

	const openAscvdFormDialog = useRecoilCallback(({ set }) => (isModifyMode: boolean) => {
		const props: TestResultFormProps = { ...ascvdData!, isModifyMode };
		const id: string = 'register-test-result';
		set(dialogIDRecoilState, id);
		openDialog({
			dialogTitle: isModifyMode? t('patient.edittr') : t('patient.etr'),
			dialogContents: <AscvdRegisterForm {...props} />,
			dialogButtons: <DialogButtons dialogId={id} submitButtonText={t('hospital.dhksfy')} />
		});
	}, [ascvdData, openDialog, t]);

	const callbackSuccess = useRecoilCallback(({ set }) => (res: AxiosResponse<any, any>) => {
		const data: AscvdDetailProps = res.data;
		setAscvdData(data);

		set(ascvdTestResultExistState, (data && isAllTruthy(data)));
		set(mostRecentAscvdSeqState, data.ascvdSeq);
		set(riskAssmtDateState, data.riskAssessmentDate.split('T')[0]);
	}, []);

	const callbackFail = useRecoilCallback(({ reset }) => () => {
		setAscvdData(undefined);

		reset(ascvdTestResultExistState);
		reset(mostRecentAscvdSeqState);
		reset(riskAssmtDateState);
	}, []);

	useEffect(() => {
		if (patientSeq) {
			findASCVDDetail(patientSeq, 0, callbackSuccess, callbackFail);
		}
	}, [callbackFail, callbackSuccess, changeCnt, patientSeq]);

	useEffect(() => {
		const tooltip = tooltipRef.current;
		if (tooltip) {
			setLeft(tooltip.offsetLeft);
			setTop(tooltip.offsetTop + 30);
		}
	}, [showTooltip, t]);

	useEffectAfterMount(() => {
		if (!ascvdTestResultExist) {
			setAscvdData(undefined);
		}
	}, [ascvdTestResultExist]);

	return (
		<>
			<div className='hc-result-row'>
				<div style={{ alignItems: 'center', display: 'flex', gap: 8 }}>
					<h2>{t('patient.testresult')}</h2>
					<img src={icInfo} alt='icon' ref={tooltipRef}
						 style={{ cursor: 'pointer', height: 20, width: 20 }}
						 onClick={() => setShowTooltip(prev => !prev)}
					/>
				</div>
				{ascvdTestResultExist
					? evaluated
						? <Button style={{ border: '1px solid #999' }} disabled>{t('patient.editresult')}</Button>
						: <Button className='white' onClick={() => openAscvdFormDialog(true)}>{t('patient.editresult')}</Button>
					: <Button className='blue' onClick={() => openAscvdFormDialog(false)}>{t('patient.etr')}</Button>
				}
				{showTooltip && <RiskAssessment left={left} top={top} />}
			</div>
			<div className='hc-result-row' style={{ minHeight: 300, paddingTop: 0 }}>
				{ascvdData && isAllTruthy(ascvdData)
					? <div style={ascvdStyle}>
						<RiskAssessmentDate date={ascvdData.riskAssessmentDate} />
						<PatientHealthInformation {...ascvdData.patientHealthInfo} />
						<BloodPressureInspectResultInfo {...ascvdData.bloodPressureInspectResultInfo} />
						<BloodInspectResultInfo {...ascvdData.bloodInspectResultInfo} />
					</div>
					: <p className='no-result'>{t('patient.notr')}</p>
				}
			</div>
		</>
	);
};

export default TestResult;
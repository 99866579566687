import { AxiosResponse } from "axios";
import { axiosInstance } from "@/axiosInstance";
import { formatDate, setAxiosRequestConfig } from "@logics/common";

export const findDashboardStatistics = (callbackSuccess: (res: AxiosResponse<any>) => void,
										callbackFail: () => void) => {

	const url: string = '/bpai/web/dashboard/statistics';
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

export const findDangerousPatients = (hospitalSeq: number,
									  callbackSuccess: (res: AxiosResponse<any>) => void,
									  callbackFail: () => void) => {

	const url: string = `/bpai/patients/hospital/${hospitalSeq}/status/dangerous`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

export const findDashboardColumnGraphData = (date: Date,
											 callbackSuccess: (res: AxiosResponse<any>) => void,
											 callbackFail: () => void) => {

	const from: string = formatDate(new Date(new Date().setDate(date.getDate() - 6)), '-');
	const to: string = formatDate(date, '-');
	const url: string = `/bpai/web/dashboard/period?from=${from}&to=${to}`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

export const sendEncouragementPush = (patientSeq?: string) => {
	let url: string = `/bpai/patients/cheerUp`;
	if (patientSeq) {
		url += `/${patientSeq}`;
	}

	const config = setAxiosRequestConfig();

	axiosInstance
		.post(url, {}, config)
		.then()
		.catch();
}
import React from 'react';
import { ColGroupProps } from "@models/layoutTypes";

const ColGroup: React.FC<ColGroupProps> = ({ widths }) => {
	return (
		<colgroup>
			{widths.map((width, index) => <col key={index} width={width} />)}
		</colgroup>
	);
};

export default ColGroup;
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { sendEncouragementPush } from "@apis/dashboard";
import { findPrediction } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { INITIAL_PREDICTION } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { PredictionProps } from "@models/layoutTypes";
import { apiFunctionState, dialogIDRecoilState, isSubmitButtonDisabledState } from "@models/recoilStates";
import { BpPredictionGraph } from "@molecules/Graph";
import { DialogButtons } from "@molecules/ModifyPassword";
import { PredictResult } from "@molecules/PredictResult";

const Prediction = () => {
	const { openDialog } = useDialog();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [prediction, setPrediction] = useState<PredictionProps>(INITIAL_PREDICTION);

	const setApiFunction = useSetRecoilState(apiFunctionState);
	const setDialogID = useSetRecoilState(dialogIDRecoilState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	const sendPush = () => {
		sendEncouragementPush(patientSeq);
	}

	useEffect(() => {
		if (patientSeq) {
			findPrediction(patientSeq,
				(res) => setPrediction(res.data),
				() => setPrediction(INITIAL_PREDICTION));
			}
	}, [patientSeq]);

	const openSendPushDialog = () => {
		setApiFunction(() => sendPush);
		setIsSubmitButtonDisabled(false);
		setDialogID('send-push-dialog');
		openDialog({
			dialogTitle: t('dashboard.spn'),
			dialogContents: t('dashboard.sartypettmtb'),
			dialogButtons: <DialogButtons dialogId='send-push-dialog' submitButtonText={t('patient.yessend')} />
		});
	}

	const openPredictResultReport = () => {
		window.open(`/patient/${patientSeq}/predict/result`, '_blank', 'width=1200,height=900');
	}

	const openPredictResultDialog = () => {
		setIsSubmitButtonDisabled(false);
		setDialogID('predict-result-dialog');
		openDialog({
			dialogTitle: t('patient.bppr'),
			dialogContents: <PredictResult />,
			dialogButtons: <Button className='blue' onClick={openPredictResultReport}>{t('patient.seereport')}</Button>
		});
	}

	return (
		<div className='analysis-content' style={{ paddingTop: 0 }}>
			<div className='analysis-header'>
				<h2>{t('patient.bpp')}</h2>
				{prediction.predictable
					? <Button className='blue' onClick={openPredictResultDialog}>{t('patient.spr')}</Button>
					: <Button className='blue' onClick={openSendPushDialog}>{t('dashboard.spn')}</Button>
				}
			</div>
			<div className='analysis-body'>
				<BpPredictionGraph {...prediction} />
				<div className='bp-prediction-legend'>
					<div className='bp-prediction-legend-item deep-blue'>
						<span>{t('patient.pc')}</span>
						<strong>{prediction.participantRate}%</strong>
					</div>
					<div className='bp-prediction-legend-item border' />
					<div className='bp-prediction-legend-item sky-blue'>
						<span>{t('patient.mc')}</span>
						<strong>{prediction.measuredDateCount}{t('patient.days')}</strong>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Prediction;
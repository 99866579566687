import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { findPatientPersonalInfo, modifyPatient } from "@apis/patientDetail";
import { saveNewPatient } from "@apis/patientList";
import { Input } from "@atoms/Input";
import { DRINK_OPTIONS, SMOKE_OPTIONS } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { PatientFormPropsExt } from "@models/layoutTypes";
import {
	apiFunctionState, bpaiPersonalInfoState, doctorOptionsState,
	isSubmitButtonDisabledState, totalElementsState
} from "@models/recoilStates";
import { DatePicker } from "@molecules/DatePicker";
import { DoctorInput } from "@molecules/PatientForm";
import { SelectBox } from "@molecules/SelectBox";
import { CheckBoxOption } from "@molecules/ToggleInputs";

const Bpai: React.FC<PatientFormPropsExt> = ({ genderOptions,
												formData,
												setFormData,
												handleFormDataChange,
												handleSelectChange,
												type }) => {

	const { closeDialog } = useDialog();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [phoneFormat, setPhoneFormat] = useState<boolean>(!!formData.contact);

	const duplicatedIdRef = useRef<HTMLParagraphElement>(null);
	const phoneRef = useRef<HTMLInputElement>(null);

	const peid: string = useMemo(() => t('login.peid'), [t]);

	const submittable: boolean = useMemo(() => !!(phoneFormat &&
			formData.birthDate &&
			formData.contact &&
			formData.drink &&
			formData.gender &&
			formData.height &&
			formData.id &&
			formData.name &&
			formData.smoke &&
			formData.staffSeq &&
			formData.weight),
	[formData.birthDate, formData.contact, formData.drink,
		formData.gender, formData.height, formData.id,
		formData.name, formData.smoke, formData.staffSeq,
		formData.weight, phoneFormat]);

	// 신규 환자 등록
	const savePatient = useRecoilCallback(({ reset, set }) => () => {
		saveNewPatient(formData, 'bpai', res => {
			if (!res.data.code) {
				set(totalElementsState, prev => prev + 1);
				closeDialog('register-patient-information-dialog');
				duplicatedIdRef.current!.style.setProperty('display', '');
				reset(doctorOptionsState);
			} else if (res.data.code === 4007) {
				duplicatedIdRef.current!.style.setProperty('display', 'block');
			}
		});
	}, [closeDialog, formData]);

	// 환자 수정
	const editPatient = useRecoilCallback(({ reset, set }) => () => {
		modifyPatient(patientSeq, formData, 'bpai', () => {
			findPatientPersonalInfo(patientSeq, 'bpai', false, 
				res => set(bpaiPersonalInfoState, res.data),
				() => {});

			reset(doctorOptionsState);
		});
	}, [formData, patientSeq]);

	// 제출 가능 여부에 따라 태울 api 설정
	const effectBySubmittable = useRecoilCallback(({ reset, set }) => () => {
		set(isSubmitButtonDisabledState, !submittable);

		if (submittable) {
			if (type === 'registration') {
				set(apiFunctionState, () => savePatient);
			} else if (type === 'modify') {
				set(apiFunctionState, () => editPatient);
			}
		} else {
			reset(apiFunctionState);
		}
	}, [editPatient, savePatient, submittable, type]);

	useEffectAfterMount(() => {
		effectBySubmittable();
	}, [effectBySubmittable]);

	useEffectAfterMount(() => {
		if (phoneRef.current) {
			setPhoneFormat(phoneRef.current.checkValidity());
		}
	}, [formData.contact]);

	return (
		<tbody>
			<tr>
				<td colSpan={2}>
					<div className='input-grp'>
						<label htmlFor="patientId">{t('login.id')}</label>
						{type === 'registration'
							? (<>
								<Input id='patientId' name='id' type='text' placeholder={peid}
									   onChange={handleFormDataChange} value={formData.id ?? ''} />
								<p className='duplicated-id' ref={duplicatedIdRef}>{t('profile.duplid')}</p>
							</>)
							: <Input id='patientId' name='id' type='text' placeholder={peid} defaultValue={formData.id} disabled />
						}
					</div>
				</td>
				<td>
					<div className='input-grp'>
						<label htmlFor="height">{t('patient.height')}</label>
						<Input id='height' name='height' type='number' step={0.1} placeholder='00.0 cm'
							   onChange={handleFormDataChange} min={0} value={formData.height ?? ''} />
					</div>
				</td>
				<td>
					<div className='input-grp'>
						<label htmlFor="weight">{t('patient.weight')}</label>
						<Input id='weight' name='weight' type='number' step={0.1} placeholder='00.0 kg'
							   onChange={handleFormDataChange} min={0} value={formData.weight ?? ''} />
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<div className='input-grp'>
						<label htmlFor="name">{t('patient.patientnm')}</label>
						<Input id='name' name='name' type='text' placeholder={t('patient.petpn')}
							   onChange={handleFormDataChange} value={formData.name} />
					</div>
				</td>
				<td>
					<div className='input-grp'>
						<label htmlFor="smoke">{t('patient.smoke')}</label>
						<SelectBox name='smoke'
								   onChange={handleSelectChange('smoke')}
								   options={SMOKE_OPTIONS}
								   placeholder={t('patient.smkstatus')}
								   value={type === 'modify' ? SMOKE_OPTIONS.find(option => option.value === formData.smoke) : undefined}
						/>
					</div>
				</td>
				<td>
					<div className='input-grp'>
						<label htmlFor="drink">{t('patient.drink')}</label>
						<SelectBox name='drink'
								   onChange={handleSelectChange('drink')}
								   options={DRINK_OPTIONS}
								   placeholder={t('patient.drinkstatus')}
								   value={type === 'modify' ? DRINK_OPTIONS.find(option => option.value === formData.drink) : undefined}
						/>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<div className='input-grp'>
						<label htmlFor="gender">{t('dashboard.gender')}</label>
						<SelectBox name='gender'
								   onChange={handleSelectChange('gender')}
								   options={genderOptions}
								   placeholder={t('patient.genderselect')}
								   value={type === 'modify' ? genderOptions.find(option => option.value === formData.gender) : undefined}
						/>
					</div>
				</td>
				<td>
					<div className='input-grp'>
						<label htmlFor="birthDate">{t('patient.dob')}</label>
						<DatePicker id='birthDate' name='birthDate' value={formData.birthDate} onChange={setFormData} />
					</div>
				</td>
				<DoctorInput onChange={handleSelectChange} staffName={formData.staffName} staffSeq={formData.staffSeq} type={type} />
			</tr>
			<tr>
				<td colSpan={2}>
					<div className='input-grp'>
						<label htmlFor="contact">{t('patient.phone')}</label>
						<Input id='contact' name='contact' type='text' maxLength={13} ref={phoneRef}
							   placeholder={t('patient.pithwe')} pattern="010-\d{4}-\d{4}"
							   onChange={handleFormDataChange} value={formData.contact} />
					</div>
				</td>
				<td colSpan={2}>
					<div className='input-grp'>
						<label>{t('patient.disease')}</label>
						<CheckBoxOption id='familyDisease' name='familyDisease' onChange={handleFormDataChange}
										checked={formData.familyDisease ?? false}
										text={t('patient.family')} />
					</div>
				</td>
			</tr>
		</tbody>
	);
};

export default Bpai;
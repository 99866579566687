import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { findPatientPersonalInfo, modifyPatient } from "@apis/patientDetail";
import { saveNewPatient } from "@apis/patientList";
import { Input } from "@atoms/Input";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { PatientFormPropsExt } from "@models/layoutTypes";
import {
	apiFunctionState,
	doctorOptionsState,
	hcaiPersonalInfoState,
	isSubmitButtonDisabledState,
	totalElementsState
} from "@models/recoilStates";
import { DatePicker } from "@molecules/DatePicker";
import { DoctorInput } from "@molecules/PatientForm";
import { SelectBox } from "@molecules/SelectBox";

const Hcai: React.FC<PatientFormPropsExt> = ({ genderOptions,
												formData,
												setFormData,
												handleFormDataChange,
												handleSelectChange,
												type }) => {

	const { closeDialog } = useDialog();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [phoneFormat, setPhoneFormat] = useState<boolean>(!!formData.contact);

	const phoneRef = useRef<HTMLInputElement>(null);

	const submittable: boolean = useMemo(() => !!(phoneFormat &&
		formData.birthDate &&
		formData.contact &&
		formData.gender &&
		formData.name &&
		formData.staffSeq
	), [formData.birthDate, formData.contact, formData.gender, formData.name, formData.staffSeq, phoneFormat]);

	// 신규 환자 등록
	const savePatient = useRecoilCallback(({ reset, set }) => () => {
		saveNewPatient(formData, 'hcai', res => {
			if (!res.data.code) {
				set(totalElementsState, prev => prev + 1);
				closeDialog('register-patient-information-dialog');
				reset(doctorOptionsState);
			}
		});
	}, [closeDialog, formData]);

	// 환자 수정
	const editPatient = useRecoilCallback(({ reset, set }) => () => {
		modifyPatient(patientSeq, formData, 'hcai', () => {
			findPatientPersonalInfo(patientSeq, 'hcai', false,
				res => set(hcaiPersonalInfoState, res.data),
				() => {});

			reset(doctorOptionsState);
		});
	}, [formData, patientSeq]);

	// 제출 가능 여부에 따라 태울 api 설정
	const effectBySubmittable = useRecoilCallback(({ reset, set }) => () => {
		set(isSubmitButtonDisabledState, !submittable);

		if (submittable) {
			if (type === 'registration') {
				set(apiFunctionState, () => savePatient);
			} else {
				set(apiFunctionState, () => editPatient);
			}
		} else {
			reset(apiFunctionState);
		}
	}, [editPatient, savePatient, submittable, type]);

	useEffectAfterMount(() => {
		effectBySubmittable();
	}, [effectBySubmittable]);

	useEffectAfterMount(() => {
		if (phoneRef.current) {
			setPhoneFormat(phoneRef.current.checkValidity());
		}
	}, [formData.contact]);

	return (
		<tbody>
			<tr>
				<td colSpan={2}>
					<div className='input-grp'>
						<label htmlFor="name">{t('patient.patientnm')}</label>
						<Input id='name' name='name' type='text' placeholder={t('patient.petpn')}
							   onChange={handleFormDataChange} value={formData.name} />
					</div>
				</td>
				<DoctorInput onChange={handleSelectChange} staffName={formData.staffName} staffSeq={formData.staffSeq} type={type} />
			</tr>
			<tr>
				<td colSpan={2}>
					<div className='input-grp'>
						<label htmlFor="contact">{t('patient.phone')}</label>
						<Input id='contact' name='contact' type='text' maxLength={13} ref={phoneRef}
							   placeholder={t('patient.pithwe')} pattern="010-\d{4}-\d{4}"
							   onChange={handleFormDataChange} value={formData.contact} />
					</div>
				</td>
				<td colSpan={2} rowSpan={3} style={{ paddingBottom: 0 }}>
					<div className='input-grp'>
						<label htmlFor="hcaiMemo">{t('patient.memo')}</label>
						<textarea id='hcaiMemo' name='hcaiMemo' style={{ height: 128 }}
								value={formData.hcaiMemo ?? ''} onChange={handleFormDataChange}
								placeholder={t('patient.petmemo')}></textarea>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<div className='input-grp'>
						<label htmlFor="gender">{t('dashboard.gender')}</label>
						<SelectBox name='gender'
							onChange={handleSelectChange('gender')}
							options={genderOptions}
							placeholder={t('patient.genderselect')}
							value={type === 'modify' ? genderOptions.find(option => option.value === formData.gender) : undefined}
						/>
					</div>
				</td>
				<td>
					<div className='input-grp'>
						<label htmlFor="birthDate">{t('patient.dob')}</label>
						<DatePicker id='birthDate' name='birthDate' value={formData.birthDate} onChange={setFormData} />
					</div>
				</td>
				<td colSpan={2}></td>
			</tr>
		</tbody>
	);
};

export default Hcai;
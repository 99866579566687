import React, { Dispatch, forwardRef, SetStateAction, useCallback, useMemo, useState } from 'react';
import ReactDatePicker from "react-datepicker";
import { ko, enUS } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import '@styles/css/datepicker.css';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Input } from "@atoms/Input";
import { formatDate } from "@logics/common";
import { DatePickerHeader } from "@molecules/DatePicker";

interface DatePickerProps {
	id: string;
	name: string;
	readOnly?: boolean;
	value?: string;
	onChange: Dispatch<SetStateAction<any>>
}

const range = (start: number, end: number): number[] => new Array(end - start).fill(0).map((_, i) => i + start);

const CustomInput = forwardRef<HTMLInputElement, any>(({ id, name, onClick, value }, ref) => {
	const { t } = useTranslation();

	return <Input className="datepicker" id={id} name={name} onClick={onClick} placeholder={t('patient.selectdate')} readOnly ref={ref} value={value} />;
});

const DatePicker: React.FC<DatePickerProps> = ({ id, name, onChange, readOnly, value }) => {
	const lng = i18next.language;

	const { t } = useTranslation();

	const [selectedDate, setSelectedDate] = useState<Date>(new Date(value || Date.now()));
	const [visibleMonth, setVisibleMonth] = useState<number>(selectedDate.getMonth());
	const [visibleYear, setVisibleYear] = useState<number>(selectedDate.getFullYear());

	const years: number[] = useMemo(() => range(1900, new Date().getFullYear() + 1), []);
	const months: string[] = useMemo(() => ([
		t('month.jan'),
		t('month.feb'),
		t('month.mar'),
		t('month.apr'),
		t('month.may'),
		t('month.jun'),
		t('month.jul'),
		t('month.aug'),
		t('month.sep'),
		t('month.oct'),
		t('month.nov'),
		t('month.dec')
	]), [t]);

	const changeVisibleYM = useCallback((date: Date) => {
		setVisibleMonth(date.getMonth());
		setVisibleYear(date.getFullYear());
	}, []);

	const filterDate = useCallback((date: Date) => {
		// 화면에 표시되는 달과 연도를 기준으로 다른 달의 날짜를 비활성화
		return date.getMonth() === visibleMonth && date.getFullYear() === visibleYear;
	}, [visibleMonth, visibleYear]);

	const handleDateChange = useCallback((date: Date) => {
		setSelectedDate(date);
		onChange((prev: any) => ({ ...prev, [name]: formatDate(date, '-') }));
	}, [name, onChange]);

	return (
		<ReactDatePicker
			customInput={<CustomInput />}
			dateFormat='yyyy-MM-dd'
			dateFormatCalendar="MMMM"
			filterDate={filterDate}
			id={id}
			locale={lng === 'en' ? enUS : ko}
			maxDate={new Date()}
			minDate={new Date(1900, 0, 1)}
			name={name}
			onCalendarClose={() => changeVisibleYM(selectedDate)}
			onChange={handleDateChange}
			onMonthChange={changeVisibleYM}
			onYearChange={changeVisibleYM}
			popperProps={{ strategy: 'fixed' }}
			readOnly={readOnly}
			renderCustomHeader={(params) => <DatePickerHeader {...params} years={years} months={months} />}
			selected={selectedDate}
			shouldCloseOnSelect
		/>
	);
};

export default DatePicker;
import { AxiosResponse } from "axios";
import { axiosInstance } from "@/axiosInstance";
import { setAxiosRequestConfig } from "@logics/common";
import { PatientFormDataProps, PatientSearchProps } from "@models/layoutTypes";

// 환자 목록 조회 API
export const findPatientList = (data: PatientSearchProps,
								page: number = 0,
								category: string,
								callbackSuccess: (res: AxiosResponse<any>) => void,
								callbackFail: () => void) => {

	const { doctorName, filters, hospitalSeq, patientKeyword } = data;

	let url: string = `/${category}/patients/hospital/${hospitalSeq}?page=${page}&size=10`;
	filters.forEach(filter => {
		url += `&filters=${filter}`;
	});

	if (patientKeyword) {
		url += `&patientKeyword=${patientKeyword}`;
	}

	if (doctorName) {
		url += `&doctorName=${doctorName}`;
	}

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 환자 등록 API
export const saveNewPatient = (formData: PatientFormDataProps,
							   category: string,
							   callbackSuccess?: (res: AxiosResponse<any>) => void,
							   callbackFail?: () => void) => {

	const data = {
		...formData,
		drink: formData.drink === 'Y',
		height: Number(formData.height),
		smoke: formData.smoke === 'Y',
		staffSeq: Number(formData.staffSeq),
		weight: Number(formData.weight)
	}

	delete data.staffName;

	const config = setAxiosRequestConfig();

	axiosInstance
		.post(`/${category}/patients`, data, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}
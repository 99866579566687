import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { findASCVDDetail } from "@apis/patientDetail";
import { INITIAL_PAST_ASCVD_DETAIL } from "@constants/common";
import { PastAscvdDetailProps } from "@models/layoutTypes";
import { riskAssmtDateState } from "@models/recoilStates";
import {
	PastAscvdBIRI,
	PastAscvdBPIRI,
	PastAscvdPHI,
	PastAscvdPI,
	PastAscvdRAD,
	PastTestResult
} from "@molecules/PastAscvd";

interface Props {
	ascvdSeq: number
}

const PastDetail: React.FC<Props> = ({ ascvdSeq }) => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [ascvdData, setAscvdData] = useState<PastAscvdDetailProps>({ ...INITIAL_PAST_ASCVD_DETAIL, ascvdSeq });

	const getASCVDDetail = useRecoilCallback(({ reset, set }) => () => {
		if (patientSeq) {
			findASCVDDetail(patientSeq, ascvdSeq, (res) => {
				const { riskAssessmentDate, riskAssessmentResultInfo } = res.data;
				setAscvdData({
					...res.data,
					riskAssessmentResultInfo: {
						positive: riskAssessmentResultInfo.ascvdGettingRate,
						negative: riskAssessmentResultInfo.ascvdNotGettingRate
					}
				});
				set(riskAssmtDateState, riskAssessmentDate.split('T')[0]);
			}, () => {
				setAscvdData({ ...INITIAL_PAST_ASCVD_DETAIL, ascvdSeq });
				reset(riskAssmtDateState);
			});
		}
	}, [ascvdSeq, patientSeq]);

	useEffect(() => {
		getASCVDDetail();
	}, [getASCVDDetail]);

	return (
		<div className='past-ascvd-detail-wrapper'>
			<div className='past-ascvd-detail-item'>
				<h2>{t('patient.pi')}</h2>
				<PastAscvdPI {...ascvdData.patientInfo} />
			</div>
			<div className='past-ascvd-detail-item'>
				<h2>{t('patient.rad')}</h2>
				<PastAscvdRAD riskAssessmentDate={ascvdData.riskAssessmentDate} />
			</div>
			<div className='past-ascvd-detail-item'>
				<h2>{t('patient.phi')}</h2>
				<PastAscvdPHI {...ascvdData.patientHealthInfo} />
			</div>
			<div className='past-ascvd-detail-item'>
				<h2>{t('patient.bptr')}</h2>
				<PastAscvdBPIRI {...ascvdData.bloodPressureInspectResultInfo} />
			</div>
			<div className='past-ascvd-detail-item'>
				<h2>{t('patient.btr')}</h2>
				<PastAscvdBIRI {...ascvdData.bloodInspectResultInfo} />
			</div>
			<div className='past-ascvd-detail-item'>
				<h2>{t('patient.ascvdrar')}</h2>
				<PastTestResult {...ascvdData.riskAssessmentResultInfo} />
			</div>
		</div>
	);
};

export default PastDetail;
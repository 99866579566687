import React, { ReactNode } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { filterCheckedState, filterValuesState } from "@models/recoilStates";
import icRemoveFilterOption from '@styles/images/ic-remove-filter-option.png';

interface FilterItemProps {
	children: ReactNode;
	index: number;
}

const Item: React.FC<FilterItemProps> = ({ children, index }) => {
	const { t } = useTranslation();
	const [checkedArr, setCheckedArr] = useRecoilState(filterCheckedState);
	const [filterValueArr, setFilterValueArr] = useRecoilState(filterValuesState);

	const removeFilterOption = () => {
		// 체크 배열 업데이트
		const newCheckedArr: boolean[] = [...checkedArr];
		newCheckedArr[index] = false; // 해당 인덱스의 체크 상태 해제
		setCheckedArr(newCheckedArr);

		// 값 배열 업데이트
		const newFilterValueArr: string[] = filterValueArr.filter((_, i) => i !== index);
		setFilterValueArr(newFilterValueArr);
	}

	return (
		<div className='filter-item'>
			<span>{t(`patient.${children?.toString().toLowerCase()}`)}</span>
			<img src={icRemoveFilterOption} onClick={removeFilterOption} alt='remove filter option' />
		</div>
	);
};

export default Item;
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Logo } from "@atoms/Logo";
import { loggedInUserState, navMenuState } from "@models/recoilStates";
import { ProfileBox } from "@molecules/ProfileBox";
import dashboardBlack from '@styles/images/ic-dashboard-black.png';
import dashboardBlue from '@styles/images/ic-dashboard-blue.png';
import hospitalBlack from '@styles/images/ic-hospital-black.png';
import hospitalBlue from '@styles/images/ic-hospital-blue.png';
import patientBlack from '@styles/images/ic-people-black.png';
import patientBlue from '@styles/images/ic-people-blue.png';

const NavDefault = () => {
	const { t } = useTranslation();
	const menu = useRecoilValue(navMenuState);
	return (
		<li className={menu === 'hospital' ? 'on' : ''}>
			<img src={menu === 'hospital' ? hospitalBlue : hospitalBlack} alt='병원 아이콘' />
			<Link to='/hospital/list'>{t('hospital.title')}</Link>
		</li>
	);
}

const NavBPAI = () => {
	const { t } = useTranslation();
	const menu = useRecoilValue(navMenuState);
	return (
		<>
			<li className={menu === 'dashboard' ? 'on' : ''}>
				<img src={menu === 'dashboard' ? dashboardBlue : dashboardBlack} alt='대시보드 아이콘' />
				<Link to='/dashboard'>{t('dashboard.title')}</Link>
			</li>
			<li className={menu === 'patient' ? 'on' : ''}>
				<img src={menu === 'patient' ? patientBlue : patientBlack} alt='환자 아이콘' />
				<Link to='/patient/list'>{t('patient.title')}</Link>
			</li>
		</>
	);
}

const NavHCAI = () => {
	const { t } = useTranslation();
	const menu = useRecoilValue(navMenuState);
	return (
		<li className={menu === 'patient' ? 'on' : ''}>
			<img src={menu === 'patient' ? patientBlue : patientBlack} alt='환자 아이콘' />
			<Link to='/patient/list'>{t('patient.title')}</Link>
		</li>
	);
}

const Navi = () => {
	const { category, id, name } = useRecoilValue(loggedInUserState);

	const nav = useMemo(() => {
		switch (category) {
			case 'BPAI':
				return <NavBPAI />;
			case 'HCAI':
				return <NavHCAI />;
			default:
				return <NavDefault />;
		}
	}, [category]);

	return (
		<nav>
			<div>
				<Logo size='small' />
				<ul className='sidebar'>{nav}</ul>
			</div>
			<ProfileBox id={id} name={name} />
		</nav>
	);
};

export default Navi;
import axios from "axios";

const axiosInstance = axios.create({
	baseURL: window._env_.REACT_APP_CANOFY_API_URL || process.env.REACT_APP_CANOFY_API_URL
});

const t3qInstance = axios.create({
	baseURL: window._env_.REACT_APP_T3Q_PLATFORM_URL ||  process.env.REACT_APP_T3Q_PLATFORM_URL
});

export { axiosInstance, t3qInstance };
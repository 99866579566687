import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enMsgs from "./messages/messages_en.json";
import koMsgs from "./messages/messages_ko.json";

i18next
	.use(I18nextBrowserLanguageDetector) // 사용자 언어 탐지
	.use(initReactI18next) // i18n 객체를 react-i18next에 전달
	.init({ // for all options read: https://www.i18next.com/overview/configuration-options
		debug: true,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false
		},
		load: 'languageOnly',
		resources: {
			en: {
				translation: enMsgs
			},
			ko: {
				translation: koMsgs
			}
		}
	});
import React from 'react';
import { useRecoilValue } from "recoil";
import { loggedInUserState } from "@models/recoilStates";
import { Bpai, Hcai, Hdai, Scai } from "@molecules/LegalAndRegulatory";

const Lri = () => {
	const { category } = useRecoilValue(loggedInUserState);

	let content = null;

	switch (category) {
		case 'BPAI':
			content = <Bpai />;
			break;
		case 'HCAI':
			content = <Hcai />;
			break;
		case 'HDAI':
			content = <Hdai />;
			break;
		case 'SCAI':
			content = <Scai />;
			break;
	}

	return (
		<div className='lri'>
			<div>
				<div className='lri-info'>
					<h2>{category}</h2>
					{content}
				</div>
			</div>
		</div>
	);
};

export default Lri;
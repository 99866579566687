import React, { useMemo } from 'react';
import Select, { GroupBase, StylesConfig } from "react-select";
import { CustomOption } from "@atoms/CustomOption";
import { DropdownIndicator } from "@atoms/DropdownIndicator";
import { SelectBoxOptionProps, SelectBoxProps } from "@models/optionProps";

const SelectBox: React.FC<SelectBoxProps> = ({ className,
												isFixed = true,
												name,
												onChange,
												options,
												placeholder,
												value }) => {

	const commonStyles: StylesConfig<SelectBoxOptionProps, false, GroupBase<SelectBoxOptionProps>> = useMemo(() => ({
		indicatorSeparator: () => ({
			display: 'none'
		}),
		menu: (baseStyles) => ({
			...baseStyles,
			padding: 8,
			zIndex: 9999
		}),
		option: (baseStyles, { isFocused }) => ({
			...baseStyles,
			backgroundColor: isFocused ? '#f7f9ff' : '#fff',
			borderRadius: 4,
			color: '#191919',
			fontSize: 14,
			fontWeight: '500',
			lineHeight: '20px',
			textAlign: 'left'
		}),
	}), []);

	// 클래스 있을 때 스타일
	const stylesWithClassName: StylesConfig<SelectBoxOptionProps, false, GroupBase<SelectBoxOptionProps>> = useMemo(() => ({
		...commonStyles,
		control: (baseStyles) => ({
			...baseStyles,
			border: 'none !important',
			boxShadow: 'none',
			cursor: 'pointer',
			fontSize: 14,
			height: 24
		}),
		singleValue: (baseStyles) => ({
			...baseStyles,
			lineHeight: '20px',
			textAlign: 'left'
		})
	}), [commonStyles]);

	// 클래스 없을 때 스타일
	const stylesWithoutClassName: StylesConfig<SelectBoxOptionProps, false, GroupBase<SelectBoxOptionProps>> = useMemo(() => ({
		...commonStyles,
		container: (baseStyles) => ({
			...baseStyles,
			minWidth: 120
		}),
		control: (baseStyles) => ({
			...baseStyles,
			borderColor: '#e0e0e0 !important',
			boxShadow: 'none',
			fontSize: 14,
			height: 42
		}),
		placeholder: (baseStyles) => ({
			...baseStyles,
			color: '#c0c0c0'
		}),
		singleValue: (baseStyles) => ({
			...baseStyles,
			textAlign: 'left'
		})
	}), [commonStyles]);

	return (
		<Select
			className={className}
			components={{ Option: CustomOption, DropdownIndicator }}
			isSearchable={false}
			menuPlacement='auto'
			menuPortalTarget={document.getElementsByClassName('dialog-base')[0] as HTMLElement}
			menuPosition={isFixed ? 'fixed' : 'absolute'}
			name={name}
			onChange={(option) => onChange(option?.value)}
			onInputChange={() => {}}
			onMenuClose={() => {}}
			onMenuOpen={() => {}}
			options={options}
			placeholder={placeholder}
			styles={className ? stylesWithClassName : stylesWithoutClassName}
			value={value}
		/>
	);
};

export default SelectBox;
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findBPDetail } from "@apis/patientDetail";
import { formatFullDate } from "@logics/common";
import { ColGroup } from "@molecules/ColGroup";

interface BP {
	systolic: number;
	diastolic: number;
	pulse: number;
}

interface EntityProps {
	firstBP: BP;
	secondBP: BP;
	measureTime: string;
	memo: string | null;
}

const Detail = () => {
	const { t } = useTranslation();
	const { patientSeq } = useParams();
	const [entries, setEntries] = useState<EntityProps[]>([]);

	const bpFirst: string = useMemo(() => t('patient.bpfirst'), [t]);
	const bpSecond: string = useMemo(() => t('patient.bpsecond'), [t]);

	useEffect(() => {
		if (patientSeq) {
			findBPDetail(patientSeq, (res) => {
				setEntries(res.data.entity);
			}, () => setEntries([]));
		}
	}, [patientSeq]);

	return (
		<table className='bp-detail-table'>
			<ColGroup widths={['22%', '18%', '25%', '17%', '18%']} />
			<thead className='bp-detail-table-header'>
				<tr>
					<th>{t('patient.dom')}</th>
					<th className='gray'>{t('patient.bpsession')}</th>
					<th>{t('patient.sdbp')}</th>
					<th>{t('patient.pulse')}</th>
					<th>{t('patient.symptom')}</th>
				</tr>
			</thead>
			<tbody className='bp-detail-table-body'>
				{entries.length === 0
					? <tr><td colSpan={5}>{t('patient.nodata')}</td></tr>
					: <>
						{entries.map(({ firstBP, secondBP, measureTime, memo }: EntityProps, index: number) => (
							<Fragment key={index}>
								<tr>
									<td rowSpan={2}>{formatFullDate(measureTime)}</td>
									<td className='gray'>{bpFirst}</td>
									<td>{firstBP.systolic}/{firstBP.diastolic}</td>
									<td>{firstBP.pulse}</td>
									<td rowSpan={2}>{memo || '-'}</td>
								</tr>
								<tr>
									<td className='gray'>{bpSecond}</td>
									<td>{secondBP.systolic}/{secondBP.diastolic}</td>
									<td>{secondBP.pulse}</td>
								</tr>
							</Fragment>
						))}
					</>
				}
			</tbody>
		</table>
	);
};

export default Detail;
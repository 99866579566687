import React from 'react';
import { useTranslation } from "react-i18next";

interface Props {
	riskAssessmentDate: string;
}

const RiskAssessmentDate: React.FC<Props> = ({ riskAssessmentDate }) => {
	const { t } = useTranslation();

	return (
		<table>
			<colgroup>
				<col width='16.66%' />
				<col width='*' />
			</colgroup>
			<tbody>
				<tr>
					<th>{t('patient.date')}</th>
					<td>{riskAssessmentDate.split('T')[0]}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default RiskAssessmentDate;
import React from 'react';
import { useTranslation } from "react-i18next";
import { BIRI, ColGroupProps } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";
import { BiriTbody } from "@molecules/HcaiTbody";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const BloodInspectResultInfo: React.FC<BIRI> = (props) => {
	const { t } = useTranslation();

	return (
		<div className='hc-result-col'>
			<h3>{t('patient.btr')}</h3>
			<table className='hc-result-table'>
				<ColGroup {...widths} />
				<BiriTbody {...props} />
			</table>
		</div>
	);
};

export default BloodInspectResultInfo;
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { AscvdResult } from "@models/layoutTypes";
import { AboutAscvd } from "@molecules/AboutAscvd";
import { AscvdGraph } from "@molecules/Graph";

const TestResult: React.FC<AscvdResult> = ({ positive, negative }) => {
	const { t } = useTranslation();

	const positiveRate = useMemo(() => positive * 100, [positive]);
	const negativeRate = useMemo(() => negative * 100, [negative]);

	return (
		<div className='past-ascvd-test-result'>
			<div className='ascvd-graph-body'>
				<AscvdGraph chartHeight={250} positive={positiveRate} />
				<div className='ascvd-graph-legend'>
					<div className='ascvd-graph-legend-item positive'>
						<span>{t('patient.positive')}</span>
						<strong>{positiveRate.toFixed(1)}%</strong>
					</div>
					<div className='ascvd-graph-legend-item border' />
					<div className='ascvd-graph-legend-item negative'>
						<span>{t('patient.negative')}</span>
						<strong>{negativeRate.toFixed(1)}%</strong>
					</div>
				</div>
			</div>
			<AboutAscvd containerStyle={{ padding: 40 }} />
		</div>
	);
};

export default TestResult;
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getCookie, setCookie } from "typescript-cookie";
import { clientTokenGeneration, t3qLogout } from "@apis/t3q";
import { Button } from "@atoms/Button";
import { useResetAllRecoilStates } from "@hooks/useResetAllRecoilStates";
import { profileButtonsState } from "@models/recoilStates";

const ProfileButtons = forwardRef<HTMLDivElement>((_, ref) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const profileBtns = useRecoilValue(profileButtonsState);

	const resetAll = useResetAllRecoilStates();

	const t3qLogoutProcess = (uuid: string) => {
		clientTokenGeneration((res) => {
			t3qLogout(res.data.access_token, uuid);
		});
	}

	const logout = useCallback(() => {
		const t3qUserUuid = getCookie('userUuid');
		if (t3qUserUuid) {
			setCookie('access_token', '');
			setCookie('refresh_token', '');
			setCookie('userUuid', '');
			t3qLogoutProcess(t3qUserUuid);
		}

		setCookie('token', '');
		resetAll();

		setTimeout(() => {
			navigate('/');
		}, 300);
	}, [navigate, resetAll]);

	return (
		<div ref={ref} className={`profile-buttons ${profileBtns ? 'flex' : ''}`}>
			<Button onClick={() => navigate('/profile')}>{t('profile.ei')}</Button>
			<Button onClick={logout}>{t('login.logout')}</Button>
		</div>
	);
});

export default ProfileButtons;
import { useCallback } from "react";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { setCookie } from "typescript-cookie";
import { clientTokenGeneration, t3qLogout, tokenIntrospect } from "@apis/t3q";
import { navigateAfterLogin } from "@logics/common";
import { aiModelState } from "@models/recoilStates";

export const useT3QHooks = () => {
	const navigate = useNavigate();

	const setAIModel = useSetRecoilState(aiModelState);

	// 토큰 검증
	const tokenVerification = useCallback((at: any, rt: any) => {
		tokenIntrospect(at, (res) => {
			const token = res.data.accessToken;
			if (token) { // 유효한 토큰
				setCookie('access_token', at);
				setCookie('refresh_token', rt);
				setCookie('token', token);
				setCookie('userUuid', res.data.userUuid);
				navigateAfterLogin(navigate, setAIModel, token);
			} else {
				alert('사용자 인증에 실패하였습니다. 관리자에게 문의해주세요.');
			}
		}, (error) => {
			const code: number = error.response.data.code;
			if (code === 1205) {
				alert('사용자 인증에 실패하였습니다. 관리자에게 문의해주세요.');
			} else if (code === 3014) {
				alert('존재하지 않는 사용자입니다.');
				const uuid: string = error.response.data.body.data.sub;
				clientTokenGeneration(r => {
					t3qLogout(r.data.access_token, uuid, () => navigate('/'));
				});
			} else {
				alert('로그인에 실패하였습니다. 관리자에게 문의해주세요.');
			}
		});
	}, [navigate, setAIModel]);

	// 토큰 생성 성공
	const tokenGenerated = useCallback((res: AxiosResponse<any, any>) => {
		const accessToken = res.data.access_token;
		const refreshToken = res.data.refresh_token;

		// 토큰 검증
		tokenVerification(accessToken, refreshToken);
	}, [tokenVerification]);

	// 토큰 생성 실패
	const tokenNotGenerated = useCallback(() => {
		setCookie('access_token', '');
		setCookie('refresh_token', '');
		navigate("/t3q");
	}, [navigate]);

	return { tokenGenerated, tokenNotGenerated, tokenVerification };
}
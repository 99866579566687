import React from 'react';
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Root from "@/Root";
import { Dashboard } from "@pages/Dashboard";
import { HospitalDetail, HospitalList } from "@pages/Hospital";
import { AscvdResult, BPDetails, BPPredictResult, PatientDetail, PatientList } from '@pages/Patient';
import { Profile } from "@pages/Profile";
import { Signin } from "@pages/Signin";
import { T3Q } from "@pages/T3Q";

declare global {
	interface Window {
		_env_: any;
	}
}

const App = () => {
	const { t } = useTranslation();
	return (
		<RecoilRoot>
			<BrowserRouter>
				<Routes>
					<Route path='' element={<Root />} />

					<Route path='/t3q' element={<T3Q />} />

					<Route path='/auth/signin' element={<Signin />} />

					<Route path='/dashboard' element={<Dashboard pageTitle={t('dashboard.title')} menu='dashboard' isListPage={false} />} />

					<Route path='/profile' element={<Profile pageTitle={t('profile.title')} isListPage={false} />} />

					<Route path='/patient/list' element={<PatientList pageTitle={t('patient.title')} menu='patient' isListPage />} />
					<Route path='/patient/:patientSeq' element={<PatientDetail grayTitle={`${t('patient.title')} / `} pageTitle={t('patient.details')} menu='patient' isListPage={false} />} />
					<Route path='/patient/:patientSeq/ascvd/:ascvdSeq' element={<AscvdResult />} />
					<Route path='/patient/:patientSeq/bp' element={<BPDetails />} />
					<Route path='/patient/:patientSeq/predict/result' element={<BPPredictResult isBit={false} />} />

					<Route path='/bit/patient/:patientSeq/predict/result' element={<BPPredictResult isBit />} />

					<Route path='/hospital/list' element={<HospitalList pageTitle={t('hospital.title')} menu='hospital' isListPage />} />
					<Route path='/hospital/:hospitalSeq' element={<HospitalDetail grayTitle={`${t('hospital.title')} / `} pageTitle={t('hospital.details')} menu='hospital' isListPage={false} />} />
				</Routes>
			</BrowserRouter>
		</RecoilRoot>
	);
};

export default App;
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { findHospitalUserDetail } from "@apis/hospitalDetail";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { DialogContentProps } from "@models/layoutTypes";
import { dialogIDRecoilState, isSubmitButtonDisabledState } from "@models/recoilStates";
import { DialogButtons, ModifyPasswordForm } from "@molecules/ModifyPassword";
import { UserDetailDialogButtons, UserDetailDialogContents } from "@molecules/UserDetailDialog";
import { UserForm } from "@molecules/UserForm";

const userDetailDialogID: string = 'user-detail-dialog';

const Buttons: React.FC<{ userSeq: number }> = ({ userSeq }) => {
	const { closeDialog, openDialog } = useDialog();
	const { hospitalSeq } = useParams();
	const { t } = useTranslation();

	const setDialogID = useSetRecoilState(dialogIDRecoilState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	const userDetailDialogSet: DialogContentProps = useMemo(() => ({
		dialogTitle: t('hospital.ud'),
		dialogContents: <UserDetailDialogContents userSeq={userSeq} />,
		dialogButtons: <UserDetailDialogButtons userSeq={userSeq} />
	}), [t, userSeq]);

	const openModifyUserDialog = () => {
		const dialogId: string = 'modify-user-dialog';
		setDialogID(dialogId);
		setIsSubmitButtonDisabled(false);
		findHospitalUserDetail(userSeq, (res) => {
			openDialog({
				dialogTitle: t('hospital.eui'),
				dialogContents: <UserForm type='modify' userData={{ ...res.data, hospitalSeq: Number(hospitalSeq || 0) }} />,
				dialogButtons: <DialogButtons dialogId={dialogId} submitButtonText={t('hospital.dhksfy')}
											  prevDialog={userDetailDialogSet}
											  prevDialogID={userDetailDialogID} />
			});
		}, () => {});
	}

	const openModifyPasswordDialog = () => {
		setIsSubmitButtonDisabled(true);
		const dialogId: string = 'modify-password-dialog';
		setDialogID(dialogId);
		openDialog({
			dialogTitle: t('profile.cp'),
			dialogContents: <ModifyPasswordForm passwordOwner='staff' />,
			dialogButtons: <DialogButtons dialogId={dialogId} submitButtonText={t('hospital.dhksfy')}
										  prevDialog={userDetailDialogSet}
										  prevDialogID={userDetailDialogID} />
		});
	}

	return (
		<>
			<Button className='white' onClick={openModifyUserDialog}>{t('hospital.edit')}</Button>
			<Button className='white' onClick={openModifyPasswordDialog}>{t('profile.cp')}</Button>
			<Button className='blue' onClick={() => closeDialog(userDetailDialogID)}>{t('dashboard.ok')}</Button>
		</>
	);
};

export default Buttons;
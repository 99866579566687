import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { setCookie } from "typescript-cookie";
import { login } from "@apis/login";
import { tokenGeneration } from "@apis/t3q";
import { Button } from "@atoms/Button";
import { Input } from "@atoms/Input";
import { Logo } from "@atoms/Logo";
import { useDialog } from "@hooks/useDialog";
import { useT3QHooks } from "@hooks/useT3QHooks";
import { navigateAfterLogin } from "@logics/common";
import { aiModelState, dialogIDRecoilState, dialogRecoilState } from "@models/recoilStates";
import { Dialog } from "@molecules/Dialog";
import { Footer } from "@molecules/Footer";

const Signin = () => {
	const { closeDialog, openDialog } = useDialog();
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const code = params.get('code');
	const { t } = useTranslation();
	const { tokenGenerated, tokenNotGenerated } = useT3QHooks();

	const [form, setForm] = useState({
		id: '',
		password: ''
	});

	const dialogState = useRecoilValue(dialogRecoilState);

	const setAIModel = useSetRecoilState(aiModelState);

	useEffect(() => {
		if (code) {
			// t3q 토큰 발급
			tokenGeneration(code, window.location.origin, tokenGenerated, tokenNotGenerated);
		} else {
			const { hostname } = window.location;
			switch (hostname) {
				case 'canofy.md': // 실서버
				case 'daw.doctorcall.global': // 스테이징
				case 'localhost': // 로컬 호스트
					break;
				default:
					navigate('/t3q');
					break;
			}
		}
	}, [code, navigate, tokenGenerated, tokenNotGenerated]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setForm({ ...form, [name]: value });
	}

	const handleSubmit = useRecoilCallback(({ set }) => (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const { id, password } = form;
		if (id && password) {
			login(new FormData(e.currentTarget),
				res => {
					const token: string = res.data.accessToken;
					setCookie('token', token);
					navigateAfterLogin(navigate, setAIModel, token);
				},
				() => {
					console.log('Login failed - Invalid ID or password');
					const dialogId: string = 'unauthorized-dialog';
					set(dialogIDRecoilState, dialogId);
					openDialog({
						dialogClass: 'untitled',
						dialogContents: t('login.eopnv'),
						dialogButtons: <Button className='blue' onClick={() => closeDialog(dialogId)}>{t('login.accept')}</Button>
					});
				}
			);
		} else {
			console.log('Login failed - ID or password not provided');
			const dialogId: string = 'empty-id-password-dialog';
			set(dialogIDRecoilState, dialogId);
			openDialog({
				dialogClass: 'untitled',
				dialogContents: t('login.peeop'),
				dialogButtons: <Button className='blue' onClick={() => closeDialog(dialogId)}>{t('login.accept')}</Button>
			});
		}
	}, [closeDialog, form, navigate, openDialog, setAIModel, t]);

	return (
		<div className='wrap signin'>
			<main className='signin-box'>
				<Logo size='big' />
				<form method='POST' onSubmit={handleSubmit}>
					<label className="input-grp">
						<p>{t('login.id')}</p>
						<Input name="id" type="text" placeholder={t('login.peid')} value={form.id} onChange={handleChange} />
					</label>
					<label className="input-grp">
						<p>{t('login.password')}</p>
						<Input name="password" type="password" placeholder={t('login.pepw')} value={form.password} onChange={handleChange} autoComplete="off" />
					</label>
					<Button type='submit'>{t('login.login')}</Button>
				</form>
			</main>
			<Footer />
			<Dialog {...dialogState} />
		</div>
	);
};

export default Signin;
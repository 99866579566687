import React from 'react';
import { TrendDown, TrendStay, TrendUp } from '.';
import { DSU } from "@models/layoutTypes";

interface TrendProps {
	trend: DSU;
}

const Trend: React.FC<TrendProps> = ({ trend }) => {
	switch (trend) {
		case 'DOWN':
			return <TrendDown />;
		case 'STAY':
			return <TrendStay />;
		case 'UP':
			return <TrendUp />;
	}
};

export default Trend;
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findBPHistoryByPeriod } from "@apis/patientDetail";
import { BP_GRAPH_DATA } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import {
	bpTooltipOptions,
	bpXAxis,
	bpYAxis,
	handleMouseOut,
	handleMouseOver,
	onlyOneNonNull,
	setGraphDataByRes
} from "@logics/graph";
import { BPGraphProps } from "@models/layoutTypes";

function setChartOptions(t: TFunction<"translation", undefined>, { bpArr, pulseArr, xCategories }: BPGraphProps) {
	const result: Highcharts.Options = {
		chart: {
			height: 290,
			reflow: true
		},
		credits: {
			enabled: false
		},
		title: {
			text: undefined
		},
		legend: {
			enabled: true
		},
		xAxis: bpXAxis(xCategories),
		yAxis: bpYAxis(6),
		tooltip: bpTooltipOptions,
		plotOptions: {
			series: {
				connectNulls: true,
				point: {
					events: {
						mouseOver: handleMouseOver,
						mouseOut: handleMouseOut
					}
				}
			}
		},
		series: [{
			color: '#f24949',
			data: bpArr,
			lineWidth: 2,
			name: t('patient.bpavg'),
			pointWidth: 20,
			showInLegend: true,
			type: 'errorbar',
			zIndex: 2
		}, {
			color: '#1b59f8',
			data: pulseArr,
			lineWidth: 3,
			marker: {
				enabled: onlyOneNonNull(pulseArr),
				states: {
					hover: {
						enabled: true,
						fillColor: '#fff',
						lineColor: '#1b59f8',
						lineWidth: 4,
						radius: 8
					}
				},
				symbol: 'circle'
			},
			name: t('patient.pulseavg'),
			type: 'spline',
			zIndex: 1
		}]
	}
	return result;
}

const BpSummary: React.FC<HighchartsReact.Props> = (props) => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [graphData, setGraphData] = useState<BPGraphProps>(BP_GRAPH_DATA);
	const [options, setOptions] = useState<Highcharts.Options>({});

	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	useEffect(() => {
		if (patientSeq) {
			findBPHistoryByPeriod(patientSeq, props.isBit, props.from, props.to, (res) => {
				setGraphDataByRes(props.from, props.to, res, setGraphData);
			});
		}
	}, [patientSeq, props.from, props.isBit, props.to]);

	useEffectAfterMount(() => {
		setOptions(setChartOptions(t, graphData));
	}, [graphData, t]);

	return (
		<div>
			<h2>{t('patient.bptrend')}</h2>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
				ref={chartComponentRef}
				{...props}
			/>
		</div>
	);
};

export default BpSummary;
import React from 'react';
// import { useTranslation } from "react-i18next";
// import { useRecoilValue } from "recoil";
import { Button } from "@atoms/Button";
import { SearchInputProps } from "@models/layoutTypes";
// import { aiModelState } from "@models/recoilStates";
import { SearchBox } from "@organisms/SearchBox";
import add from '@styles/images/add.png';

interface WhiteTopBoxProps {
	addBtnText: string;
	openAddDialog: () => void;
	searchboxes: SearchInputProps[];
}

const White: React.FC<WhiteTopBoxProps> = ({ addBtnText, openAddDialog, searchboxes }) => {
/*
	const { t } = useTranslation();

	const aiModel = useRecoilValue(aiModelState);
*/

	return (
		<div className='white-top-box'>
			<SearchBox searchboxes={searchboxes} />
			<div style={{ display: "flex", gap: 10 }}>
				{/* TODO 화면 기획 나오면 주석 해제 */}
				{/*{aiModel === 'HCAI' && <Button className='white' style={{ padding: '0 15px' }}>{t('patient.load')}</Button>}*/}
				<Button className='blue add-button' onClick={openAddDialog}>
					<img src={add} alt='add' />
					<span>{addBtnText}</span>
				</Button>
			</div>
		</div>
	);
};

export default White;
import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { getCookie } from "typescript-cookie";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { decodeBase64 } from "@logics/common";
import { HeaderProps, ComponentProps } from "@models/layoutTypes";
import { aiModelState, loggedInUserState, navMenuState, profileButtonsState } from "@models/recoilStates";
import { ProfileButtons } from "@molecules/ProfileBox";
import { Header } from "@organisms/Header";
import { Navi } from "@organisms/Navi";

const DefaultLayout: React.FC<HeaderProps & ComponentProps> = ({ children, grayTitle, isListPage, menu, pageTitle }) => {
	const navigate = useNavigate();

	const profileBtns = useRecoilValue(profileButtonsState);

	const setNavMenu = useSetRecoilState(navMenuState);

	const pbtnRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = useRecoilCallback(({ set }) => (e: MouseEvent) => {
		if (pbtnRef.current && !pbtnRef.current.contains(e.target as Node)) {
			set(profileButtonsState, false);
		}
	}, []);

	const processByToken = useRecoilCallback(({ set }) => () => {
		const token = getCookie('token');
		if (!token) {
			navigate('/auth/signin');
		} else {
			const user = JSON.parse(decodeBase64(token.split('.')[1]));
			set(aiModelState, user.category);
			set(loggedInUserState, user);
			set(profileButtonsState, false);
		}
	}, [navigate]);

	useEffect(() => {
		setNavMenu(menu);
	}, [menu, setNavMenu]);

	useEffect(() => {
		processByToken();
	}, [processByToken]);

	useEffectAfterMount(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		}
	}, [profileBtns]);

	return (
		<div className='wrap'>
			<Navi />
			<ProfileButtons ref={pbtnRef} />
			<main>
				<Header grayTitle={grayTitle} pageTitle={pageTitle} isListPage={isListPage} />
				{children}
			</main>
		</div>
	);
};

export default DefaultLayout;
import { AxiosResponse } from "axios";
import { axiosInstance } from "@/axiosInstance";
import { setAxiosRequestConfig } from "@logics/common";
import { HospitalFormDataProps, T3QHospitalFormDataProps } from "@models/layoutTypes";

// 병원 목록 조회 API
export const findHospitalList = (keyword: string | undefined,
								 page: number = 0,
								 callbackSuccess: (res: AxiosResponse<any>) => void,
								 callbackFail: () => void) => {

	let url: string = `/hospitals?page=${page}&size=10`;
	if (keyword) {
		url += `&keyword=${keyword}`;
	}

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 병원 등록 API
export const saveHospital = (requestBody: HospitalFormDataProps | T3QHospitalFormDataProps,
							 callbackSuccess: (res: AxiosResponse<any>) => void,
							 callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.post('/hospitals', requestBody, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 병원 수정 API
export const modifyHospital = (requestBody: HospitalFormDataProps | T3QHospitalFormDataProps,
							   callbackSuccess: (res: AxiosResponse<any>) => void,
							   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.patch('/hospitals', requestBody, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}
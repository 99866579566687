import React, { useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import { Button } from "@atoms/Button";
import { useDownloadPDF } from "@hooks/useDownloadPDF";
import { formatDateRange } from "@logics/common";
import { IsBitProps } from "@models/layoutTypes";
import { BpSummaryGraph } from "@molecules/Graph";
import {
	BloodPressureChange,
	BloodPressurePrediction,
	BloodPressurePredictionResult
} from "@organisms/BloodPressure";
import { PatientReportInfo } from "@organisms/PatientInfo";
import canofyLogo from "@styles/images/img_small_logo.png";

const fromTo: string[] = formatDateRange(new Date(), 30).split(' - ');

const PredictResult: React.FC<IsBitProps> = ({ isBit }) => {
	const { pageRef, excludeRef, downloadPDF } = useDownloadPDF();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const bpcProps = useMemo(() => ({
		className: 'paddingless',
		isBit,
		isReport: true,
		moreBtn: false,
		reverse: false,
		toggle: false
	}), [isBit]);

	useEffect(() => {
		if (!isBit) {
			const token = getCookie('token');
			if (!token) {
				navigate('/auth/signin');
			}
		}
	}, [isBit, navigate]);

	return (
		<div className='report-wrap' ref={pageRef}>
			<header>
				<h1>{t('patient.bpr')}</h1>
				<div>
					<img src={canofyLogo} alt='canofy logo' />
				</div>
			</header>
			<main style={{ gap: 25 }}>
				<PatientReportInfo isBit={isBit} />
				<BpSummaryGraph isBit={isBit}
					from={fromTo[0].replaceAll('.', '-')}
					to={fromTo[1].replaceAll('.', '-')}
				/>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
					<BloodPressureChange {...bpcProps} rows={8} title unit='WEEK' />
					<BloodPressureChange {...bpcProps} rows={2} title={false} unit='MONTH' />
				</div>
				<BloodPressurePrediction isBit={isBit} />
				{/* TODO 삭제하지 말고 일단 주석 */}
				{/*<BloodPressurePredictionResult isBit={isBit} />*/}
				<Button
					className='blue'
					style={{ padding: '10px 16px' }}
					ref={excludeRef}
					onClick={() => downloadPDF('report')}
				>{t('patient.downloadReport')}</Button>
			</main>
		</div>
	);
};

export default PredictResult;
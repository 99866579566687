import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findPatientPersonalInfo } from "@apis/patientDetail";
import { INITIAL_DATE_STRING_HYPHEN, INITIAL_PATIENT_REPORT_DATA } from "@constants/common";
import { IsBitProps } from "@models/layoutTypes";

const Report: React.FC<IsBitProps> = ({ isBit }) => {
	const { t } = useTranslation();
	const { patientSeq } = useParams();
	const [patientData, setPatientData] = useState(INITIAL_PATIENT_REPORT_DATA);

	useEffect(() => {
		if (patientSeq) {
			findPatientPersonalInfo(patientSeq, 'bpai', isBit,
				res => setPatientData(res.data),
				() => setPatientData(INITIAL_PATIENT_REPORT_DATA));
		}
	}, [isBit, patientSeq]);

	return (
		<ul className='patient-info'>
			<li>
				<p>{t('patient.name')}</p>
				<p>{patientData.name}</p>
			</li>
			<li>
				<p>{t('dashboard.gender')}</p>
				<p>{t(`patient.${patientData.gender.toLowerCase()}`)}</p>
			</li>
			<li>
				<p>{t('patient.dob')}</p>
				<p>{patientData.birthDate}</p>
			</li>
			<li>
				<p>{t('patient.height')}/{t('patient.weight')}</p>
				<p>{patientData.height}cm/{patientData.weight}kg</p>
			</li>
			<li>
				<p>{t('patient.pd')}</p>
				<p>{INITIAL_DATE_STRING_HYPHEN}</p>
			</li>
		</ul>
	);
};

export default Report;
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { findPatientDetail, findPatientPersonalInfo } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { INITIAL_PATIENT_FORM_DATA } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { patientText } from "@logics/common";
import { ColGroupProps, PatientFormDataProps } from "@models/layoutTypes";
import { dialogIDRecoilState, hcaiPersonalInfoState, isSubmitButtonDisabledState } from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { DialogButtons } from "@molecules/ModifyPassword";
import { PatientForm } from "@molecules/PatientForm";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const HcaiBasic = () => {
	const { openDialog } = useDialog();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [patientData, setPatientData] = useState<PatientFormDataProps>(INITIAL_PATIENT_FORM_DATA);
	const [personalInfo, setPersonalInfo] = useRecoilState(hcaiPersonalInfoState);

	const setDialogID = useSetRecoilState(dialogIDRecoilState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	useEffect(() => {
		if (patientSeq) {
			// 환자 인적사항 세팅
			findPatientPersonalInfo(patientSeq, 'hcai', false, res => setPersonalInfo(res.data), () => {});
		}
	}, [patientSeq, setPersonalInfo]);

	useEffectAfterMount(() => {
		// 환자 수정 팝업에 들어갈 정보 세팅
		findPatientDetail(patientSeq, 'hcai',
			res => setPatientData({
				...res.data,
				hcaiMemo: personalInfo.hcaiMemo,
				staffName: personalInfo.staffName,
				staffSeq: personalInfo.staffSeq
			}),
			() => {
				const { birthDate, contact, gender, hcaiMemo, name, staffName, staffSeq } = personalInfo;
				setPatientData({
					birthDate,
					contact,
					gender,
					hcaiMemo,
					name,
					staffName,
					staffSeq
				});
			}
		);
	}, [personalInfo]);

	// 환자 수정 팝업 열기
	const openModifyPatientDialog = () => {
		setIsSubmitButtonDisabled(false);
		setDialogID('modify-patient-dialog');
		openDialog({
			dialogTitle: t('patient.epi'),
			dialogContents: <PatientForm type='modify' patientData={patientData} />,
			dialogButtons: <DialogButtons dialogId='modify-patient-dialog' submitButtonText={t('hospital.dhksfy')} />
		});
	}

	return (
		<table className='basic-info' style={{ tableLayout: 'fixed' }}>
			<ColGroup {...widths} />
			<tbody>
				<tr>
					<td colSpan={3} style={{ verticalAlign: 'middle' }}>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<h2>{personalInfo.name}</h2>
						</div>
					</td>
					<td colSpan={3}>
						<div style={{ display: 'flex', justifyContent: 'right', gap: 10 }}>
							<Button className='white' onClick={openModifyPatientDialog}>{t('patient.epi')}</Button>
						</div>
					</td>
				</tr>
				<tr>
					<th>{t('dashboard.gender')}</th>
					<td>{patientText(personalInfo.gender)}</td>
					<th>{t('patient.dob')}</th>
					<td>{personalInfo.birthDate}</td>
					<th>{t('patient.contact')}</th>
					<td>{personalInfo.contact}</td>
				</tr>
				<tr>
					<th>{t('patient.ap')}</th>
					<td>{personalInfo.staffName}</td>
					<th>{t('patient.rd')}</th>
					<td>{personalInfo.createdDate}</td>
					<th>{t('patient.memo')}</th>
					<td style={{ overflowWrap: 'break-word', whiteSpace: 'normal', wordBreak: 'keep-all' }}>{personalInfo.hcaiMemo || t('patient.nomemo')}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default HcaiBasic;
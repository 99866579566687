import { AxiosResponse } from "axios";
import { axiosInstance } from "@/axiosInstance";

// 로그인 API
export const login = (formData: FormData, callbackSuccess: (res: AxiosResponse<any>) => void, callbackFail: () => void) => {
	const formDataObj = Object.fromEntries(formData.entries());
	axiosInstance
		.post('/auth/staff', formDataObj)
		.then(callbackSuccess)
		.catch(callbackFail);
}
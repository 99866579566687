import React from 'react';
import { useTranslation } from "react-i18next";
import { ColGroupProps, PHI } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const PatientHealthInfo: React.FC<PHI> = ({ bmi = 0, diabetes, hbpMedicine,
											height = 0, smoke, weight = 0 }) => {

	const { t } = useTranslation();

	return (
		<table>
			<ColGroup {...widths} />
			<tbody>
				<tr>
					<th>{t('patient.height')}</th>
					<td>{height} cm</td>
					<th>{t('patient.weight')}</th>
					<td>{weight} kg</td>
					<th>BMI</th>
					<td>{bmi} kg/m²</td>
				</tr>
				<tr>
					<th>{t('patient.smkstatus')}</th>
					<td>{smoke ? 'Y' : 'N'}</td>
					<th>{t('patient.diabetesstatus')}</th>
					<td>{diabetes ? 'Y' : 'N'}</td>
					<th>{t('patient.thtnm')}</th>
					<td>{hbpMedicine ? 'Y' : 'N'}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default PatientHealthInfo;
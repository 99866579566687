import React from 'react';
import { useRecoilValue } from "recoil";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { dialogIDRecoilState } from "@models/recoilStates";
import icDialogClose from '@styles/images/ic-pop-close.svg';

interface BasicDialogProps {
	dialogButtons?: JSX.Element;
	dialogContents: JSX.Element | string;
	title: string;
}

const Basic: React.FC<BasicDialogProps> = ({ dialogButtons, dialogContents, title }) => {
	const id: string = useRecoilValue(dialogIDRecoilState);
	const { closeDialog } = useDialog();

	return (
		<>
			<div className="dialog-header">
				<h1>{title}</h1>
				<Button onClick={() => closeDialog(id)}>
					<img src={icDialogClose} alt="close" />
				</Button>
			</div>
			<div className="dialog-contents">{dialogContents}</div>
			{dialogButtons && <div className="btns">{dialogButtons}</div>}
		</>
	);
};

export default Basic;
import React, { useMemo, useRef } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from "highcharts/modules/accessibility";
import { useTranslation } from "react-i18next";
import { axisStyle } from "@logics/graph";
import { DashboardColumnGraphProps } from "@models/layoutTypes";
highchartsAccessibility(Highcharts); // init module

const Column: React.FC<DashboardColumnGraphProps & HighchartsReact.Props> = (graphData: DashboardColumnGraphProps, props: HighchartsReact.Props) => {
	const { t } = useTranslation();
	const dailyMeasurement: string = useMemo(() => t('dashboard.dm'), [t]);
	const options: Highcharts.Options = useMemo(() => ({
		chart: {
			height: 260,
			reflow: true
		},
		credits: {
			enabled: false
		},
		title: {
			text: undefined
		},
		legend: {
			enabled: false
		},
		xAxis: {
			categories: graphData.xCategories,
			labels: {
				style: axisStyle
			},
			lineColor: '#e0e0e0'
		},
		yAxis: {
			allowDecimals: false, // y축을 정수로만 설정
			gridLineColor: '#e0e0e0',
			labels: {
				style: axisStyle
			},
			min: 0,
			tickAmount: 6,
			title: {
				text: undefined
			}
		},
		series: [{
			borderRadius: 7,
			color: '#1e4bfa',
			data: graphData.values,
			name: dailyMeasurement,
			pointWidth: 30,
			type: 'column'
		}]
	}), [dailyMeasurement, graphData]);

	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
			ref={chartComponentRef}
			{...props}
		/>
	);
};

export default Column;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { deleteAscvdTestResult, findPastAscvds } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import {
	apiFunctionState, ascvdGraphLegendStyleState, ascvdResultChangeState, ascvdResultState, ascvdTestResultExistState,
	dialogIDRecoilState, evaluatedState,
	isSubmitButtonDisabledState,
	mostRecentAscvdSeqState, riskAssmtDateState
} from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { DialogButtons } from "@molecules/ModifyPassword";
import { PastASCVDDetail } from "@organisms/Ascvd";

interface AscvdHistoryRow {
	ascvdSeq: number;
	createAt: string;
	riskAssessmentDate: string;
}

interface TrsProps {
	data: AscvdHistoryRow[];
	onDeleteSuccess: (ascvdSeq: number) => void;
}

const Trs: React.FC<TrsProps> = ({ data, onDeleteSuccess }) => {
	const { t } = useTranslation();
	const { patientSeq } = useParams();

	const { closeDialog, openDialog } = useDialog();

	const deleteText: string = useMemo(() => t('patient.delete'), [t]);
	const downloadText: string = useMemo(() => t('patient.download'), [t]);

	const deleteAscvd = useCallback((ascvdSeq: number) => {
		if (patientSeq) {
			deleteAscvdTestResult(patientSeq, ascvdSeq, () => {
				onDeleteSuccess(ascvdSeq);
			});
		}
	}, [onDeleteSuccess, patientSeq]);

	const openDeleteDialog = useRecoilCallback(({ set }) => (ascvdSeq: number) => {
		if (patientSeq) {
			const dialogID: string = 'delete-ascvd-result-dialog';
			set(dialogIDRecoilState, dialogID);
			set(isSubmitButtonDisabledState, false);
			set(apiFunctionState, () => () => deleteAscvd(ascvdSeq));
			openDialog({
				dialogClass: 'untitled',
				dialogContents: <Trans i18nKey='patient.suredelete' components={{ br: <br /> }} />,
				dialogButtons: <DialogButtons dialogId={dialogID} submitButtonText={t('hospital.ghkrdls')} />
			});
		}
	}, [deleteAscvd, openDialog, patientSeq, t]);

	// 과거 ASCVD 위험 평가 결과 상세 팝업
	const openDetailHistoryDialog = useRecoilCallback(({ set }) => (ascvdSeq: number) => {
		const dialogID: string = 'ascvd-history-detail-dialog';
		set(dialogIDRecoilState, dialogID);
		openDialog({
			dialogTitle: t('patient.ascvdrar'),
			dialogContents: <PastASCVDDetail ascvdSeq={ascvdSeq} />,
			dialogButtons: <Button className='blue' onClick={() => closeDialog(dialogID)}>{t('dashboard.ok')}</Button>
		});
	}, [closeDialog, openDialog, t]);

	// 다운로드용 과거 ASCVD 위험 평가 결과 상세 팝업
	const openDownloadDetailHistoryDialog = useRecoilCallback(({ set }) => (ascvdSeq: number, riskAssessmentDate: string) => {
		if (patientSeq) {
			set(riskAssmtDateState, riskAssessmentDate.split('T')[0]);
			window.open(`/patient/${patientSeq}/ascvd/${ascvdSeq}`, '_blank', 'width=1200,height=900');
		}
	}, [patientSeq]);

	return (
		<>
			{data.map(({ ascvdSeq, createAt, riskAssessmentDate }) => (
				<tr key={ascvdSeq}>
					<td>
						<span className='underline' onClick={() => openDetailHistoryDialog(ascvdSeq)}>{createAt.split('T')[0]}</span>
					</td>
					<td>{riskAssessmentDate.split('T')[0]}</td>
					<td>
						<div className='hcai-history-manage-btns'>
							<Button onClick={() => openDownloadDetailHistoryDialog(ascvdSeq, riskAssessmentDate)}>{downloadText}</Button>
							<Button onClick={() => openDeleteDialog(ascvdSeq)}>{deleteText}</Button>
						</div>
					</td>
				</tr>
			))}
		</>
	);
}

const History = () => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [fullHistoryList, setFullHistoryList] = useState<AscvdHistoryRow[]>([]);
	const [historyRow, setHistoryRow] = useState<AscvdHistoryRow[]>([]);
	const [limit, setLimit] = useState(5);

	const evaluated = useRecoilValue(evaluatedState);
	const mostRecentAscvdSeq = useRecoilValue(mostRecentAscvdSeqState);

	const foldText: string = useMemo(() => t('patient.fold'), [t]);
	const moreText: string = useMemo(() => t('patient.more'), [t]);

	const handleDeleteSuccess = useRecoilCallback(({ reset }) => (ascvdSeq: number) => {
		setFullHistoryList(prev => prev.filter(item => item.ascvdSeq !== ascvdSeq));
		if (ascvdSeq === mostRecentAscvdSeq) {
			reset(ascvdGraphLegendStyleState);
			reset(ascvdResultState);
			reset(ascvdResultChangeState);
			reset(ascvdTestResultExistState);
			reset(evaluatedState);
			reset(mostRecentAscvdSeqState);
		}
	}, [mostRecentAscvdSeq]);

	useEffect(() => {
		if (patientSeq) {
			findPastAscvds(patientSeq, res => {
				const list = res.data.pastPredictionList;
				setFullHistoryList(list);
				setHistoryRow(list.slice(0, 5));
			}, () => setFullHistoryList([]));
		}
	}, [evaluated, patientSeq]);

	useEffectAfterMount(() => {
		setHistoryRow(fullHistoryList.slice(0, limit));
	}, [fullHistoryList, limit]);

	return (
		<>
			<div className='hcai-history-title'>
				<h2>{t('patient.prar')}</h2>
			</div>
			<table className='hcai-history-table'>
				<ColGroup widths={['33.33%', '33.33%', '33.34%']} />
				<thead>
					<tr>
						<th>{t('patient.rd')}</th>
						<th>{t('patient.rad')}</th>
						<th>{t('patient.manage')}</th>
					</tr>
				</thead>
				<tbody>
				{fullHistoryList.length === 0
					? <tr><td colSpan={3}>{t('patient.nodata')}</td></tr>
					: <Trs data={historyRow} onDeleteSuccess={handleDeleteSuccess} />
				}
				</tbody>
			</table>
			{fullHistoryList.length > 5 &&
				(limit <= fullHistoryList.length
						? <Button className='more' onClick={() => setLimit(prev => prev + 5)}>{moreText}</Button>
						: <Button className='more' onClick={() => setLimit(5)}>{foldText}</Button>
				)
			}
		</>
	);
};

export default History;
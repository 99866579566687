import React from 'react';
import { components } from 'react-select';
import icDown from '@styles/images/ic-down.png';
import icUp from '@styles/images/ic-up.png';

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			{props.selectProps.menuIsOpen
				? <img src={icUp} alt='up' />
				: <img src={icDown} alt='down' />
			}
		</components.DropdownIndicator>
	);
};

export default DropdownIndicator;
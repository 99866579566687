import React from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { Button } from "@atoms/Button";
import { useDialog } from "@hooks/useDialog";
import { dialogIDRecoilState, loggedInUserState } from "@models/recoilStates";
import { Lri } from "@molecules/LegalAndRegulatory";

const Footer = () => {
	const { t } = useTranslation();
	const { closeDialog, openDialog } = useDialog();
	const { category } = useRecoilValue(loggedInUserState);

	const openLriDialog = useRecoilCallback(({ set }) => () => {
		const lriDialog: string = 'lri-dialog';
		set(dialogIDRecoilState, lriDialog);
		openDialog({
			dialogClass: lriDialog,
			dialogTitle: t('footer.lri'),
			dialogContents: <Lri />,
			dialogButtons: <Button className='blue' onClick={() => closeDialog(lriDialog)}>{t('dashboard.ok')}</Button>
		});
	}, [closeDialog, openDialog, t]);

	return (
		<footer>
			<div className="corp-info">
				<div className="row">
					<span>&copy;&nbsp;2024, LifeSemantics</span>
				</div>
				<div className="row">
					<span>{t('footer.ceo')}</span>
					<span>{t('footer.bln')}: 120-87-88602</span>
					<span>{t('footer.email')}: info@lifesemantics.kr</span>
					<span>{t('footer.mobrn')}</span>
					<span>{t('footer.tel')}: 1661-2858</span>
				</div>
				<div className="row">
					<span>{t('footer.fax')}: 02-2038-2858</span>
					<span>{t('footer.address')}</span>
					{/*{category && <span className='lri' onClick={openLriDialog}>{t('footer.lri')}</span>}*/}
					{category === 'BPAI' && <span className='lri' onClick={openLriDialog}>{t('footer.lri')}</span>}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
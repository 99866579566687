import React from 'react';
import { useSetRecoilState } from "recoil";
import { Button } from "@atoms/Button";
import { profileButtonsState } from "@models/recoilStates";

interface ProfileBoxProps {
	id: string;
	name: string;
}

const ProfileBox: React.FC<ProfileBoxProps> = ({ id, name }) => {
	const setProfileButtonsState = useSetRecoilState(profileButtonsState);

	return (
		<div className="profile-box">
			<div className='profile-info'>
				<strong>{name}</strong>
				<p>{id}</p>
			</div>
			<Button onClick={() => setProfileButtonsState(prev => !prev)} />
		</div>
	);
};

export default ProfileBox;
import React from 'react';
import { useRecoilValue } from "recoil";
import { HeaderProps } from "@models/layoutTypes";
import { dialogRecoilState } from "@models/recoilStates";
import { Dialog } from "@molecules/Dialog";
import { Footer } from "@molecules/Footer";
import { HospitalBasicInfo, HospitalUserList } from "@organisms/HospitalInfo";
import { GrayMiddleBox } from "@organisms/MiddleBox";
import { GrayTopBox } from "@organisms/TopBox";
import { ContentBoxGray } from "@templates/ContentBox";
import { DefaultLayout } from "@templates/DefaultLayout";

const Detail: React.FC<HeaderProps> = (headerProps) => {
	const dialogState = useRecoilValue(dialogRecoilState);

	return (
		<DefaultLayout {...headerProps}>
			<ContentBoxGray style={{ backgroundColor: '#fff' }}>
				<GrayTopBox>
					<HospitalBasicInfo />
				</GrayTopBox>
				<GrayMiddleBox className='h100'>
					<HospitalUserList />
				</GrayMiddleBox>
			</ContentBoxGray>
			<Footer />
			<Dialog {...dialogState} />
		</DefaultLayout>
	);
};

export default Detail;
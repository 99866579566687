import { CSSProperties } from "react";
import { atom, selector } from "recoil";
import {
	INITIAL_ASCVD_GRAPH_LEGEND_STYLE,
	INITIAL_DATE_STRING_HYPHEN,
	INITIAL_RISK_ASSESSMENT_RESULT
} from "@constants/common";
import {
	AIModel, AscvdResult,
	BPAIPersonalInfoProps,
	DialogProps,
	HCAIPersonalInfoProps,
	LoggedInUserProps,
	PatientSearchProps
} from "@models/layoutTypes";
import { SelectBoxOptionProps } from "@models/optionProps";

export const loggedInUserState = atom<LoggedInUserProps>({
	key: "loggedInUserState",
	default: {
		category: '',
		id: '',
		name: '',
		seq: 0,
		userType: ''
	}
});

export const navMenuState = atom<string | undefined>({
	key: "navMenuState",
	default: undefined
});

export const profileButtonsState = atom<boolean>({
	key: "profileButtonsState",
	default: false
});

export const pieGraphDateState = atom<Date>({
	key: 'pieGraphDateState',
	default: new Date()
});

export const columnGraphDateState = atom<Date>({
	key: 'columnGraphDateState',
	default: new Date()
});

export const bpTrendingGraphDateState = atom<Date>({
	key: 'bpTrendingGraphDateState',
	default: new Date()
});

export const filterBtnOffsetLeftState = atom<number>({
	key: 'filterBtnOffsetLeftState',
	default: 0
});

export const filterBtnOffsetTopState = atom<number>({
	key: 'filterBtnOffsetTopState',
	default: 0
});

export const patientSearchKeywordState = atom<string>({
	key: 'patientSearchKeywordState',
	default: ''
});

export const doctorNameKeywordState = atom<string>({
	key: 'doctorNameKeywordState',
	default: ''
});

export const modifyPasswordSucceedState = atom<boolean | undefined>({
	key: 'modifyPasswordSucceedState',
	default: undefined
})

export const filterCheckedState = atom<boolean[]>({
	key: 'filterCheckedState',
	default: []
});

export const filterValuesState = atom<string[]>({
	key: 'filterValuesState',
	default: []
});

export const patientSearchState = selector<PatientSearchProps>({
	key: 'patientSearchState',
	get: ({ get }) => {
		const loggedInUser = get(loggedInUserState);
		return {
			hospitalSeq: loggedInUser.hospitalSeq,
			patientKeyword: get(patientSearchKeywordState),
			doctorName: get(doctorNameKeywordState),
			filters: get(filterValuesState)
		}
	}
});

export const pageNumberState = atom<number>({
	key: 'pageNumberState',
	default: 0
});

export const dialogIDRecoilState = atom<string>({
	key: 'dialogIDRecoilState',
	default: 'empty'
});

export const dialogRecoilState = atom<DialogProps>({
	key: 'dialogRecoilState',
	default: {
		isOpen: false,
		dialogContents: ''
	}
});

export const aiModelState = atom<AIModel>({
	key: 'aiModelState',
	default: ''
});

export const isSubmitButtonDisabledState = atom<boolean>({
	key: 'isSubmitButtonDisabledState',
	default: true
});

export const apiFunctionState = atom<() => void>({
	key: 'apiFunctionState',
	default: () => {}
});

export const totalElementsState = atom<number>({
	key: 'totalElementsState',
	default: 0
})

export const bpaiPersonalInfoState = atom<BPAIPersonalInfoProps>({
	key: 'bpaiPersonalInfoState',
	default: {
		birthDate: '2024-01-01',
		contact: '',
		createdDate: '2024-01-01',
		drink: '',
		gender: 'MALE',
		height: '',
		id: '',
		name: '',
		smoke: '',
		weight: ''
	}
});

export const hcaiPersonalInfoState = atom<HCAIPersonalInfoProps>({
	key: 'hcaiPersonalInfoState',
	default: {
		birthDate: '2024-01-01',
		contact: '',
		createdDate: '2024-01-01',
		gender: 'MALE',
		name: '',
		staffName: '',
		staffSeq: 0
	}
});

export const treatmentHistoryAddedState = atom<number>({
	key: 'treatmentHistoryAddedState',
	default: 0
});

export const hospitalSearchState = atom<string>({
	key: 'hospitalSearchState',
	default: ''
});

export const hospitalModifyCountState = atom<number>({
	key: 'hospitalModifyCountState',
	default: 0
});

export const userListRefreshState = atom<boolean>({
	key: 'userListRefreshState',
	default: false
});

export const hospitalCategoriesState = atom<string[]>({
	key: 'hospitalCategoriesState',
	default: []
});

export const usedCategoriesState = atom<string[]>({
	key: 'usedCategoriesState',
	default: []
});

export const hospitalT3QkeyState = atom<string | null>({
	key: 'hospitalT3QkeyState',
	default: null
});

export const doctorOptionsState = atom<SelectBoxOptionProps[]>({
	key: 'doctorOptionsState',
	default: []
});

export const ascvdTestResultExistState = atom<boolean>({
	key: 'ascvdTestResultExistState',
	default: false
});

export const evaluatedState = atom<boolean>({
	key: 'evaluatedState',
	default: false
});

export const ascvdResultChangeState = atom<number>({
	key: 'ascvdResultChangeState',
	default: 0
});

// 검사 결과 상세에 나오는 것의 ascvdSeq(= 가장 최근 것의 ascvdSeq)
export const mostRecentAscvdSeqState = atom<number | undefined>({
	key: 'mostRecentAscvdSeqState',
	default: undefined
});

// ascvd 다운로드 시 이름 생성용
export const riskAssmtDateState = atom<string>({
	key: 'riskAssmtDateState',
	default: INITIAL_DATE_STRING_HYPHEN
});

export const ascvdResultState = atom<AscvdResult>({
	key: 'ascvdResultState',
	default: INITIAL_RISK_ASSESSMENT_RESULT
});

export const ascvdGraphLegendStyleState = atom<CSSProperties>({
	key: 'ascvdGraphLegendStyleState',
	default: INITIAL_ASCVD_GRAPH_LEGEND_STYLE
});
import { useRecoilCallback, useRecoilValue } from "recoil";
import { DialogContentProps } from "@models/layoutTypes";
import {
	apiFunctionState,
	dialogIDRecoilState,
	dialogRecoilState,
	modifyPasswordSucceedState
} from "@models/recoilStates";

export const useDialog = () => {
	const dialogDataState = useRecoilValue(dialogRecoilState);
	const dialogID = useRecoilValue(dialogIDRecoilState);

	// 팝업 닫기
	const closeDialog = useRecoilCallback(({ reset }) => (id: string) => {
		reset(dialogRecoilState);

		const dialog: HTMLDialogElement = document.getElementById(id) as HTMLDialogElement;
		dialog.close();
		dialog.removeAttribute('class');

		reset(dialogIDRecoilState);
		reset(apiFunctionState);
	}, []);

	// 팝업 열기
	const openDialog = useRecoilCallback(({ set }) => (props: DialogContentProps) => {
		set(dialogRecoilState, { ...props, isOpen: true });

		const dialog: HTMLDialogElement = document.getElementById(dialogID) as HTMLDialogElement;
		const dialogClass = props.dialogClass;
		if (dialogClass) {
			dialog.setAttribute('class', dialogClass);
		}
		dialog.showModal();
	}, [dialogID]);

	// 비밀번호 변경 결과 팝업 닫기
	const closePwdModifyResultDialog = useRecoilCallback(({ reset }) => () => {
		closeDialog(dialogID);
		reset(modifyPasswordSucceedState);
	}, [closeDialog, dialogID]);

	return { closeDialog, closePwdModifyResultDialog, dialogDataState, openDialog };
};
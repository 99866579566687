import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { evaluate } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import {
	ascvdGraphLegendStyleState,
	ascvdResultState,
	ascvdTestResultExistState,
	evaluatedState,
	mostRecentAscvdSeqState
} from "@models/recoilStates";
import { AscvdGraph } from "@molecules/Graph";

const GraphContainer = () => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const ascvdResult = useRecoilValue(ascvdResultState);
	const ascvdTestResultExist = useRecoilValue(ascvdTestResultExistState);
	const evaluated = useRecoilValue(evaluatedState);
	const legendStyle = useRecoilValue(ascvdGraphLegendStyleState);
	const mostRecentAscvdSeq = useRecoilValue(mostRecentAscvdSeqState);

	const downloadResult = useRecoilCallback(({ reset }) => () => {
		if (mostRecentAscvdSeq && patientSeq) {
			window.open(`/patient/${patientSeq}/ascvd/${mostRecentAscvdSeq}`, '_blank', 'width=1200,height=900');
			reset(ascvdGraphLegendStyleState);
			reset(ascvdResultState);
			reset(ascvdTestResultExistState);
			reset(evaluatedState);
		}
	}, [mostRecentAscvdSeq, patientSeq]);

	const handleEvaluate = useRecoilCallback(({ reset, set }) => () => {
		evaluate(mostRecentAscvdSeq, res => {
			set(ascvdGraphLegendStyleState, ({}));
			set(ascvdResultState, ({
				positive: Number((res.data.ascvdGettingRate * 100).toFixed(1)),
				negative: Number((res.data.ascvdNotGettingRate * 100).toFixed(1))
			}));
			set(evaluatedState, true);
		}, () => {
			reset(ascvdGraphLegendStyleState);
			reset(ascvdResultState);
			reset(evaluatedState);
		});
	}, [mostRecentAscvdSeq]);

	const resetEvaluatedAndLegendStyle = useRecoilCallback(({ reset }) => () => {
		reset(ascvdGraphLegendStyleState);
		reset(ascvdResultState);
		reset(evaluatedState);
	}, []);

	useEffect(() => {
		resetEvaluatedAndLegendStyle();
	}, [resetEvaluatedAndLegendStyle]);

	return (
		<div style={{ marginBottom: 40 }}>
			<div className='ascvd-graph-header'>
				<h2>{t('patient.ascvdrar')}</h2>
				{evaluated
					? <Button className='blue' onClick={downloadResult}>{t('patient.downloadResult')}</Button>
					: ascvdTestResultExist
						? <Button className='blue' onClick={handleEvaluate}>{t('patient.evaluate')}</Button>
						: <Button disabled>{t('patient.evaluate')}</Button>
				}
			</div>
			<div className='ascvd-graph-body'>
				{evaluated
					? <AscvdGraph chartHeight={300} positive={ascvdResult.positive} />
					: ascvdTestResultExist
						? <p className='pettr'>{t('patient.pcte')}</p>
						: <p className='pettr'>{t('patient.pettr')}</p>
				}
				<div className='ascvd-graph-legend' style={legendStyle}>
					<div className='ascvd-graph-legend-item positive'>
						<span>{t('patient.positive')}</span>
						<strong>{ascvdResult.positive}%</strong>
					</div>
					<div className='ascvd-graph-legend-item border' />
					<div className='ascvd-graph-legend-item negative'>
						<span>{t('patient.negative')}</span>
						<strong>{ascvdResult.negative}%</strong>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GraphContainer;
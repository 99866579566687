import React from 'react';
import { ComponentProps } from "@models/layoutTypes";

interface GrayContentBoxProps extends ComponentProps {
	style?: React.CSSProperties
}

const Gray: React.FC<GrayContentBoxProps> = ({ children, style }) => {
	return (
		<div className='content-box gray' style={style ?? {}}>{children}</div>
	);
};

export default Gray;
import React from 'react';
import { useTranslation } from "react-i18next";
import { ColGroupProps, PI } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const PatientInfo: React.FC<PI> = ({ age, ascvdCreateAt, gender, name }) => {
	const { t } = useTranslation();

	return (
		<table>
			<ColGroup {...widths} />
			<tbody>
				<tr>
					<th>{t('patient.patientnm')}</th>
					<td>{name}</td>
					<th>{t('dashboard.gender')}</th>
					<td>{t(`patient.${gender}`)}</td>
					<th>{t('patient.age')}</th>
					<td>{t('patient.yearold', { age })}</td>
				</tr>
				<tr>
					<th>{t('patient.doa')}</th>
					<td colSpan={5}>{ascvdCreateAt}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default PatientInfo;
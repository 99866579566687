import React from 'react';
import { AboutAscvd } from "@molecules/AboutAscvd";
import { ASCVDGraphContainer } from "@organisms/Ascvd";

const TestResult = () => {
	return (
		<div className='ascvd-test-result-container'>
			<ASCVDGraphContainer />
			<AboutAscvd wrapperStyle={{ borderTop: '1px solid #e5e7eb', paddingTop: 40 }} />
		</div>
	);
};

export default TestResult;
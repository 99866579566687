import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { getCookie } from "typescript-cookie";
import { modifyHospitalUser, saveHospitalUser } from "@apis/hospitalDetail";
import { findT3QUserList } from "@apis/t3q";
import { Input } from "@atoms/Input";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { formatPhoneNumber } from "@logics/common";
import { T3QUserProps, UserFormDataProps, UserFormProps } from "@models/layoutTypes";
import {
	apiFunctionState,
	hospitalCategoriesState, hospitalT3QkeyState,
	isSubmitButtonDisabledState, usedCategoriesState,
	userListRefreshState
} from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { TrTh } from "@molecules/TableRow";
import { RadioOption } from "@molecules/ToggleInputs";

const UserForm: React.FC<UserFormProps> = ({ type, userData }) => {
	const isT3Q: boolean = !!getCookie('userUuid');

	const { t } = useTranslation();

	const [phoneFormat, setPhoneFormat] = useState(type === 'modify');

	const hospitalCategories: string[] = useRecoilValue(hospitalCategoriesState);
	const hospitalT3Qkey = useRecoilValue(hospitalT3QkeyState);

	const phoneRef = useRef<HTMLInputElement>(null);

	const initialFormData: UserFormDataProps = useMemo(() => ({
		...userData,
		userType: typeof userData.userType === "undefined" ? 'DOCTOR' : userData.userType,
		isActive: typeof userData.isActive === "undefined" ? true : userData.isActive
	}), [userData]);

	const [formData, setFormData] = useState<UserFormDataProps>(initialFormData);
	const [t3qUsers, setT3QUsers] = useState<T3QUserProps[]>([]);

	const addText = useMemo(() => t('hospital.add'), [t]);
	const peidText = useMemo(() => t('login.peid'), [t]);
	const petnText = useMemo(() => t('hospital.petn'), [t]);
	const selectedText = useMemo(() => t('hospital.selected'), [t]);

	// 선택한 사용자 변화 핸들링
	const handleSelectedUserChange = useCallback((e: ChangeEvent<HTMLInputElement>, i: number) => {
		const { checked, value } = e.target;
		const { id, name, t3qkey, userType }: T3QUserProps = JSON.parse(value);
		setFormData({ categories: [''], hospitalSeq: userData.hospitalSeq, id, name, t3qkey, userType });
		setT3QUsers(prev =>
			prev.map((u, idx) => ({ ...u, text: (checked && idx === i) ? selectedText : addText }))
		);
	}, [addText, selectedText, userData.hospitalSeq]);

	const saveOrModifySucceed = useRecoilCallback(({ set }) => () => {
		set(userListRefreshState, prev => !prev);
		set(usedCategoriesState, prev => Array.from(new Set([...prev, ...formData.categories])));
	}, [formData.categories]);

	const saveNewUser = useCallback(() => {
		saveHospitalUser(formData, () => {
			saveOrModifySucceed();
		}, () => {});
	}, [formData, saveOrModifySucceed]);

	const modifyUser = useCallback(() => {
		modifyHospitalUser(formData, () => {
			saveOrModifySucceed();
		}, () => {});
	}, [formData, saveOrModifySucceed]);

	const setSubmittableAndApiFunction = useRecoilCallback(({ reset, set }) => () => {
		const { categories, contact, id, name } = formData;
		const abled: boolean = !!(!!categories[0] && contact && id && name && phoneFormat);

		set(isSubmitButtonDisabledState, !abled);

		if (abled) {
			if (type === 'registration') {
				set(apiFunctionState, () => saveNewUser);
			} else {
				set(apiFunctionState, () => modifyUser);
			}
		} else {
			reset(apiFunctionState);
		}
	}, [formData, modifyUser, phoneFormat, saveNewUser, type]);

	const handleFormDataChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		switch (name) {
			case 'categories':
				setFormData(prev => ({ ...prev, categories: [value] }));
				break;
			case 'contact':
				setFormData(prev => ({ ...prev, contact: formatPhoneNumber(value) }));
				break;
			case 'isActive':
				setFormData(prev => ({ ...prev, isActive: value === '1' }));
				break;
			default:
				setFormData(prev => ({ ...prev, [name]: value }));
				break;
		}
	}, []);

	useEffect(() => {
		if (hospitalT3Qkey && isT3Q && type === 'registration') {
			findT3QUserList(hospitalT3Qkey, (res) => {
				setT3QUsers(res.data.t3QStaffList
					.filter((item: T3QUserProps) => !item.save)
					.map((item: T3QUserProps) => ({ ...item, text: addText }))
				);
			}, () => setT3QUsers([]));
		}
	}, [addText, hospitalT3Qkey, isT3Q, type]);

	useEffect(() => {
		setSubmittableAndApiFunction();
	}, [setSubmittableAndApiFunction]);

	useEffectAfterMount(() => {
		if (phoneRef.current) {
			setPhoneFormat(phoneRef.current.checkValidity());
		}
	}, [formData.contact]);

	return (
		<form className='user-form'>
			{(hospitalT3Qkey && isT3Q && type === 'registration') && (
				<div className='input-grp'>
					<label>{t('hospital.ul')}</label>
					<table>
						<ColGroup widths={['34%', '34%', '32%']} />
						<thead className='list-head t3q'>
							<TrTh thArr={[t('hospital.name'), t('login.id'), t('hospital.status')]} />
						</thead>
						<tbody className='list-body t3q'>
						{t3qUsers.map((user, i) => (
							<tr key={user.t3qkey}>
								<td>{user.name}</td>
								<td>{user.id}</td>
								<td>
									<RadioOption className='radio-t3q' name='t3qUser' id={`t3qUser-${user.t3qkey}`}
												 onChange={e => handleSelectedUserChange(e, i)}
												 value={JSON.stringify(user)}
												 text={user.text} />
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			)}
			<div className='input-grp'>
				<label>{t('hospital.uc')}</label>
				<div className='radio-options'>
					<RadioOption name='userType' id='userType-doctor' text={t('hospital.doctor')}
								 onChange={handleFormDataChange} value='DOCTOR'
								 checked={formData.userType === 'DOCTOR'} />
					<RadioOption name='userType' id='userType-coordinator' text={t('hospital.coordinator')}
								 onChange={handleFormDataChange} value='COORDINATOR'
								 checked={formData.userType === 'COORDINATOR'} />
				</div>
			</div>
			<div className='input-grp'>
				<label htmlFor='id'>{t('login.id')}</label>
				{(type === 'modify' || (hospitalT3Qkey && isT3Q))
					? <Input type='text' name='id' placeholder={peidText} defaultValue={formData.id ?? ''} disabled />
					: <Input type='text' name='id' placeholder={peidText} value={formData.id ?? ''} onChange={handleFormDataChange} />
				}
			</div>
			<div className='input-grp'>
				<label htmlFor='name'>{t('hospital.name')}</label>
				{(hospitalT3Qkey && isT3Q)
					? <Input type='text' name='name' placeholder={petnText} defaultValue={formData.name ?? ''} disabled />
					: <Input type='text' name='name' placeholder={petnText} value={formData.name ?? ''} onChange={handleFormDataChange} />
				}
			</div>
			<div className='input-grp'>
				<label htmlFor='contact'>{t('hospital.phone')}</label>
				<Input type='text' name='contact' ref={phoneRef} maxLength={13}
					   placeholder={t('patient.pithwe')} value={formData.contact ?? ''}
					   pattern="010-\d{4}-\d{4}" onChange={handleFormDataChange} />
			</div>
			<div className='input-grp'>
				<label htmlFor="categories">{t('hospital.category')}</label>
				<div style={{ display: 'flex', gap: 16 }}>
					{hospitalCategories.map((category: string) =>
						<RadioOption name='categories' text={category} value={category} key={category}
									 checked={category === formData.categories[0].toUpperCase()}
									 id={`category-${category}`} onChange={handleFormDataChange} />
					)}
				</div>
			</div>
			{type === 'modify' &&
				<div className='input-grp'>
					<label>{t('hospital.status')}</label>
					<div className='radio-options'>
						<RadioOption name='isActive' id='isActive-true' text={t('hospital.active')}
									 onChange={handleFormDataChange} value='1'
									 checked={formData.isActive === true} />
						<RadioOption name='isActive' id='isActive-false' text={t('hospital.inactive')}
									 onChange={handleFormDataChange} value='0'
									 checked={formData.isActive === false} />
					</div>
				</div>
			}
			<Input type='hidden' name='hospitalSeq' defaultValue={formData.hospitalSeq} />
		</form>
	);
};

export default UserForm;
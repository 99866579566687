import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { modifyPatientPassword } from "@apis/patientDetail";
import { modifyStaffPassword } from "@apis/profile";
import { Input } from "@atoms/Input";
import { INITIAL_PASSWORD_FORM_DATA } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { PasswordForm } from "@models/layoutTypes";
import {
	apiFunctionState,
	dialogRecoilState, isSubmitButtonDisabledState,
	loggedInUserState, modifyPasswordSucceedState
} from "@models/recoilStates";

interface FormProps {
	passwordOwner: 'patient' | 'staff';
}

const Form: React.FC<FormProps> = ({ passwordOwner }) => {
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [form, setForm] = useState<PasswordForm>(INITIAL_PASSWORD_FORM_DATA);
	const [isSamePassword, setIsSamePassword] = useState(false);
	const [isValidatePassword, setIsValidatePassword] = useState(false);

	const { seq } = useRecoilValue(loggedInUserState);
	const dialogDataState = useRecoilValue(dialogRecoilState);

	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useRecoilState(isSubmitButtonDisabledState);

	const setApiFunction = useSetRecoilState(apiFunctionState);

	const modifyPassword = useRecoilCallback(({ set }) => () => {
		if (passwordOwner === 'patient') {
			modifyPatientPassword(patientSeq, form.password,
				() => set(modifyPasswordSucceedState, true),
				() => set(modifyPasswordSucceedState, false),
				() => setForm(INITIAL_PASSWORD_FORM_DATA)
			);
		} else if (passwordOwner === 'staff') {
			modifyStaffPassword(seq, form.password,
				() => set(modifyPasswordSucceedState, true),
				() => set(modifyPasswordSucceedState, false),
				() => setForm(INITIAL_PASSWORD_FORM_DATA)
			);
		}
	}, [form.password, passwordOwner, patientSeq, seq]);

	useEffect(() => {
		setForm(INITIAL_PASSWORD_FORM_DATA);
	}, [dialogDataState]);

	useEffectAfterMount(() => {
		const { password, repeatPassword } = form;
		setIsSamePassword(() => password === repeatPassword);
	}, [form]);

	useEffectAfterMount(() => {
		// 영어, 숫자, 특수문자 중 2가지 이상 포함 여부 검사
		const isValidLength: boolean = form.password.length >= 6 && form.password.length <= 20;
		const hasLetter: boolean = /[A-Za-z]/.test(form.password);
		const hasNumber: boolean = /\d/.test(form.password);
		const hasSpecialChar: boolean = /[\W_]/.test(form.password);

		// 조건 충족 여부 계산
		const conditionsMet: boolean = [hasLetter, hasNumber, hasSpecialChar].filter(Boolean).length >= 2;

		setIsValidatePassword(() => isValidLength && conditionsMet);
	}, [form.password]);

	useEffectAfterMount(() => {
		setIsSubmitButtonDisabled(() => !(isValidatePassword && isSamePassword));
	}, [isValidatePassword, isSamePassword]);

	useEffectAfterMount(() => {
		if (!isSubmitButtonDisabled) {
			setApiFunction(() => modifyPassword);
		} else {
			setApiFunction(() => {});
		}
	}, [isSubmitButtonDisabled, modifyPassword, setApiFunction]);

	const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setForm({ ...form, [name]: value });
	}

	return (
		<form className='modify-password-form'>
			<div className="input-grp">
				<label htmlFor='password'>{t('profile.np')}</label>
				<Input type='password' autoComplete='new-password' id='password' name='password' maxLength={20}
					   value={form.password} onChange={handleFormChange}
					   placeholder={t('profile.pwregexp')} />
			</div>
			<div className="input-grp">
				<label htmlFor='re-password'>{t('profile.npc')}</label>
				<Input type='password' autoComplete='new-password' id='re-password' name='repeatPassword' maxLength={20}
					   value={form.repeatPassword} onChange={handleFormChange}
					   placeholder={t('profile.pepafc')} />
			</div>
		</form>
	);
};

export default Form;
import React from 'react';
import smallLogo from '@styles/images/img_small_logo.png';

const Small = () => {
	return (
		<div className="logo small">
			<img src={smallLogo} alt='small logo' />
		</div>
	)
};

export default Small;
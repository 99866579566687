import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { patientText } from "@logics/common";
import { ListBodyProps } from "@models/layoutTypes";

const Bpai: React.FC<ListBodyProps> = ({ content, page, total }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	if (content.length === 0) {
		return <tr><td className='no-result' colSpan={9}>{t('hospital.nr')}</td></tr>;
	}

	return (
		<>
			{content.map((item, index: number) => (
				<tr key={index} onClick={() => navigate(`/patient/${item.seq}`)}>
					<td>{total - (index + 10 * page)}</td>
					<td>{item.id}</td>
					<td>{item.name}</td>
					<td>{item.birthDate}</td>
					<td>{item.contact}</td>
					<td>{patientText(item.gender)}</td>
					<td>{item.createdDate}</td>
					<td>{item.doctorName}</td>
					<td><p className={`patient-status ${item.status.toLowerCase()}`}>{patientText(item.status)}</p></td>
				</tr>
			))}
		</>
	);
}

export default Bpai;
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { findHospitalDetail } from "@apis/hospitalDetail";
import { modifyHospital, saveHospital } from "@apis/hospitalList";
import { findT3QHospitalList } from "@apis/t3q";
import { Input } from "@atoms/Input";
import { AI_CATEGORIES, INITIAL_T3Q_HOSPITAL_FORM_DATA } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { T3QHospitalFormDataProps } from "@models/layoutTypes";
import {
	apiFunctionState, hospitalModifyCountState,
	isSubmitButtonDisabledState,
	totalElementsState,
	usedCategoriesState
} from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { TrTh } from "@molecules/TableRow";
import { CheckBoxOption, RadioOption } from "@molecules/ToggleInputs";

interface T3QHospitalFormProps {
	type: 'modify' | 'registration';
}

interface T3QHospitalProps {
	hospitalAddress: string;
	hospitalName: string;
	save: boolean;
	t3qkey: string;
	text: string;
}

const T3QHospitalForm: React.FC<T3QHospitalFormProps> = ({ type }) => {
	const { hospitalSeq } = useParams();
	const { t } = useTranslation();

	const [formData, setFormData] = useState<T3QHospitalFormDataProps>(INITIAL_T3Q_HOSPITAL_FORM_DATA);
	const [hospitals, setHospitals] = useState<T3QHospitalProps[]>([]);

	const usedCategories = useRecoilValue(usedCategoriesState);

	const setApiFunction = useSetRecoilState(apiFunctionState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	const registerHospital = useRecoilCallback(({ set }) => () => {
		saveHospital(formData, () => {
			set(totalElementsState, prev => prev + 1);
			setFormData(INITIAL_T3Q_HOSPITAL_FORM_DATA);
		}, () => setFormData(INITIAL_T3Q_HOSPITAL_FORM_DATA));
	}, [formData]);

	const updateHospital = useRecoilCallback(({ set }) => () => {
		modifyHospital(formData, (res) => {
			if (res.status === 200) {
				set(hospitalModifyCountState, prev => prev + 1);
			}
		}, () => {});
	}, [formData]);

	// ○○AI 체크 박스 변화 핸들링
	const handleAIChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { checked, value } = e.target;
		if (checked) {
			setFormData(prev => ({ ...prev, categories: [...prev.categories, value] }));
		} else {
			setFormData(prev => ({ ...prev, categories: prev.categories.filter(item => item !== value) }));
		}
	}, []);

	useEffect(() => {
		if (type === 'registration') {
			findT3QHospitalList((res) => {
					setHospitals(res.data.hospitalList
											.filter((item: T3QHospitalProps) => !item.save)
											.map((item: T3QHospitalProps) => ({ ...item, text: t('hospital.add') }))
					);
				}, () => setHospitals([]));
		} else if (type === 'modify' && hospitalSeq) {
			findHospitalDetail(hospitalSeq, (res) => {
				const { address, categories, name, seq } = res.data;
				setFormData({
					address,
					isActive: true,
					categories: categories.map((c: string) => c.toUpperCase()).sort(),
					hospitalSeq: seq,
					name
				});
			}, () => {});
		}
	}, [hospitalSeq, t, type]);

	useEffectAfterMount(() => {
		const { address, categories, name } = formData;
		const abled: boolean = !!(address && categories.length && name);
		setIsSubmitButtonDisabled(!abled);
		if (abled) {
			if (type === 'registration') {
				setApiFunction(() => registerHospital);
			} else {
				setApiFunction(() => updateHospital);
			}
		} else {
			setApiFunction(() => {});
		}
	}, [formData, registerHospital, setApiFunction, setIsSubmitButtonDisabled, type, updateHospital]);

	// 선택 병원 변화 핸들링
	const handleHospitalChange = useCallback((e: ChangeEvent<HTMLInputElement>, i: number) => {
		const { checked, value } = e.target;
		const { hospitalAddress, hospitalName, t3qkey }: T3QHospitalProps = JSON.parse(value);
		setFormData({ address: hospitalAddress, categories: [], name: hospitalName, t3qkey });
		setHospitals(prev =>
			prev.map((h, idx) => ({ ...h, text: (checked && idx === i) ? t('hospital.selected') : t('hospital.add') }))
		);
	}, [t]);

	return (
		<form className='hospital-form'>
			{type === 'registration' && (
				<div className='input-grp'>
					<label>{t('hospital.list')}</label>
					<table style={{ width: 600 }}>
						<ColGroup widths={[160, 320, 120]} />
						<thead className='list-head t3q'>
							<TrTh thArr={[t('hospital.noh'), t('hospital.addr'), t('hospital.status')]} />
						</thead>
						<tbody className='list-body t3q'>
							{hospitals.map((item, i) => (
								<tr key={item.t3qkey}>
									<td>{item.hospitalName}</td>
									<td>{item.hospitalAddress}</td>
									<td>
										<RadioOption className='radio-t3q' name='t3qHospital' id={`t3qHospital${i}`}
													 onChange={e => handleHospitalChange(e, i)}
													 value={JSON.stringify(item)}
													 text={item.text} />
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
			<div className='input-grp'>
				<label htmlFor="categories">{t('hospital.category')}</label>
				<div style={{ display: 'flex' }}>
					{AI_CATEGORIES.map((category: string) =>
						<CheckBoxOption name='categories' text={category} value={category} key={category}
										disabled={usedCategories.includes(category)}
										onChange={handleAIChange}
										checked={formData.categories.includes(category)} />
					)}
				</div>
			</div>
			<div className='input-grp'>
				<label htmlFor="name">{t('hospital.noh')}</label>
				<Input id='name' name='name' type='text' placeholder={t('hospital.psh')}
					   defaultValue={formData.name} disabled />
			</div>
			<div className='input-grp'>
				<label htmlFor="address">{t('hospital.addr')}</label>
				<Input id='address' name='address' type='text' placeholder={t('hospital.psh')}
					   defaultValue={formData.address} disabled />
			</div>
		</form>
	);
};

export default T3QHospitalForm;
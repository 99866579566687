import { AxiosResponse } from "axios";
import { axiosInstance } from "@/axiosInstance";
import { setAxiosRequestConfig } from "@logics/common";
import { UserFormDataProps } from "@models/layoutTypes";

// 병원 상세 조회 API
export const findHospitalDetail = (hospitalSeq: string,
								   callbackSuccess: (res: AxiosResponse<any>) => void,
								   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(`/hospitals/${hospitalSeq}`,config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 병원 사용자 리스트 조회 API
export const findHospitalUsers = (hospitalSeq: string | undefined,
								  callbackSuccess: (res: AxiosResponse<any>) => void,
								  callbackFail: () => void) => {

	const url: string = `/staffs/hospitals/${hospitalSeq}?size=1000`;
	const config = setAxiosRequestConfig();

	axiosInstance
		.get(url, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 사용자 상세 조회 API
export const findHospitalUserDetail = (staffSeq: number,
									   callbackSuccess: (res: AxiosResponse<any>) => void,
									   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(`/staffs/${staffSeq}`, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 사용자 등록 API
export const saveHospitalUser = (formData: UserFormDataProps,
								 callbackSuccess: (res: AxiosResponse<any>) => void,
								 callbackFail: () => void) => {

	delete formData.isActive;

	const config = setAxiosRequestConfig();

	axiosInstance
		.post('/staffs', formData, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

// 사용자 수정 API
export const modifyHospitalUser = (formData: UserFormDataProps,
								   callbackSuccess: (res: AxiosResponse<any>) => void,
								   callbackFail: () => void) => {

	const {
		categories,
		contact,
		isActive,
		name,
		seq,
		userType
	} = formData;

	const data = {
		categories,
		contact,
		isActive,
		name,
		userType,
		staffSeq: seq
	};

	const config = setAxiosRequestConfig();

	axiosInstance
		.patch('/staffs', data, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}
import React, { ChangeEvent } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { filterCheckedState, filterValuesState } from "@models/recoilStates";
import { CheckBoxOption } from "@molecules/ToggleInputs";

interface FilterProps {
	left: number;
	top: number;
	values: string[];
}

const Filter: React.FC<FilterProps> = ({ left, top, values }) => {
	const { t } = useTranslation();
	const checkedArr = useRecoilValue(filterCheckedState);

	const handleFilterChange = useRecoilCallback(({ set }) => (e: ChangeEvent<HTMLInputElement>, i: number) => {
		// 체크 배열 업데이트
		const updatedCheckedArr: boolean[] = [...checkedArr];
		updatedCheckedArr[i] = e.target.checked;
		set(filterCheckedState, updatedCheckedArr);

		// 값 배열 업데이트
		const updatedValueArr: string[] = updatedCheckedArr.reduce<string[]>((acc, checked, index) => {
			if (checked) {
				acc.push(values[index]); // 여기서 초기 상태를 사용하여 조건에 맞는 값을 추가
			}
			return acc;
		}, []);

		set(filterValuesState, updatedValueArr);
	}, [checkedArr, values]);

	return (
		<div className="search-filter" style={{ left, top }}>
			{values.map((value, i) => (
				<CheckBoxOption
					checked={checkedArr[i]}
					id={`filter-${value}`}
					name='filter-option'
					onChange={(e: ChangeEvent<HTMLInputElement>) => handleFilterChange(e, i)}
					key={`option-${value}`}
					text={t(`patient.${value.toLowerCase()}`)}
					value={value}
				/>
			))}
		</div>
	);
};

export default Filter;
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Root = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const { hostname } = window.location;
		switch (hostname) {
			case 'canofy.md': // 실서버
			case 'daw.doctorcall.global': // 스테이징
			case 'localhost': // 로컬 호스트
				navigate('/auth/signin');
				break;
			default:
				navigate('/t3q');
				break;
		}
	}, [navigate]);

	return <></>;
};

export default Root;
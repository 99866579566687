import React from 'react';
import icError from '@styles/images/ic-error.png';

interface UntitledDialogProps {
	dialogButtons: JSX.Element;
	dialogContents: JSX.Element | string;
}

const Untitled: React.FC<UntitledDialogProps> = ({ dialogButtons, dialogContents }) => {
	return (
		<>
			<div className="dialog-contents">
				<img className='ic-error' src={icError} alt='느낌표' />
				<h1>{dialogContents}</h1>
			</div>
			<div className="btns">
				<div className="btns">{dialogButtons}</div>
			</div>
		</>
	);
};

export default Untitled;
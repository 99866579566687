import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Input } from "@atoms/Input";
import { FormType } from "@models/layoutTypes";
import { doctorOptionsState, loggedInUserState } from "@models/recoilStates";
import { SelectBox } from "@molecules/SelectBox";

interface Props {
	onChange: (name: string) => (value: string) => void;
	staffName?: string;
	staffSeq?: number;
	type: FormType;
}

type CodiProps = Omit<Props, 'staffName'>;
type DocProps = Omit<Props, 'onChange' | 'type'>;

const InputCoordinator: React.FC<CodiProps> = ({ onChange, staffSeq, type }) => {
	const { t } = useTranslation();

	const doctorOptions = useRecoilValue(doctorOptionsState);

	return (
		<SelectBox name='staffSeq'
				   onChange={onChange('staffSeq')}
				   options={doctorOptions}
				   placeholder={t('patient.selectap')}
				   value={type === 'modify' ? doctorOptions.find(option => option.value === staffSeq?.toString()) : undefined}
		/>
	)
}

const InputDoctor: React.FC<DocProps> = ({ staffName, staffSeq }) => {
	return (
		<>
			<Input id='staffSeq' type='text' disabled defaultValue={staffName} />
			<Input type='hidden' defaultValue={staffSeq} />
		</>
	)
};

const DoctorInput: React.FC<Props> = (props) => {
	const { t } = useTranslation();

	const loggedInUser = useRecoilValue(loggedInUserState);

	const contents = useMemo(() => {
		switch (loggedInUser.userType) {
			case 'COORDINATOR':
				const codiProps: CodiProps = (({ staffName, ...rest }) => rest)(props);
				return <InputCoordinator {...codiProps} />;
			case 'DOCTOR':
				const docProps: DocProps = (({ onChange, type, ...rest }) => rest)(props);
				return <InputDoctor {...docProps} />;
			default:
				return null;
		}
	}, [loggedInUser.userType, props]);

	return (
		<td colSpan={2}>
			<div className='input-grp'>
				<label htmlFor='staffSeq'>{t('patient.ap')}</label>
				{contents}
			</div>
		</td>
	);
};

export default DoctorInput;
import { AxiosResponse } from "axios";
import { axiosInstance } from "@/axiosInstance";
import { setAxiosRequestConfig } from "@logics/common";

export const findStaffDetailProfile = (staffSeq: number,
									   callbackSuccess: (res: AxiosResponse<any>) => void,
									   callbackFail: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.get(`/staffs/${staffSeq}`, config)
		.then(callbackSuccess)
		.catch(callbackFail);
}

export const modifyStaffPassword = (staffSeq: number,
									password: string,
									callbackSuccess: (res: AxiosResponse<any>) => void,
									callbackFail: () => void,
									callbackFinally: () => void) => {

	const config = setAxiosRequestConfig();

	axiosInstance
		.patch('/staffs/password', { staffSeq, password },config)
		.then(callbackSuccess)
		.catch(callbackFail)
		.finally(callbackFinally);
}
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { formatDateRange } from "@logics/common";
import { OptionProps } from "@models/optionProps";
import { bpTrendingGraphDateState } from "@models/recoilStates";
import { DateRange } from "@molecules/DateRange";
import { DateUnitSelector } from "@molecules/DateUnitSelector";
import { BpTrendingGraph } from "@molecules/Graph";

const Trending = () => {
	const { t } = useTranslation();

	const [date, setDate] = useRecoilState(bpTrendingGraphDateState);

	const [dateUnit, setDateUnit] = useState<string>('week');
	const [fromTo, setFromTo] = useState<string[]>(formatDateRange(date, 7).split(' - '));
	const [periodRange, setPeriodRange] = useState<JSX.Element>(<DateRange dateState={bpTrendingGraphDateState} days={7} />);

	const days: number = useMemo(() => dateUnit === 'month' ? 30 : 7, [dateUnit]);

	const dateUnitOptions: OptionProps[] = useMemo(() => [
		{
			checked: dateUnit === 'week',
			id: 'bp-trending-week',
			text: t('patient.week'),
			onChange: (e: ChangeEvent<HTMLInputElement>) => setDateUnit(e.target.value),
			name: 'bp-trending',
			value: 'week'
		},
		{
			checked: dateUnit === 'month',
			id: 'bp-trending-month',
			text: t('patient.month'),
			onChange: (e: ChangeEvent<HTMLInputElement>) => setDateUnit(e.target.value),
			name: 'bp-trending',
			value: 'month'
		}
	], [dateUnit, t]);

	useEffect(() => {
		setPeriodRange(<DateRange dateState={bpTrendingGraphDateState} days={days} />);
		setDate(new Date());
	}, [days, setDate]);

	useEffectAfterMount(() => {
		setFromTo(formatDateRange(date, days).split(' - '))
	}, [date, days]);

	return (
		<>
			<div className="bp-trending-row" style={{ borderBottom: '1px solid #e5e7eb' }}>
				<h2>{t('patient.bptrend')}</h2>
				<div>{periodRange}<DateUnitSelector options={dateUnitOptions} /></div>
			</div>
			<div className="bp-trending-row graph">
				<BpTrendingGraph
					from={fromTo[0].replaceAll('.', '-')}
					to={fromTo[1].replaceAll('.', '-')}
				/>
			</div>
		</>
	);
};

export default Trending;
import React, { useEffect, useMemo, useState } from 'react';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { findPatientDetail, findPatientPersonalInfo, sendInstallGuideSMS } from "@apis/patientDetail";
import { Button } from "@atoms/Button";
import { INITIAL_PATIENT_FORM_DATA } from "@constants/common";
import { useDialog } from "@hooks/useDialog";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { patientText } from "@logics/common";
import { ColGroupProps, PatientFormDataProps } from "@models/layoutTypes";
import {
	apiFunctionState, bpaiPersonalInfoState,
	dialogIDRecoilState,
	isSubmitButtonDisabledState
} from "@models/recoilStates";
import { ColGroup } from "@molecules/ColGroup";
import { DialogButtons, ModifyPasswordForm } from "@molecules/ModifyPassword";
import { PatientForm } from "@molecules/PatientForm";

const widths: ColGroupProps = { widths: [...Array(5).fill('16.66%'), '16.7%'] };

const InstallGuideContents: React.FC<{ name: string }> = ({ name }) => {
	const { t } = useTranslation();

	const sendToWhom: string = useMemo(() => {
		const sendTo = t('patient.sendto');
		let result = i18next.language === 'en' ? sendTo : '';

		result += name;

		if (i18next.language !== 'en') {
			result += sendTo;
		}

		return result;
	}, [name, t]);


	return <>{sendToWhom}<br />{t('patient.ucmripn')}</>;
}

const BpaiBasic = () => {
	const { openDialog } = useDialog();
	const { patientSeq } = useParams();
	const { t } = useTranslation();

	const [patientData, setPatientData] = useState<PatientFormDataProps>(INITIAL_PATIENT_FORM_DATA);
	const [personalInfo, setPersonalInfo] = useRecoilState(bpaiPersonalInfoState);

	const setApiFunction = useSetRecoilState(apiFunctionState);
	const setDialogID = useSetRecoilState(dialogIDRecoilState);
	const setIsSubmitButtonDisabled = useSetRecoilState(isSubmitButtonDisabledState);

	useEffect(() => {
		if (patientSeq) {
			// 환자 인적사항 세팅
			findPatientPersonalInfo(patientSeq, 'bpai', false, res => setPersonalInfo(res.data), () => {});
		}
	}, [patientSeq, setPersonalInfo]);

	useEffectAfterMount(() => {
		// 환자 수정 팝업에 들어갈 정보 세팅
		findPatientDetail(patientSeq, 'bpai',
			res => setPatientData({
				...res.data,
				drink: res.data.drink ? 'Y' : 'N',
				smoke: res.data.smoke ? 'Y' : 'N',
				staffName: personalInfo.staffName,
				staffSeq: personalInfo.staffSeq
			}),
			() => {
				const {
					birthDate, contact, drink,
					gender, height, id,
					name, smoke, staffName,
					staffSeq, weight
				} = personalInfo;

				setPatientData({
					birthDate,
					contact,
					drink,
					familyDisease: false,
					gender,
					height,
					id,
					name,
					smoke,
					staffName,
					staffSeq,
					weight
				});
			}
		);
	}, [personalInfo]);

	// 비밀번호 변경 팝업 열기
	const openModifyPasswordDialog = () => {
		setIsSubmitButtonDisabled(true);
		setDialogID('modify-password-dialog');
		openDialog({
			dialogTitle: t('profile.cp'),
			dialogContents: <ModifyPasswordForm passwordOwner='patient' />,
			dialogButtons: <DialogButtons dialogId='modify-password-dialog' submitButtonText={t('hospital.dhksfy')} />
		});
	}

	// 환자 수정 팝업 열기
	const openModifyPatientDialog = () => {
		setIsSubmitButtonDisabled(false);
		setDialogID('modify-patient-dialog');
		openDialog({
			dialogTitle: t('patient.epi'),
			dialogContents: <PatientForm type='modify' patientData={patientData} />,
			dialogButtons: <DialogButtons dialogId='modify-patient-dialog' submitButtonText={t('hospital.dhksfy')} />
		});
	}

	const sendInstallGuide = () => {
		if (patientSeq) {
			sendInstallGuideSMS(patientSeq);
		}
	}

	// 설치 안내 발송 팝업 열기
	const openInstallGuideDialog = () => {
		setApiFunction(() => sendInstallGuide);
		setIsSubmitButtonDisabled(false);
		setDialogID('install-guide-dialog');
		openDialog({
			dialogTitle: t('patient.sii'),
			dialogContents: <InstallGuideContents name={personalInfo.name} />,
			dialogButtons: <DialogButtons dialogId='install-guide-dialog' submitButtonText={t('patient.yessend')} />
		});
	}

	return (
		<table className='basic-info'>
			<ColGroup {...widths} />
			<tbody>
			<tr>
				<td colSpan={3} style={{ verticalAlign: 'middle' }}>
					<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
						<h2>{personalInfo.name}</h2>
						<span>({personalInfo.id})</span>
					</div>
				</td>
				<td colSpan={3}>
					<div style={{ display: 'flex', justifyContent: 'right', gap: 10 }}>
						<Button className='white' onClick={openModifyPasswordDialog}>{t('profile.cp')}</Button>
						<Button className='white' onClick={openModifyPatientDialog}>{t('patient.epi')}</Button>
						<Button className='blue' onClick={openInstallGuideDialog}>{t('patient.sii')}</Button>
					</div>
				</td>
			</tr>
			<tr>
				<th>{t('patient.contact')}</th>
				<td>{personalInfo.contact}</td>
				<th>{t('dashboard.gender')}</th>
				<td>{patientText(personalInfo.gender)}</td>
				<th>{t('patient.dob')}</th>
				<td>{personalInfo.birthDate}</td>
			</tr>
			<tr>
				<th>{t('patient.rd')}</th>
				<td>{personalInfo.createdDate}</td>
				<th>{t('patient.height')}</th>
				<td>{Number(personalInfo.height) === 0 ? '-' : `${personalInfo.height}cm`}</td>
				<th>{t('patient.weight')}</th>
				<td>{Number(personalInfo.weight) === 0 ? '-' : `${personalInfo.weight}kg`}</td>
			</tr>
			<tr>
				<th>{t('patient.drinkstatus')}</th>
				<td>{personalInfo.drink ? 'Y' : 'N'}</td>
				<th>{t('patient.smkstatus')}</th>
				<td>{personalInfo.smoke ? 'Y' : 'N'}</td>
				<th>{t('patient.ap')}</th>
				<td>{personalInfo.staffName}</td>
			</tr>
			</tbody>
		</table>
	);
};

export default BpaiBasic;
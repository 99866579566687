import React, { useEffect, useMemo, useState } from 'react';
import i18next from "i18next";
import { useRecoilState } from "recoil";
import { LANGUAGE_OPTIONS } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { HeaderProps } from "@models/layoutTypes";
import { SelectBoxOptionProps } from "@models/optionProps";
import { totalElementsState } from "@models/recoilStates";
import { SelectBox } from "@molecules/SelectBox";
import icGlobal from '@styles/images/ic-global.png';

const Header: React.FC<HeaderProps> = ({ grayTitle, isListPage, pageTitle }) => {
	const [language, setLanguage] = useState<string>(() => {
		const lng: string = i18next.language;
		return lng.includes('-') ? lng.split('-')[0] : lng;
	});

	const [totalElements, setTotalElements] = useRecoilState(totalElementsState);

	const curLng: SelectBoxOptionProps = useMemo(() => LANGUAGE_OPTIONS.find(({ value }) => value === language) ?? LANGUAGE_OPTIONS[0], [language]);

	useEffect(() => {
		if (!isListPage) {
			setTotalElements(0);
		}
	}, [isListPage, setTotalElements]);

	useEffectAfterMount(() => {
		i18next.changeLanguage(language).then();
	}, [language]);

	return (
		<header>
			<div className="header-name">
				{grayTitle && <span className='gray'>{grayTitle}</span>}
				<span>{pageTitle}</span>
				{isListPage && <span className='cell'>({totalElements})</span>}
			</div>
			<div className='header-language'>
				<img src={icGlobal} alt='language icon' />
				<SelectBox name='language' className='language-selector' onChange={value => setLanguage(value)} options={LANGUAGE_OPTIONS} value={curLng} />
			</div>
		</header>
	);
};

export default Header;
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from "recoil";
import { findDashboardColumnGraphData } from "@apis/dashboard";
import { getWeekDates, getWeekDatesYMD } from "@logics/common";
import { DashboardBottomProps, DashboardColumnGraphProps } from "@models/layoutTypes";
import { columnGraphDateState } from '@models/recoilStates';
import { DateRange } from '@molecules/DateRange';
import { ColumnGraph } from '@molecules/Graph';

const Graph: React.FC<DashboardBottomProps> = ({ title }) => {
	const date: Date = useRecoilValue(columnGraphDateState);

	const [graphData, setGraphData] = useState<DashboardColumnGraphProps>({
		values: [0, 0, 0, 0, 0, 0, 0],
		xCategories: getWeekDates(date)
	});

	useEffect(() => {
		const weekDates: string[] = getWeekDates(date);
		const weekDatesYMD: string[] = getWeekDatesYMD(date);
		findDashboardColumnGraphData(date, (res) => {
			const response = res.data;
			const responseObj = response.reduce((acc: any, cur: any) => {
				acc[cur.date] = cur.count;
				return acc;
			}, {} as { [key: string]: { count: number; date: string; } });

			setGraphData({
				values: weekDatesYMD.map((d: string) => responseObj[d] || 0),
				xCategories: weekDates
			});
		}, () => {});
	}, [date]);

	return (
		<div className='dashboard-bottom'>
			<div className='dashboard-bottom-header'>
				<h2>{title}</h2>
				<DateRange dateState={columnGraphDateState} days={7} />
			</div>
			<div className='dashboard-bottom-content'>
				<ColumnGraph {...graphData} />
			</div>
		</div>
	);
};

export default Graph;
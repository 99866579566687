import React, { useMemo, useRef } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from 'highcharts/modules/solid-gauge';
import highchartsAccessibility from "highcharts/modules/accessibility";
import { useTranslation } from "react-i18next";

HighchartsMore(Highcharts); // init module
SolidGauge(Highcharts);
highchartsAccessibility(Highcharts); // init module

interface Props {
	chartHeight: number
	positive: number;
}

const Ascvd: React.FC<Props> = ({ chartHeight, positive }) => {
	const { t } = useTranslation();

	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	const options = useMemo(() => ({
		chart: {
			backgroundColor: 'transparent',
			height: chartHeight,
			margin: [0, 0, 0, 0],
			type: 'solidgauge'
		},
		credits: {
			enabled: false
		},
		exporting: {
			enabled: false
		},
		pane: {
			background: {
				borderColor: '#587bfd',
				borderWidth: 20,
				innerRadius: '100%',
				outerRadius: '100%',
				shape: 'arc'
			},
			center: ['50%', '50%'],
			endAngle: 90,
			startAngle: -90
		},
		plotOptions: {
			solidgauge: {
				borderWidth: 20,
				dataLabels: {
					borderWidth: 0,
					format: positive < 50 ? `<p class="label negative">${t('patient.negative')}</p>` : `<p class="label positive">${t('patient.positive')}</p>`,
					useHTML: true
				},
				linecap: 'square',
				rounded: true
			}
		},
		series: [{
			borderColor: '#f74f58',
			data: [{ innerRadius: '100%', radius: 100, y: positive }],
			enableMouseTracking: false,
			name: 'participation',
			type: 'solidgauge'
		}],
		title: undefined,
		tooltip: {
			enabled: false
		},
		yAxis: {
			lineWidth: 0,
			max: 100,
			min: 0,
			tickPositions: []
		}
	}), [chartHeight, positive, t]);

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
			ref={chartComponentRef}
		/>
	);
};

export default Ascvd;
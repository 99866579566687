import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findPatientAnalytics } from "@apis/patientDetail";
import { INITIAL_CLASSIFICATION } from "@constants/common";
import { switchBPCText, switchDRText, switchGoalLDLText, switchMCText } from "@logics/common";
import { ClassificationProps, ColGroupProps, IsBitProps } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";

const widths: ColGroupProps = { widths: new Array(5).fill('20%') };

const Classification: React.FC<IsBitProps> = ({ isBit }) => {
	const { t } = useTranslation();
	const { patientSeq } = useParams();
	const [classification, setClassification] = useState<ClassificationProps>(INITIAL_CLASSIFICATION);

	useEffect(() => {
		if (patientSeq) {
			// 진료 기록 분석 결과
			findPatientAnalytics(patientSeq, isBit,
				(res) => {
					const { bpControl, diseaseRisk, goalDiastolic, goalLDL, goalSystolic, medicationControl } = res.data;
					setClassification({
						bpControl,
						diastolic: goalDiastolic,
						ldl: goalLDL,
						medicationControl,
						risk: diseaseRisk,
						systolic: goalSystolic
					});
				},
				() => {});
		}
	}, [isBit, patientSeq]);

	return (
		<table>
			<ColGroup {...widths} />
			<thead>
				<tr>
					<th>{t('patient.cdr')}</th>
					<th>{t('patient.targetbp')}</th>
					<th>{t('patient.targetldl')}</th>
					<th>{t('patient.bpcontrol')}</th>
					<th>{t('patient.mcontrol')}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{switchDRText(classification.risk)}</td>
					<td>{classification.systolic < 0 ? '-' : classification.systolic} / {classification.diastolic < 0 ? '-' : classification.diastolic}</td>
					<td>{switchGoalLDLText(classification.ldl)}</td>
					<td>{switchBPCText(classification.bpControl)}</td>
					<td>{switchMCText(classification.medicationControl)}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default Classification;
import React, { CSSProperties } from 'react';
import { components, OptionProps } from "react-select";
import { SelectBoxOptionProps } from "@models/optionProps";

const CustomOption = (props: OptionProps<SelectBoxOptionProps, false>) => {
	const style: CSSProperties = { backgroundColor: props.isFocused ? '#f7f9ff' : '#fff' };

	return (
		<components.Option {...props}>
			<div style={style}>{props.children}</div>
		</components.Option>
	);
};

export default CustomOption;
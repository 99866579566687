import { AxiosRequestConfig } from "axios";
import i18next from "i18next";
import { NavigateFunction } from "react-router-dom";
import { SetterOrUpdater } from "recoil";
import { getCookie } from "typescript-cookie";
import { AIModel, AscvdFormProps, BIRI, BPIRI, PHI } from "@models/layoutTypes";

// 월, 일, 시간, 분 등 1자리 값이 나올 수 있는 것을 2자리로 변환. 1자리일 때 앞에 0 붙임
export const doubleDigit = (val: number): string => String(val).padStart(2, '0');

// 심뇌혈관질환 위험도 텍스트 결정
export const switchDRText = (rawText: string | null): string => {
	switch (rawText) {
		case 'DANGEROUS':
			return i18next.t('patient.hrg');
		case 'NORMAL':
			return i18next.t('patient.nrml');
		case 'CAN_NOT_JUDGE':
		default:
			return i18next.t('patient.undeterminedlong');
	}
}

// 목표 LDL 콜레스테롤 텍스트 결정
export const switchGoalLDLText = (gLDL: number): string => {
	switch (gLDL) {
		case 70:
			return i18next.t('patient.less70');
		case 100:
			return i18next.t('patient.less100');
		case 130:
			return i18next.t('patient.less130');
		default:
			return i18next.t('patient.undetermined');
	}
}

// 혈압 조절 텍스트 결정
export const switchBPCText = (rawText: string | null): string => {
	switch (rawText) {
		case 'APPROPRIATE':
			return i18next.t('patient.appropriate');
		case 'INAPPROPRIATE':
			return i18next.t('patient.inappropriate');
		case 'CAN_NOT_JUDGE':
		default:
			return i18next.t('patient.undetermined');
	}
}

// 약제 조절 텍스트 결정
export const switchMCText = (rawText: string | null): string => {
	switch (rawText) {
		case 'DECREASE':
			return i18next.t('patient.decrease');
		case 'INCREASE':
			return i18next.t('patient.increase');
		case 'STAY':
			return i18next.t('patient.maintenance');
		case 'CAN_NOT_JUDGE':
		default:
			return i18next.t('patient.undetermined');
	}
}

// 성별, 상태 등 파라미터의 소문자 값과 언어팩 키가 같은 문구들의 텍스트 설정
export const patientText = (str: string): string => i18next.t(`patient.${str.toLowerCase()}`);

export const formatKoreanDate = (date: Date): string => {
	const year: number = date.getFullYear();
	const month: number = date.getMonth() + 1;
	const day: number = date.getDate();

	return `${year}년 ${month}월 ${day}일`;
}

const setEngMonth = (month: number): string => {
	switch (month) {
		case 1:
			return 'January';
		case 2:
			return 'February';
		case 3:
			return 'March';
		case 4:
			return 'April';
		case 5:
			return 'May';
		case 6:
			return 'June';
		case 7:
			return 'July';
		case 8:
			return 'August';
		case 9:
			return 'September';
		case 10:
			return 'October';
		case 11:
			return 'November';
		case 12:
			return 'December';
		default:
			return '';
	}
}

export const formatEnglishDate = (date: Date): string => {
	const year: number = date.getFullYear();
	const month: string = setEngMonth(date.getMonth() + 1);
	const day: number = date.getDate();

	return `${month} ${day} ${year}`;
}

export const formatDate = (date: Date, separator: string): string => {
	const year: number = date.getFullYear();
	const month: number = date.getMonth() + 1; // getMonth()는 0부터 11까지의 값을 반환하므로 +1
	const day: number = date.getDate();

	const formattedMonth: string = doubleDigit(month);
	const formattedDay: string = doubleDigit(day);

	return `${year}${separator}${formattedMonth}${separator}${formattedDay}`;
}

export const formatDateMD = (date: Date): string => {
	const month: number = date.getMonth() + 1;
	const day: number = date.getDate();
	return `${month}/${day}`;
}

export const formatDateRange = (date: Date, days: number): string => {
	let startDate = new Date(date);
	startDate.setDate(date.getDate() - (days - 1));

	const formattedStartDate: string = formatDate(startDate, '.');
	const formattedEndDate: string = formatDate(date, '.');

	return `${formattedStartDate} - ${formattedEndDate}`;
};

// base64 형식의 문자열을 디코딩
export const decodeBase64 = (encoded: string): string => {
	// base64 디코딩
	const decodedString: string = atob(encoded.replace(/-/g, '+'));

	// utf-8 디코딩
	return decodeURIComponent(Array.from(decodedString).map((c) =>
		'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
	).join(''));
}

// 기간 내의 모든 날짜를 m/d 형식의 배열로 출력
export const getDatesDuringPeriod = (from: string, to: string): string[] => {
	const dates: string[] = []; // 결과를 저장할 배열
	let currentDate = new Date(from); // 시작 날짜를 Date 객체로 변환
	const endDate = new Date(to); // 종료 날짜를 Date 객체로 변환

	// 시작 날짜가 종료 날짜보다 작거나 같을 때까지 반복
	while (currentDate <= endDate) {
		// 월(month)은 0부터 시작하므로 +1을 해줍니다. getDate()는 일(day)를 반환
		// 결과 형식을 'm/d'로 맞추기 위해 문자열로 변환
		dates.push(`${currentDate.getMonth() + 1}/${currentDate.getDate()}`);
		// 현재 날짜에 1일을 추가
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}

export const getYMDsDuringPeriod = (from: string, to: string): string[] => {
	const dates: string[] = [];
	let currentDate = new Date(from);
	const endDate = new Date(to);

	// 시작 날짜가 종료 날짜와 같거나 작을 때까지 반복
	while (currentDate <= endDate) {
		// 날짜를 yyyy-MM-dd 형식으로 변환하여 배열에 추가
		dates.push(currentDate.toISOString().split('T')[0]);
		// 현재 날짜에 1일 추가
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}

export const getWeekDates = (date: Date): string[] => {
	const dates: string[] = [];

	// 지난 6일간의 날짜를 계산하여 배열에 추가
	let pastDate = new Date(date);
	for (let i = 6; i > 0; i--) {
		pastDate.setDate(date.getDate() - i);
		dates.push(formatDateMD(pastDate));
	}

	// 오늘 날짜를 배열 마지막 요소로 추가
	dates.push(formatDateMD(date));

	return dates;
}

export const getWeekDatesYMD = (date: Date): string[] => {
	const dates: string[] = [];

	// 지난 6일간의 날짜를 계산하여 배열에 추가
	let pastDate = new Date(date);
	for (let i = 6; i > 0; i--) {
		pastDate.setDate(date.getDate() - i);
		dates.push(formatDate(pastDate, '-'));
	}

	// 오늘 날짜를 배열 마지막 요소로 추가
	dates.push(formatDate(date, '-'));

	return dates;
}

export const formatFullDate = (dateString: string): string => {
	const date = new Date(dateString);
	const ymd: string = formatDate(date, '-');
	const hms: string = doubleDigit(date.getHours()) + ':' + doubleDigit(date.getMinutes()) + ':' + doubleDigit(date.getSeconds());
	return `${ymd} ${hms}`;
}

export const formatPhoneNumber = (val: string): string => {
	const numericValue = val.replace(/\D/g, '');
	const length = numericValue.length;
	if (length <= 3) {
		return numericValue;
	} else if (length <= 7) {
		return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
	} else {
		return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 7)}-${numericValue.slice(7, 11)}`;
	}
}

// axios 요청 config 설정
export const setAxiosRequestConfig = (isBit?: boolean): AxiosRequestConfig<any> | undefined => {
	if (isBit) {
		return undefined;
	}

	return {
		headers: {
			Authorization: `Bearer ${getCookie('token')}`
		}
	}
}

export const navigateAfterLogin = (navigate: NavigateFunction, setAIModel: SetterOrUpdater<AIModel>, token: string) => {
	const { category, userType } = JSON.parse(decodeBase64(token.split('.')[1]));

	if (userType === 'ADMIN') {
		navigate('/hospital/list');
	} else {
		setAIModel(category);
		switch (category) {
			case 'BPAI':
				navigate('/dashboard');
				break;
			case 'HCAI':
				navigate('/patient/list');
				break;
			case 'HDAI':
			case 'SCAI':
				navigate('/profile');
				break;
		}
	}
}

// 객체의 모든 값이 truthy한지 구분하는 함수
export const isAllTruthy = (obj: any): boolean => {
	return Object.entries(obj).every(([, value]) => {
		if (typeof value === 'boolean') {
			return true;
		} else if (typeof value === 'number') {
			return value >= 0;
		}
		return Boolean(value);
	});
}

// 모든 요소가 string 타입인 객체를 모든 요소가 number 타입인 객체로 변경
const convertStrObjToNumObj = (strObj: any): any => {
	return Object.fromEntries(Object.entries(strObj).map(([key, value]) => [key, Number(value)]));
}

export const setHcaiResultBody = (formData: AscvdFormProps, patientSeq: string) => {
	const { diabetes, hbpMedicine, height, smoke, weight } = formData.patientHealthInfo;

	const now = new Date();
	let riskAssessmentDate: Date | string = new Date(formData.riskAssessmentDate);
	riskAssessmentDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
	riskAssessmentDate = riskAssessmentDate.toISOString();

	return {
		...formData,
		bloodInspectResultInfo: convertStrObjToNumObj(formData.bloodInspectResultInfo),
		bloodPressureInspectResultInfo: convertStrObjToNumObj(formData.bloodPressureInspectResultInfo),
		patientHealthInfo: {
			...formData.patientHealthInfo,
			height: Number(height),
			isDiabetes: !!diabetes,
			isHbpMedicine: !!hbpMedicine,
			isSmoke: !!smoke,
			weight: Number(weight)
		},
		patientSeq: Number(patientSeq),
		riskAssessmentDate
	}
}

// number 타입의 input에 0을 입력했을 때 처리
export const handleZero = (value: string): string => {
	const [integerPart, decimalPart] = value.split('.');

	// 정수 부분 처리
	let newIntegerPart = integerPart.replace(/^0+(?=\d)/, ''); // 맨 앞의 0 제거
	if (newIntegerPart === '') { // 빈 문자열일 경우 0으로 설정
		newIntegerPart = '0';
	}

	// 소수 부분은 그대로 유지
	return decimalPart ? `${newIntegerPart}.${decimalPart}` : newIntegerPart;
}

// 입력값이 범위 안에 있는지 확인
export const isInRange = (obj: BIRI | BPIRI | PHI): boolean => {
	return Object.entries(obj).every(([key, value]) => {
		if (value === undefined) {
			return false;
		}

		let result: boolean;
		const nValue = Number(value);
		switch (key) {
			case 'height': // 신장
			case 'weight': // 체중
				result = nValue >= 0.1;
				break;
			case 'phosphorus': // 인
				result = nValue >= 0 && nValue <= 20;
				break;
			case 'pdw': // PDW(혈소판 분포폭)
			case 'ppSd': // 맥압 표준편차
			case 'sbpSd': // 수축기 혈압 표준편차
				result = nValue >= 0 && nValue <= 100;
				break;
			case 'ppMax': // 최대 맥압
			case 'ppMean': // 평균 맥압
			case 'ppMin': // 최소 맥압
				result = nValue >= 0 && nValue <= 150;
				break;
			case 'appt': // aPTT
				result = nValue >= 0 && nValue <= 200;
				break;
			case 'hdlCholesterol': // HDL-C(고밀도 콜레스테롤)
			case 'totalCholesterol': // 총 콜레스테롤
				result = nValue >= 0 && nValue <= 500;
				break;
			case 'ast': // AST
				result = nValue >= 0 && nValue <= 2000;
				break;
			case 'sbpMax': // 최대 수축기 혈압
			case 'sbpMean': // 평균 수축기 혈압
			case 'sbpMin': // 최소 수축기 혈압
				result = nValue >= 80 && nValue <= 200;
				break;
			default:
				result = true;
				break;
		}

		if (!result) {
			console.log(key, value);
		}

		return result;
	});
}
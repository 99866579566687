import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { findPredictionResult } from "@apis/patientDetail";
import { RawPredictionsProps, SysDias } from "@models/layoutTypes";
import { ColGroup } from "@molecules/ColGroup";

interface NotPredictedProps {
	textKey: string;
}

interface PredictionsProps {
	bp: SysDias;
	week: number;
}

const NotPredicted: React.FC<NotPredictedProps> = ({ textKey }) => {
	const { t } = useTranslation();
	return <tr><td colSpan={3}>{t(`patient.${textKey}`)}</td></tr>;
}

const Predictions: React.FC<RawPredictionsProps> = ({ data }) => {
	const arr: PredictionsProps[] = Object.entries(data).map(([key, value]): PredictionsProps => ({
		bp: value,
		week: Number(key.replace('week', '')) // "week" 문자열을 제거하고 숫자로 변환
	}));

	const { t } = useTranslation();

	const after: string = useMemo(() => t('patient.after'), [t]);
	const nweek: string = useMemo(() => t('patient.nweek'), [t]);

	return (
		<>
			{arr.map(({ bp, week }, i: number) => (
				<tr key={i}>
					<th>{after} {week}{nweek}</th>
					<td>{bp.systolic}</td>
					<td>{bp.diastolic}</td>
				</tr>
			))}
		</>
	)
}

const PredictResult = () => {
	const { t } = useTranslation();
	const { patientSeq } = useParams();
	const [predictions, setPredictions] = useState(<NotPredicted textKey='pip' />);

	useEffect(() => {
		if (patientSeq) {
			findPredictionResult(patientSeq, false,
				(res) => setPredictions(<Predictions data={res.data} />),
				() => setPredictions(<NotPredicted textKey='unpredictable' />));
		}
	}, [patientSeq]);

	return (
		<table className='predict-result-table'>
			<ColGroup widths={['33.32%', '33.34%', '33.34%']} />
			<thead>
				<tr>
					<th />
					<th>{t('patient.systole')}</th>
					<th>{t('patient.diastole')}</th>
				</tr>
			</thead>
			<tbody>{predictions}</tbody>
		</table>
	);
};

export default PredictResult;
import React from 'react';
import { useRecoilValue } from "recoil";
import "@styles/css/lri.css";
import { DialogProps } from "@models/layoutTypes";
import { dialogIDRecoilState } from "@models/recoilStates";
import { Basic, Untitled } from "@molecules/Dialog";

const Dialog: React.FC<Omit<DialogProps, 'isOpen'>> = ({ dialogClass, dialogTitle, ...props }) => {
	const id: string = useRecoilValue(dialogIDRecoilState);

	return (
		<dialog id={id} className={dialogClass}>
			{dialogTitle
				? <Basic title={dialogTitle} {...props} />
				: <Untitled dialogContents={props.dialogContents} dialogButtons={props.dialogButtons ?? <></>} />}
		</dialog>
	);
};

export default Dialog;
import React from 'react';
import { Trend } from '@atoms/Trend';
import { DashboardMiddleProps } from "@models/layoutTypes";

const Dashboard: React.FC<DashboardMiddleProps> = ({ count, title, trend }) => {
	return (
		<div className='dashboard-middle-box'>
			<div>
				<p>{count.toLocaleString()}</p>
				<span>{title}</span>
			</div>
			<Trend trend={trend} />
		</div>
	);
};

export default Dashboard;
import { useCallback, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const useDownloadPDF = () => {
	const pageRef = useRef<HTMLDivElement>(null);
	const excludeRef = useRef<HTMLButtonElement>(null);

	const downloadPDF = useCallback((fileName: string) => {
		if (pageRef.current && excludeRef.current) {
			// 배제할 요소를 숨긴다.
			excludeRef.current.style.display = 'none';

			const pdf = new jsPDF('p', 'mm', 'a4');
			const pdfWidth: number = pdf.internal.pageSize.getWidth();
			const pdfHeight: number = pdf.internal.pageSize.getHeight();

			// 현재 스크롤 위치 저장
			const currentScrollY: number = window.scrollY;
			const currentScrollX: number = window.scrollX;

			// 페이지의 크기를 기본 크기로 설정
			document.body.style.width = '1200px';

			// 스크롤을 맨 위로 설정
			window.scrollTo(0, 0);

			setTimeout(() => {
				html2canvas(pageRef.current!, { scale: 2 })
					.then(canvas => {
						const imgData: string = canvas.toDataURL('image/png');
						const imgProps = pdf.getImageProperties(imgData);
						const imgHeight: number = (imgProps.height * pdfWidth) / imgProps.width;

						let heightLeft: number = imgHeight;
						let position = 0;

						pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
						heightLeft -= pdfHeight;

						while (heightLeft >= 0) {
							position = heightLeft - imgHeight;
							pdf.addPage();
							pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
							heightLeft -= pdfHeight;
						}
					}).finally(() => {
					pdf.save(`${fileName}.pdf`);

					// 배제한 요소를 다시 표시한다.
					excludeRef.current!.style.display = 'block';

					// 원래 스크롤 위치로 되돌림
					window.scrollTo(currentScrollX, currentScrollY);

					// 페이지의 크기를 원래 크기로 복원
					document.body.style.width = '';
				});
			}, 300);
		}
	}, []);

	return { pageRef, excludeRef, downloadPDF };
}
import React from 'react';
import { Input } from "@atoms/Input";
import { OptionProps } from "@models/optionProps";

const CheckBoxOption: React.FC<OptionProps> = ({ disabled, id, text, ...props }) => {
	return (
		<label className={`check-option ${disabled ? 'disabled' : ''}`} htmlFor={id}>
			<Input type='checkbox' id={id} {...props} disabled={disabled} />
			<span className="checkmark"></span>
			{text}
		</label>
	);
};

export default CheckBoxOption;
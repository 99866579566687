import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getCookie } from "typescript-cookie";
import { findHospitalUserDetail } from "@apis/hospitalDetail";
import { INITIAL_USER_DETAIL } from "@constants/common";

const Table: React.FC<{ userSeq: number }> = ({ userSeq }) => {
	const { t } = useTranslation();
	const [detail, setDetail] = useState(INITIAL_USER_DETAIL);
	const isT3Q: boolean = !!getCookie('userUuid');

	const userTypeText = useCallback((userType: string): string => {
		switch (userType) {
			case 'DOCTOR':
				return t('hospital.doctor');
			case 'COORDINATOR':
				return t('hospital.coordinator');
			default:
				return '';
		}
	}, [t]);

	useEffect(() => {
		findHospitalUserDetail(userSeq, (res) => {
			const { categories, contact, createdAt, id, isActive, name, userType } = res.data;
			setDetail({
				categories,
				contact,
				createdAt: createdAt.split('T')[0],
				id,
				name,
				status: isActive ? t('hospital.active') : t('hospital.inactive'),
				userType: userTypeText(userType)
			});
		}, () => setDetail(INITIAL_USER_DETAIL));
	}, [t, userSeq, userTypeText]);

	return (
		<table className="user-detail-dialog-table">
			<colgroup>
				<col width='35%' />
				<col width='65%' />
			</colgroup>
			<tbody>
			<tr>
				<th>{t('login.id')}</th>
				<td>{isT3Q ? '-' : detail.id}</td>
			</tr>
			<tr>
				<th>{t('hospital.uc')}</th>
				<td>{detail.userType}</td>
			</tr>
			<tr>
				<th>{t('hospital.name')}</th>
				<td>{detail.name}</td>
			</tr>
			<tr>
				<th>{t('hospital.phone')}</th>
				<td>{detail.contact}</td>
			</tr>
			<tr>
				<th>{t('hospital.category')}</th>
				<td>{detail.categories.sort().join(', ').toUpperCase()}</td>
			</tr>
			<tr>
				<th>{t('hospital.status')}</th>
				<td>{detail.status}</td>
			</tr>
			<tr>
				<th>{t('hospital.rd')}</th>
				<td>{detail.createdAt}</td>
			</tr>
			</tbody>
		</table>
	);
};

export default Table;
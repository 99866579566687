import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Button } from "@atoms/Button";
import { BPAI_FILTER_VALUES, HCAI_FILTER_VALUES } from "@constants/common";
import { useEffectAfterMount } from "@hooks/useEffectAfterMount";
import { SearchBoxProps } from "@models/layoutTypes";
import {
	aiModelState,
	filterBtnOffsetLeftState,
	filterBtnOffsetTopState,
	filterCheckedState,
	filterValuesState
} from "@models/recoilStates";
import { Filter, FilterItem } from "@molecules/Filter";
import { SearchInput } from "@molecules/SearchInput";
import icFilter from "@styles/images/ic-filter.png";

const SearchBox: React.FC<SearchBoxProps> = ({ searchboxes }) => {
	const { t } = useTranslation();

	const [showFilterOptions, setShowFilterOptions] = useState(false);

	const aiModel = useRecoilValue(aiModelState);
	const offsetLeft = useRecoilValue(filterBtnOffsetLeftState);
	const offsetTop = useRecoilValue(filterBtnOffsetTopState);

	const [filterArr, setFilterArr] = useRecoilState(filterValuesState);

	const setCheckedArr = useSetRecoilState(filterCheckedState);

	const filterBtnRef = useRef<HTMLButtonElement>(null);

	const filterValues: string[] = useMemo(() => {
		switch (aiModel) {
			case "BPAI":
				return BPAI_FILTER_VALUES;
			case "HCAI":
				return HCAI_FILTER_VALUES;
			default:
				return [];
		}
	}, [aiModel]);

	const setFilterPosition = useRecoilCallback(({ set }) => () => {
		const filterBtn = filterBtnRef.current;
		if (filterBtn) {
			set(filterBtnOffsetLeftState, filterBtn.offsetLeft);
			set(filterBtnOffsetTopState, filterBtn.offsetTop + 45);
		}
	}, []);

	useEffect(() => {
		setFilterArr([]);
	}, [setFilterArr]);

	useEffect(() => {
		setFilterPosition();
	}, [setFilterPosition, showFilterOptions]);

	useEffectAfterMount(() => {
		// filterValuesState 상태가 변경될 때마다 checkedArr 업데이트
		setCheckedArr(filterArr.length === 0
									? new Array(filterValues.length).fill(false)
									: filterValues.map(value => filterArr.includes(value)));
	}, [filterArr, filterValues, setCheckedArr]);

	return (
		<div className='search-box'>
			{searchboxes.map((sb, i) => <SearchInput key={i} {...sb} />)}
			<Button className='filter-button' onClick={() => setShowFilterOptions(prev => !prev)} ref={filterBtnRef}>
				<img src={icFilter} alt='filter icon' />
				<span>{t('patient.filter')}</span>
			</Button>
			{showFilterOptions && <Filter left={offsetLeft} top={offsetTop} values={filterValues} />}
			{filterArr.map((filter, i) => <FilterItem key={i} index={i}>{filter}</FilterItem>)}
		</div>
	);
};

export default SearchBox;